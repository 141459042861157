import React from 'react'
import { Autocomplete, TextField, Typography } from '@mui/material'
import { useApolloClient } from '@apollo/client'
import { throttle } from 'lodash'

import { IProduct, IProductsPayload } from '../../graphql/types/products'
import { PRODUCTS_SEARCH } from '../../graphql/queries/products'

interface IProps {
  value: IProduct | null
  onChange: (product: IProduct | null) => void
  brand?: number
  error?: boolean
}

export const ProductsAutocomplete: React.FC<IProps> = ({ value, onChange, brand, error }) => {
  const client = useApolloClient()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState<readonly IProduct[]>([])

  const fetch = React.useMemo(
    () =>
      throttle(async (search: string, callback: (results?: readonly IProduct[]) => void) => {
        const { data } = await client.query<{ productsSearch: IProductsPayload }>({
          query: PRODUCTS_SEARCH,
          variables: { limit: 10, search, brand },
        })

        callback(data.productsSearch.products || [])
      }, 200),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(inputValue, (results?: readonly IProduct[]) => {
      if (active) {
        let newOptions: readonly IProduct[] = []

        if (value) {
          newOptions = [value]
        }

        if (results) {
          newOptions = [...newOptions, ...results]
        }

        setOptions(newOptions)
      }
    })

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      id='products-autocomplete'
      getOptionLabel={(option) => option.fullTitle || ''}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event: any, newValue: IProduct | null) => {
        setOptions(newValue ? [newValue, ...options] : options)
        onChange(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => <TextField {...params} label='Товар' error={error} margin='dense' fullWidth />}
      noOptionsText={'Нет продуктов'}
      renderOption={(props, option) => {
        return (
          <li {...props} key={`product-${option.id}`}>
            <Typography variant='body2'>{option.fullTitle}</Typography>
          </li>
        )
      }}
    />
  )
}
