import { Card, CardActionArea, CardContent, CardMedia, Chip, Typography, styled } from '@mui/material'
import moment from 'moment'
import { Link } from 'react-router-dom'

import { DATE_FORMAT } from '../../core/constants'
import { IArticle } from '../../graphql/types/articles'
import { withApiUrl } from '../../utils/helpers'
import { defaultBg } from '../../assets/images'

interface IProps {
  item: IArticle
}

export const NewsCard: React.FC<IProps> = ({ item }) => {
  const isSurvey = false

  return (
    <Card>
      <CardActionArea component={Link} to={`/news/${item.id}`}>
        <CardMedia
          sx={{ height: 140, position: 'relative', bgcolor: 'grey.50' }}
          image={withApiUrl(item.image?.url) || defaultBg}
          title={item.title}
        >
          {isSurvey && <Chip label='Опрос' color='primary' sx={{ position: 'absolute', bottom: 10, right: 10 }} />}
        </CardMedia>
        <CardContent>
          <Typography gutterBottom variant='caption' color='text.secondary'>
            {moment(item.published_at).format(DATE_FORMAT)}
          </Typography>
          <EllipsisTypography variant='h5' mb={1}>
            {item.title}
          </EllipsisTypography>
          <EllipsisTypography variant='body2' color='text.secondary'>
            {item.description}
          </EllipsisTypography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

const EllipsisTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
})
