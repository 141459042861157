import { Box, Grid, Typography } from '@mui/material'

import { SalesPointLeaderCard, SellerLeaderCard } from '../../../components/Leaderboard'
import { useLeaders } from '../../../graphql/hooks/rating'
import { DealersRating } from './DealersRating'
import { SellersRating } from './SellersRating'

export const Leaderboards = () => {
  const { leaders } = useLeaders()

  return (
    <Box pt={3}>
      <Typography variant='h4' mb={3}>
        Турнирная таблица
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box mb={3}>
            <SalesPointLeaderCard leaders={leaders} />
          </Box>
          <DealersRating />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box mb={3}>
            <SellerLeaderCard leaders={leaders} />
          </Box>
          <SellersRating />
        </Grid>
      </Grid>
    </Box>
  )
}
