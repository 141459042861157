import { Grid, Card, CardContent, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { ISeller } from '../../graphql/types/sellers'
import { useStores } from '../../stores/hooks'

export const CreateWarrantyInfo = observer(() => {
  const { authStore } = useStores()
  const { user, userType } = authStore

  if (!user || !userType || authStore.isDealer) {
    return null
  }

  const seller = userType as ISeller

  return (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6' mb={2}>
              Точка продаж
            </Typography>
            <Typography variant='subtitle1'>{seller.dealer.name}</Typography>
            <Typography color='text.secondary'>
              {seller.dealer.city?.title}, {seller.dealer.address}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6' mb={2}>
              Продавец
            </Typography>
            <Typography variant='subtitle1'>{seller.name}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
})
