import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_WARRANTIES } from '../../queries/warranty'
import { IWarranty, IGetWarrantiesVariables } from '../../types/warranty'
import { useStores } from '../../../stores/hooks'

export const useWarranties = (limit: number = 12) => {
  const { authStore } = useStores()
  const [page, setPage] = useState<number>(1)

  const where: { [key: string]: any } = {}

  if (authStore.isSeller) {
    where.seller = authStore.userType?.id
  } else {
    where.dealer = authStore.userType?.id
  }

  const { data, ...q } = useQuery<{ warranties: IWarranty[]; warrantiesCount: number }, IGetWarrantiesVariables>(
    GET_WARRANTIES,
    {
      variables: {
        limit,
        start: (page - 1) * limit,
        sort: 'created_at:DESC',
        where,
      },
      fetchPolicy: 'network-only',
    }
  )

  const warranties = data?.warranties || []
  const total = data?.warrantiesCount || 0

  return {
    warranties,
    total,
    page,
    setPage,
    ...q,
  }
}
