export enum NotificationType {
  NEW_ARTICLE = 'new_article',
  SALE_POINTS = 'sale_points',
  DEALER_POINTS = 'dealer_points',
  REMOVE_SALE_POINTS = 'remove_sale_points',
  ARTICLE_LIKE_POINTS = 'article_like_points',
  TESTING_POINTS = 'testing_points',
  WITHDRAW_PRIZE_POINTS = 'withdraw_prize_points',
}

export interface INotification {
  id: number
  type: NotificationType
  relation_id: number | null
  points: number | null
  created_at: string
}
