import { gql } from '@apollo/client'

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFragment on Notification {
    id
    type
    relation_id
    points
    created_at
  }
`

export const NOTIFICATIONS = gql`
  query UserNotificationsQuery($limit: Int) {
    userNotifications(limit: $limit) {
      ...NotificationFragment
    }
  }
  ${NOTIFICATION_FRAGMENT}
`
