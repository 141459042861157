export interface ITesting {
  id: number
  title: string
  description: string | null
  points: number
  status: TestingStatus
  created_at: string
  questions: ITestingQuestion[]
  myResult: ITestingResult | null
}

export enum TestingStatus {
  PUBLISHED = 'published',
  STOPPED = 'stopped',
}

export interface ITestingQuestion {
  id: number
  title: string
  answers: ITestingAnswer[]
}

export interface ITestingAnswer {
  id: number
  title: string
}

export interface ITestingResult {
  id: number
  results: ITestingResultItem[]
  points: number
  correct_count: number
  is_passed: boolean
  created_at: string
}

export interface IGetTestingsVariables {
  sort?: string
  limit?: number
  start?: number
}

export interface IGetTestingsResponse {
  testings: ITesting[]
  total: number
}

export interface ITestingResultItem {
  questionId: number
  answerId: number
}

export interface ISendTestingResultInput {
  testingId: number
  results: ITestingResultItem[]
}
