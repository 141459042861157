import React from 'react'
import { Box, Dialog, DialogActions, IconButton } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { PDFViewer } from '@react-pdf/renderer'
import { IWarranty } from '../../../graphql/types/warranty'
import { WarrantyPDF } from './WarrantyPDF'

interface IProps {
  warranty: IWarranty
  open: boolean
  onClose?: VoidFunction
}

export const WarrantyPDFDialog: React.FC<IProps> = ({ warranty, open, onClose }) => {
  return (
    <Dialog fullScreen open={open}>
      <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <DialogActions
          sx={{
            zIndex: 9,
            boxShadow: (theme) => theme.shadows[8],
          }}
        >
          <IconButton color='inherit' onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogActions>
        <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
          <PDFViewer width='100%' height='100%' style={{ border: 'none' }}>
            <WarrantyPDF warranty={warranty} />
          </PDFViewer>
        </Box>
      </Box>
    </Dialog>
  )
}
