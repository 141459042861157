import React, { useState } from 'react'
import { Grid, Card, Typography, Divider, Stack, Box, IconButton, styled } from '@mui/material'
import { Print as PrintIcon } from '@mui/icons-material'
import moment from 'moment'
import { WarrantyPDFDialog } from './warrantyPDF'
import { IWarranty } from '../../graphql/types/warranty'
import { DATE_TIME_FORMAT } from '../../core/constants'
import { WarrantyProductsForm } from './WarrantyProductsForm'
import { WarrantyProductsInfo } from './WarrantyProductsInfo'

interface IProps {
  warranty: IWarranty
}

export const WarrantyInfo: React.FC<IProps> = ({ warranty }) => {
  const [printPdf, setPrintPdf] = useState(false)

  const togglePrintPdf = () => {
    setPrintPdf((prev) => !prev)
  }

  return (
    <Card sx={{ position: 'relative' }}>
      <PrintButton>
        <IconButton onClick={togglePrintPdf}>
          <PrintIcon />
        </IconButton>
      </PrintButton>
      <Box p={2}>
        <Box mb={3}>
          <Typography variant='h6'>{warranty.brand.title}</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant='body2' color='text.secondary' mb={1}>
                Точка продаж
              </Typography>
              <Typography>{warranty.dealer.name}</Typography>
              <Typography>{`${warranty.dealer.city?.title}, ${warranty.dealer.address}`}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant='body2' color='text.secondary' mb={1}>
                Продавец
              </Typography>
              <Typography>{warranty.seller.name}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box>
              <Typography variant='body2' color='text.secondary' mb={1}>
                Дата создания
              </Typography>
              <Typography>{moment(warranty.created_at).format(DATE_TIME_FORMAT)}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box px={2} mb={2}>
        {!!warranty.product ? (
          <WarrantyProductsInfo warranty={warranty} />
        ) : (
          <WarrantyProductsForm warranty={warranty} />
        )}
      </Box>
      <Divider />
      <Box p={2}>
        <Stack direction='row' alignItems='center' justifyContent='center'>
          <Typography mr={2} color='text.secondary'>
            КОД ПОКУПАТЕЛЯ:
          </Typography>
          <Typography variant='h5'>{warranty.code}</Typography>
        </Stack>
      </Box>
      <WarrantyPDFDialog warranty={warranty} open={printPdf} onClose={togglePrintPdf} />
    </Card>
  )
}

const PrintButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
}))
