import { FormikHelpers, useFormik } from 'formik'
import { IImage } from '../../../graphql/types/image'
import { warrantyCaseSchema } from './validation'

export const WARRANTY_CASE_TIRE_TYPES = ['Run Flat', 'Традиционные', 'ContiSeal', 'ContiSilent']

export const WARRANTY_CASE_DAMAGES = ['Прокол', 'Удар по шине(вздутие)', 'Порез', 'Преждевременное выпадение шипов']

export const WARRANTY_CASE_TYPES = ['Ремонт', 'Замена']

export interface IWarrantyCaseFormValues {
  tireType: number
  tireDesignation: string
  dotCode: string
  treadDepth: string[]
  avgPressure: string[] // [front axle, Rear axle]
  damage: number // Прокол, удар по шине(вздутие), порез, преждевременное выпадение шипов
  type: number // Ремонт/Замена
  cost: string
  mileage: string
  vendorCode: string
  // Switches
  damageHumanHealth: boolean
  carDamage: boolean
  policeInvolvement: boolean
  damageProperty: boolean
  damageOtherVehicles: boolean
  involvementInsurance: boolean
  // Images
  wholeTireImage: IImage | null
  sizeImage: IImage | null
  dotCodeImage: IImage | null
  damageImage: IImage | null
  couponCheckImage: IImage | null
  optImage1: IImage | null
  optImage2: IImage | null
}

type WarrantyCaseFormType = {
  onSubmit: (values: IWarrantyCaseFormValues, formikHelpers: FormikHelpers<IWarrantyCaseFormValues>) => Promise<void>
}

export const useWarrantyCaseFormik = ({ onSubmit }: WarrantyCaseFormType) => {
  const formik = useFormik<IWarrantyCaseFormValues>({
    initialValues: {
      tireType: 0,
      tireDesignation: '',
      dotCode: '',
      treadDepth: ['', '', ''],
      avgPressure: ['', ''],
      damage: 0,
      type: 0,
      cost: '',
      mileage: '',
      vendorCode: '',
      // Switches
      damageHumanHealth: false,
      carDamage: false,
      policeInvolvement: false,
      damageProperty: false,
      damageOtherVehicles: false,
      involvementInsurance: false,
      // Images
      wholeTireImage: null,
      sizeImage: null,
      dotCodeImage: null,
      damageImage: null,
      couponCheckImage: null,
      optImage1: null,
      optImage2: null,
    },
    validationSchema: warrantyCaseSchema,
    onSubmit,
  })

  return formik
}
