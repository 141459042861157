import { Box, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'
import { Redirect } from 'react-router-dom'

import { EditableAvatar, Loader } from '../../components/UI'
import { useProfile, useUpdateProfile } from '../../graphql/hooks/auth'
import { IUpdateProfileInput } from '../../graphql/types/auth'
import { useStores } from '../../stores/hooks'
import { IRegisterFormValues, RegisterForm } from './components'

function Register() {
  const { authStore } = useStores()
  const history = useHistory()
  const { profile, loading } = useProfile()
  const { updateProfile, updateAvatar, loading: updateLoading } = useUpdateProfile()

  if (authStore.isLoggedIn) {
    return <Redirect to='/' />
  }

  if (loading) {
    return <Loader />
  }

  if (!profile) {
    return null
  }

  const handleSubmit = async (values: IRegisterFormValues) => {
    try {
      await updateProfile(values as IUpdateProfileInput)
      history.push('/')
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelectAvatar = async (file: File) => {
    try {
      await updateAvatar(file)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <Box pt={'220px'} mb={3}>
        <Typography variant='h4'>Регистрация</Typography>
        <Typography color='text.secondary'>{profile.user.email}</Typography>
      </Box>
      <Box display='flex' justifyContent='center' mb={3}>
        <EditableAvatar
          src={
            authStore.userType?.avatar?.url
              ? `${process.env.REACT_APP_API_HOST}${authStore.userType?.avatar?.url}`
              : undefined
          }
          loading={updateLoading}
          onSelectImage={handleSelectAvatar}
        />
      </Box>
      <RegisterForm profile={profile} loading={updateLoading} onSubmit={handleSubmit} />
    </Box>
  )
}

export default observer(Register)
