import { Box, IconButton, List, Stack, Typography } from '@mui/material'
import { ChevronRight as MoreIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import { LeaderboardItem } from '../../../components/Leaderboard'
import { useDealersRating } from '../../../graphql/hooks/rating'
import { Loader } from '../../../components/UI'

export const DealersRating = () => {
  const { dealers, loading } = useDealersRating(6)

  if (loading) {
    return <Loader />
  }

  return (
    <Box>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography color='text.secondary'>Рейтинг торговых точек</Typography>
        <IconButton size='small' component={Link} to='/leaders/sales-points'>
          <MoreIcon />
        </IconButton>
      </Stack>
      <List sx={{ width: '100%' }}>
        {dealers.map((item) => (
          <Box key={`dealer-rating-${item.id}`} mb={1}>
            <LeaderboardItem
              name={item.name}
              description={`${item.city?.title || ''}, ${item.address || ''}`}
              avatar={item.avatar?.url}
              points={item.points}
            />
          </Box>
        ))}
      </List>
    </Box>
  )
}
