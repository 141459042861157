import React from 'react'
import { Box, Typography, styled, CircularProgress } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { compressImage } from '../../../utils/image'
import { useSingleUpload } from '../../../graphql/hooks/upload'
import { IImage } from '../../../graphql/types/image'
import { withApiUrl } from '../../../utils/helpers'

interface IProps {
  image: IImage | null
  label: string
  error?: boolean
  onChange: (image: IImage) => void
}

export const ImageUploadItem: React.FC<IProps> = ({ image, label, error, onChange }) => {
  const [singleUpload, { loading }] = useSingleUpload()

  const onDrop = async (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0]

    if (!selectedFile) {
      return
    }

    const compressed = await compressImage(selectedFile, 800)

    try {
      const { data } = await singleUpload({
        variables: { file: compressed },
      })

      if (data?.upload) {
        onChange(data.upload)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    maxSize: 3000000,
    multiple: false,
    onDrop,
  })

  return (
    <Box sx={{ flex: 1 }}>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        {loading && (
          <LoadingBox>
            <CircularProgress />
          </LoadingBox>
        )}
        {!!image && <Image src={withApiUrl(image.url)} alt='preview image' />}
        <input {...getInputProps()} />
        <Typography textAlign='center' variant='body2' color={error ? 'error' : 'text.secondary'}>
          Загрузите фото
        </Typography>
      </Container>
      <Typography variant='body2' color={error ? 'error' : 'text.secondary'}>
        {label}
      </Typography>
    </Box>
  )
}

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Container = styled(Box)(({ theme, ...props }) => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  minHeight: 170,
  marginBottom: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1),
  borderWidth: 2,
  borderRadius: 4,
  borderColor: getColor(props),
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  outline: 'none',
  transition: 'border 0.24s ease-in-out',
}))

const LoadingBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 9,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  top: 0,
  left: 0,
}))

const Image = styled('img')(({ theme }) => ({
  position: 'absolute',
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  top: 0,
  left: 0,
}))
