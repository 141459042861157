import React from 'react'
import { TextField, Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../components/Form'
import { resetPasswordSchema } from './validation'

export interface IResetPasswordFormValues {
  password: string
  passwordConfirmation: string
}

interface IProps {
  onSubmit: (values: IResetPasswordFormValues) => void
  loading?: boolean
}

export const ResetPasswordForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const initialValues: IResetPasswordFormValues = {
    password: '',
    passwordConfirmation: '',
  }

  const handleFormSubmit = (values: IResetPasswordFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={resetPasswordSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IResetPasswordFormValues>) => (
        <form onSubmit={handleSubmit}>
          <TextField
            label='Пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            required
            type='password'
          />
          <TextField
            label='Подтверждение пароля'
            name='passwordConfirmation'
            value={values.passwordConfirmation}
            onChange={handleChange}
            error={!!errors.passwordConfirmation}
            required
            type='password'
          />
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Отправить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}
