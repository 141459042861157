import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material'
import { Person as UserIcon, VerifiedUser as WarrantyIcon } from '@mui/icons-material'
import { RouteComponentProps, Link } from 'react-router-dom'

import { authBg, mainLogo } from '../../assets/images'
import { Copyright } from '../../components/Layout'

const Welcome: React.FC<RouteComponentProps> = ({ location }) => {
  return (
    <Box>
      <LogoImg src={mainLogo} alt='' />
      <Grid container sx={{ minHeight: 'calc(100vh - 32px)', backgroundColor: '#f0f0f0' }}>
        <Grid item xs={12} sm={8} md={5}>
          <Body>
            <Box>
              <Box pt={'260px'} mb={6}>
                <Typography variant='h4' mb={1}>
                  Добро пожаловать!
                </Typography>
                <Typography color='text.secondary'>
                  Первый выбор ведущих автопроизводителей.
                  <br />
                  Каждый третий автомобиль в Европе оснащен шинами Continental.
                </Typography>
              </Box>
              <Stack spacing={5}>
                <Button
                  component={Link}
                  to='/auth/login'
                  variant='contained'
                  startIcon={<UserIcon />}
                  sx={{ height: 64 }}
                >
                  Вход для сотрудников
                </Button>
                <Button
                  component={Link}
                  to='/register-warranty'
                  variant='contained'
                  color='info'
                  startIcon={<WarrantyIcon />}
                  sx={{ height: 64 }}
                >
                  Расширенная гарантия
                </Button>
              </Stack>
            </Box>
            <Copyright />
          </Body>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${authBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </Box>
  )
}

const Body = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: '140px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 400,
    margin: 'auto',
  },
}))

const LogoImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(6),
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.down('sm')]: {
    top: -50,
  },
}))

export default Welcome
