import React from 'react'
import { Box, Drawer, Typography } from '@mui/material'

import { useNotifications } from './useNotifications'
import { Loader } from '../../components/UI'
import { NotificationItem } from '.'

interface IProps {
  open: boolean
  onClose: () => void
}

export const NotificationDrawer: React.FC<IProps> = ({ open, onClose }) => {
  const { notifications, loading } = useNotifications()

  return (
    <Drawer anchor='right' open={open} onClose={onClose}>
      <Box sx={{ width: 320, p: 2 }}>
        <Typography variant='h6' mb={2}>
          Уведомления
        </Typography>

        <Box>
          {loading && <Loader />}
          {notifications.map((item) => (
            <Box mb={2} key={`notf-${item.id}`}>
              <NotificationItem item={item} />
            </Box>
          ))}
        </Box>
      </Box>
    </Drawer>
  )
}
