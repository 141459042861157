import { gql } from '@apollo/client'

export const PRIZE_FRAGMENT = gql`
  fragment PrizeFragment on PrizesPrize {
    id
    points
    status
    items
    created_at
  }
`

export const GET_PRIZES = gql`
  query GetPrizesQuery($sort: String, $limit: Int, $start: Int) {
    myPrizes(sort: $sort, limit: $limit, start: $start) {
      prizes {
        ...PrizeFragment
      }
      total
    }
  }
  ${PRIZE_FRAGMENT}
`

export const GET_PRIZE = gql`
  query GetPrizeQuery($id: ID!) {
    myPrize(id: $id) {
      ...PrizeFragment
    }
  }
  ${PRIZE_FRAGMENT}
`

export const REQUEST_PRIZE = gql`
  mutation RequestPrizeMutation($input: RequestPrizeInput!) {
    requestPrizeByUser(input: $input) {
      ...PrizeFragment
    }
  }
  ${PRIZE_FRAGMENT}
`
