import React, { useState } from 'react'
import {
  Box,
  Button,
  Stack,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Link,
} from '@mui/material'
import { VerifiedUser as WarrantyIcon } from '@mui/icons-material'
import { IWarranty } from '../../../graphql/types/warranty'
import { useRegisterCustomer } from '../../../graphql/hooks/warranty'
import { LoadableButton } from '../../../components/Form'
import { GET_WARRANTY } from '../../../graphql/queries/warranty'
import { IRegisterCustomerToWarrantyInput } from '../../../graphql/types/warranty'
import { CustomerForm, ICustomerFormValues } from './CustomerForm'
import { CarForm, ICarFormValues } from './CarForm'
import { CouponForm } from './CouponForm'

interface IProps {
  initialWarranty?: IWarranty
  onRegistered?: VoidFunction
  refetchWarranty?: boolean
}

export const RegisterWarrantyForm: React.FC<IProps> = ({ initialWarranty, refetchWarranty, onRegistered }) => {
  const [registerCustomer, { loading }] = useRegisterCustomer()

  const [warranty, setWarranty] = useState<IWarranty | undefined>(initialWarranty)
  const [customerForm, setCustomerForm] = useState<ICustomerFormValues>()
  const [carForm, setCarForm] = useState<ICarFormValues>()
  const [termsChecked, setTermsChecked] = useState(false)
  const [activeStep, setActiveStep] = useState(initialWarranty ? 1 : 0)

  const handleNext = () => {
    setActiveStep((prev) => prev + 1)
  }

  const handleBack = () => {
    setActiveStep((prev) => prev - 1)
  }

  const handleReset = () => {
    setWarranty(initialWarranty)
    setCustomerForm(undefined)
    setCarForm(undefined)
    setActiveStep(initialWarranty ? 1 : 0)
  }

  const handleCouponForm = (w: IWarranty) => {
    setWarranty(w)
    handleNext()
  }

  const handleCustomerForm = (values: ICustomerFormValues) => {
    setCustomerForm(values)
    handleNext()
  }

  const handleCarForm = (values: ICarFormValues) => {
    setCarForm(values)
    handleNext()
  }

  const handleRegister = async () => {
    if (!termsChecked || !warranty || !customerForm || !carForm) {
      return
    }

    try {
      const input: IRegisterCustomerToWarrantyInput = {
        warrantyCode: warranty.code,
        customer: {
          ...customerForm,
          ...carForm,
          email: '',
          // birthday: moment(customerForm.birthday).format('YYYY-MM-DD'),
        },
      }

      const refetchQueries = []
      if (refetchWarranty) {
        refetchQueries.push({ query: GET_WARRANTY, variables: { id: warranty.id } })
      }

      const { data } = await registerCustomer({
        variables: { input },
        refetchQueries,
      })

      if (data?.registerCustomerToWarranty) {
        onRegistered?.()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} orientation='vertical'>
        <Step>
          <StepLabel>
            <Box>
              <Typography variant='subtitle2'>Купон</Typography>
              {!!warranty?.code && <Typography color='text.secondary'>{warranty.code}</Typography>}
            </Box>
          </StepLabel>
          <StepContent>
            <CouponForm onNext={handleCouponForm} />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            <Box>
              <Typography variant='subtitle2'>Данные покупателя</Typography>
              {!!customerForm && <Typography color='text.secondary'>{customerForm.fullName}</Typography>}
            </Box>
          </StepLabel>
          <StepContent>
            <CustomerForm
              initialData={customerForm}
              onNext={handleCustomerForm}
              onBack={initialWarranty ? undefined : handleBack}
            />
          </StepContent>
        </Step>
        <Step>
          <StepLabel>
            <Box>
              <Typography variant='subtitle2'>Данные автомобиля</Typography>
              {!!carForm && <Typography color='text.secondary'>{`${carForm.carBrand} ${carForm.carModel}`}</Typography>}
            </Box>
          </StepLabel>
          <StepContent>
            <CarForm onNext={handleCarForm} onBack={handleBack} />
          </StepContent>
        </Step>
      </Stepper>
      {activeStep === 3 && (
        <Box sx={{ p: 3 }}>
          <Typography mb={2}>Данные готовы для регистрации</Typography>
          <FormGroup sx={{ mb: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={termsChecked} onChange={(e, ch) => setTermsChecked(ch)} />}
              label={
                <Link href='#' color='info.main'>
                  Я даю согласие на обработку персональных данных
                </Link>
              }
            />
          </FormGroup>
          <Stack spacing={2} direction='row'>
            <LoadableButton
              loading={loading}
              variant='contained'
              size='large'
              startIcon={<WarrantyIcon />}
              onClick={handleRegister}
            >
              Зарегистрировать
            </LoadableButton>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Отменить
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  )
}
