import { gql } from '@apollo/client'

import { DEALER_FRAGMENT } from './dealers'
import { SELLER_FRAGMENT } from './sellers'

export const DEALERS_RATING = gql`
  query DealersRatingQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    dealers(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...DealerFragment
    }
    dealersCount(where: $where)
  }
  ${DEALER_FRAGMENT}
`

export const SELLERS_RATING = gql`
  query SellersRatingQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    sellers(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...SellerFragment
    }
    sellersCount(where: $where)
  }
  ${SELLER_FRAGMENT}
`

export const LEADERS_OF_LAST_MONTH = gql`
  query LeadersOfLastMonthQuery {
    leaders(sort: "month:DESC", limit: 1, start: 0) {
      month
      dealer {
        ...DealerFragment
      }
      seller {
        ...SellerFragment
      }
    }
  }
  ${DEALER_FRAGMENT}
  ${SELLER_FRAGMENT}
`
