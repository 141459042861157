import { useEffect } from 'react'
import { Box, Grid, styled } from '@mui/material'
import { Route, RouteComponentProps, Switch } from 'react-router'

import { authBg, mainLogo } from '../assets/images'
import { Copyright } from '../components/Layout'
import Login from '../views/Auth/Login'
import Logout from '../views/Auth/Logout'
import Register from '../views/Auth/Register'
import EmailConfirmation from '../views/Auth/EmailConfirmation'
import ForgotPassword from '../views/Auth/ForgotPassword'
import ResetPassword from '../views/Auth/ResetPassword'

const AuthLayout: React.FC<RouteComponentProps> = ({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Box>
      <LogoImg src={mainLogo} alt='' />
      <Grid container sx={{ minHeight: 'calc(100vh - 32px)', backgroundColor: '#f0f0f0' }}>
        <Grid item xs={12} sm={8} md={5}>
          <Body>
            <Switch>
              <Route path='/auth/login' exact component={Login} />
              <Route path='/auth/logout' exact component={Logout} />
              <Route path='/auth/register' exact component={Register} />
              <Route path='/auth/email-confirmation' exact component={EmailConfirmation} />
              <Route path='/auth/forgot-password' exact component={ForgotPassword} />
              <Route path='/auth/reset-password' exact component={ResetPassword} />
            </Switch>
            <Copyright />
          </Body>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${authBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </Box>
  )
}

const Body = styled('div')(({ theme }) => ({
  position: 'relative',
  minHeight: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: '140px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 400,
    margin: 'auto',
  },
}))

const LogoImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(6),
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.down('sm')]: {
    top: -50,
  },
}))

export default AuthLayout
