import { IProduct } from '../graphql/types/products'

export function formatProductTitle(product: IProduct) {
  return `${product.title} / ${product.width}/${product.height}/${product.diameter}`
}

export const withApiUrl = (url?: string) => {
  if (!url) {
    return undefined
  }

  return (process.env.REACT_APP_API_HOST || '') + url
}

export const generateRandomString = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
export const stripTypenames = (obj: any, propToDelete: string) => {
  for (const property in obj) {
    if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
      delete obj.property
      const newData = stripTypenames(obj[property], propToDelete)
      obj[property] = newData
    } else {
      if (property === propToDelete) {
        delete obj[property]
      }
    }
  }

  return obj
}

export const plural = (labels: string[], number: number): string => {
  let index
  // @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html
  if (number % 10 === 1 && number % 100 !== 11) {
    index = 0 // many
  } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
    index = 1 // few
  } else {
    index = 2 // one
  }
  return labels[index] || ''
}
