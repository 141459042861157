import React from 'react'
import { Page, View, Text, Document, Image } from '@react-pdf/renderer'
import styles from './styles'
import { IWarrantyCase } from '../../../graphql/types/warranty-case'
import { IWarranty } from '../../../graphql/types/warranty'
import { signByManagerId } from '../../../assets/signs'
import { formatProductTitle } from '../../../utils/helpers'

interface IProps {
  warranty: IWarranty
  warrantyCase: IWarrantyCase
}

export const DecisionPDF: React.FC<IProps> = ({ warranty, warrantyCase }) => {
  const signer = signByManagerId(warrantyCase.admin_user?.id ? +warrantyCase.admin_user?.id : 0)

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={[styles.mb40]}>
          <Text style={styles.subtitle1}>Ответ на заявку №{warrantyCase.id}</Text>
          <Text style={styles.subtitle1}>
            {`от "${warrantyCase.seller.dealer.name}", ${warrantyCase.seller.dealer.city?.title}, ${warrantyCase.seller.dealer.address}`}
          </Text>
          <Text style={styles.subtitle1}>Портал "Contipartners", к. Континенталь.</Text>
        </View>
        <View style={[styles.mb40]}>
          <Text>{warrantyCase.decision}</Text>
        </View>
        <View style={[styles.mb40]}>
          {!!warranty.product && <Text>{formatProductTitle(warranty.product)}</Text>}
          {!!warranty.secondProduct && <Text>{formatProductTitle(warranty.secondProduct)}</Text>}
        </View>

        <View style={[styles.mb16]}>
          <Text>С уважением,</Text>
        </View>

        {!!signer && (
          <View style={styles.mb16}>
            <Image source={signer.src} style={{ width: 70 }} />
          </View>
        )}

        <View style={styles.mb16}>
          <Text>{`${warrantyCase.admin_user?.firstname} ${warrantyCase.admin_user?.lastname}`}</Text>
          <Text>Специалист по работе с клиентами</Text>
        </View>

        <View style={[styles.mb16]}>
          <Text>ООО "Континентал Тайрс РУС"</Text>
          <Text>Ленинградский пр-т, 15</Text>
          <Text>стр. 15, БЦ "Большевик", 1 этаж</Text>
          <Text>125040, г. Москва</Text>
          <Text>Российская Федерация</Text>
        </View>

        <View style={[styles.mb16]}>
          <Text>Тел: +7 (495) 787-67-35</Text>
        </View>
      </Page>
    </Document>
  )
}
