import { useMutation } from '@apollo/client'

import { GET_ARTICLE, LIKE_ARTICLE } from '../../queries/articles'
import { PROFILE } from '../../queries/auth'

export const useLikeArticle = (id: number) => {
  return useMutation<{ likeArticle: boolean }, { id: number }>(LIKE_ARTICLE, {
    variables: { id },
    refetchQueries: [{ query: GET_ARTICLE, variables: { id } }, { query: PROFILE }],
  })
}
