import React, { useState } from 'react'
import { Box, Chip, Divider, Grid, IconButton, Stack, styled, Typography } from '@mui/material'
import { CheckCircle as OkIcon, Close as NoIcon, Print as PrintIcon } from '@mui/icons-material'
import Lightbox from 'react-image-lightbox'
import { IWarrantyCase, WarrantyCaseStatus } from '../../graphql/types/warranty-case'
import {
  WARRANTY_CASE_DAMAGES,
  WARRANTY_CASE_TIRE_TYPES,
  WARRANTY_CASE_TYPES,
} from './WarrantyCaseForm/useWarrantyCaseFormik'
import { WarrantyCaseStatusChip } from './WarrantyCaseStatusChip'
import { withApiUrl } from '../../utils/helpers'

interface IProps {
  warrantyCase: IWarrantyCase
  onOpenPDF?: () => void
}

export const WarrantyCaseDetail: React.FC<IProps> = ({ warrantyCase, onOpenPDF }) => {
  console.log('ADMIN', warrantyCase.admin_user)

  return (
    <Box>
      <Stack direction='row' alignItems='center' mb={4}>
        <Box>
          <Typography color='text.secondary'>Составитель</Typography>
          <Typography variant='subtitle1'>{warrantyCase.seller.name}</Typography>
        </Box>
        <Box flexGrow={1} ml={4}>
          <Typography color='text.secondary'>Статус</Typography>
          <WarrantyCaseStatusChip status={warrantyCase.status} />
        </Box>
        {[WarrantyCaseStatus.REJECTED, WarrantyCaseStatus.SUCCESS].includes(warrantyCase.status) && (
          <Box>
            <IconButton onClick={onOpenPDF}>
              <PrintIcon />
            </IconButton>
          </Box>
        )}
      </Stack>

      <Box mb={4}>
        <Divider sx={{ mb: 1 }} />
        <TextRow label='Тип шин' value={WARRANTY_CASE_TIRE_TYPES[warrantyCase.tireType]} />
        <TextRow label='Обозначение шин' value={warrantyCase.tireDesignation} />
        <TextRow label='DOT-код шин' value={warrantyCase.dotCode} />
        <TextRow label='Глубина протектора, мм' value={warrantyCase.treadDepth.join(', ')} />
        <TextRow label='Среднее давление в шинах, бар' value={warrantyCase.avgPressure.join(', ')} />
        <TextRow label='Повреждения, претензии к шинам' value={WARRANTY_CASE_DAMAGES[warrantyCase.damage]} />
        <TextRow label='Ремонт/Замена' value={WARRANTY_CASE_TYPES[warrantyCase.type]} />
        <TextRow label='Цена/Стоимость ремонта без НДС' value={warrantyCase.cost} />
        <TextRow label='Километраж, км' value={warrantyCase.mileage} />
        <TextRow label='Артикул шин' value={warrantyCase.vendorCode} />
      </Box>

      <Grid container spacing={3} mb={5}>
        <Grid item xs={12} sm={6}>
          <BoolRow label='Ущерб здоровью людей' value={warrantyCase.damageHumanHealth} />
          <BoolRow label='Ущерб автомобиля' value={warrantyCase.carDamage} />
          <BoolRow label='Участие полиции' value={warrantyCase.policeInvolvement} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BoolRow label='Ущерб матер. ценностей' value={warrantyCase.damageProperty} />
          <BoolRow label='Ущерб других автомобилей' value={warrantyCase.damageOtherVehicles} />
          <BoolRow label='Участие страховой компании' value={warrantyCase.involvementInsurance} />
        </Grid>
      </Grid>

      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <ImageItem label='Шина целиком + дефект + борт' src={warrantyCase.wholeTireImage?.url} />
        <ImageItem label='Типоразмер, ин, ис' src={warrantyCase.sizeImage?.url} />
        <ImageItem label='DOT-код' src={warrantyCase.dotCodeImage?.url} />
        <ImageItem label='Повреждение' src={warrantyCase.damageImage?.url} />
        <ImageItem label='Купон + Чек' src={warrantyCase.couponCheckImage?.url} />
      </Stack>
    </Box>
  )
}

const TextRow: React.FC<{ label: string; value: string }> = ({ label, value }) => {
  return (
    <Box mb={1}>
      <Stack direction='row' justifyContent='space-between' mb={1}>
        <Typography color='text.secondary'>{label}</Typography>
        <Typography>{value}</Typography>
      </Stack>
      <Divider />
    </Box>
  )
}

const BoolRow: React.FC<{ label: string; value: boolean }> = ({ label, value }) => {
  return (
    <Box mb={1}>
      <Chip label={label} icon={value ? <OkIcon /> : <NoIcon />} />
    </Box>
  )
}

const ImageItem: React.FC<{ label: string; src?: string }> = ({ label, src }) => {
  const [preview, setPreview] = useState(false)

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ height: 170, bgcolor: 'grey.200', mb: 1 }}>
        {!!src && <Image src={withApiUrl(src)} alt={label} onClick={() => setPreview(true)} />}
      </Box>
      <Typography color='text.secondary'>{label}</Typography>
      {preview && !!src && (
        <Lightbox
          imageTitle={label}
          mainSrc={withApiUrl(src)!}
          onCloseRequest={() => setPreview(false)}
          reactModalStyle={{ overlay: { zIndex: 3110 } }}
        />
      )}
    </Box>
  )
}

const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  cursor: 'pointer',
}))
