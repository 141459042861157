import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { IFindVariables } from '../../types/common'
import { SELLERS_RATING } from '../../queries/leaders'
import { ISeller } from '../../types/sellers'

export const useSellersRating = (limit: number) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ sellers: ISeller[]; sellersCount: number }, IFindVariables>(SELLERS_RATING, {
    variables: {
      sort: 'points:DESC',
      limit,
      start: (page - 1) * limit,
    },
  })

  const sellers = data?.sellers || []
  const total = data?.sellersCount || 0

  return {
    sellers,
    total,
    page,
    setPage,
    ...q,
  }
}
