import { Box, styled, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import DOMPurify from 'dompurify'
import { useSnackbar } from 'notistack'

import { PageHeader, PageNotFound } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { DATE_FORMAT } from '../../core/constants'
import { withApiUrl } from '../../utils/helpers'
import { NewsLike } from './components'
import { useArticle, useLikeArticle } from '../../graphql/hooks/articles'

function NewsSingle() {
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()
  const { article, likesCount, didILike, loading } = useArticle(parseInt(id, 10))
  const [likeArticle, { loading: likeLoading }] = useLikeArticle(parseInt(id, 10))

  if (loading) {
    return <Loader />
  }

  if (!article) {
    return <PageNotFound />
  }

  const handleLike = async () => {
    try {
      await likeArticle()
      enqueueSnackbar('Успешно отправлено', { variant: 'success' })
    } catch (err) {
      console.log(err)
    }
  }

  const html =
    article.content &&
    DOMPurify.sanitize(article.content, {
      USE_PROFILES: { html: true },
    })

  return (
    <Box>
      <PageHeader title={article.title} back />
      <Box mb={3}>
        <Typography color='text.secondary' gutterBottom>
          {moment(article.published_at).format(DATE_FORMAT)}
        </Typography>

        {article.image && (
          <Box mb={3}>
            <NewsImg src={withApiUrl(article.image?.url)} alt='' />
          </Box>
        )}

        {!!html && <div dangerouslySetInnerHTML={{ __html: html }} />}
      </Box>
      <Box>
        <NewsLike likes={likesCount} didILike={didILike} onLike={handleLike} loading={likeLoading} />
      </Box>
    </Box>
  )
}

const NewsImg = styled('img')(({ theme }) => ({
  maxWidth: '100%',
}))

export default NewsSingle
