import React from 'react'
import { Page, View, Text, Document } from '@react-pdf/renderer'
import moment from 'moment'
import { IWarranty } from '../../../graphql/types/warranty'
import styles from './styles'
import { DATE_FORMAT } from '../../../core/constants'

interface IProps {
  warranty: IWarranty
}

export const WarrantyPDF: React.FC<IProps> = ({ warranty }) => {
  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View style={[styles.mb8]}>
          <Text style={styles.h3}>КУПОН {`${warranty.brand.title}`}</Text>
        </View>

        <View style={styles.section}>
          <Text style={[styles.h4, styles.lh1]}>СЕРТИФИКАТ ПОТРЕБИТЕЛЯ ПО РАСШИРЕННЫМ УСЛОВИЯМ К ГАРАНТИИ</Text>
          <Text style={[styles.h4, styles.mb16]}>{`${warranty.brand.title}`}</Text>
          <View style={styles.gridContainer}>
            <View style={styles.col8}>
              <View style={styles.mb8}>
                <Text>Точка продаж</Text>
                <Text style={[styles.h4, styles.lh1]}>{warranty.dealer.name}</Text>
                <Text style={[styles.h4]}>{`${warranty.dealer.city?.title}, ${warranty.dealer.address}`}</Text>
              </View>
              <View style={styles.mb8}>
                <Text>ФИО продавца</Text>
                <Text style={styles.h4}>{warranty.seller.name}</Text>
              </View>
              <View>
                <Text>Дата покупки</Text>
                {!!warranty.purchaseDate ? (
                  <Text style={styles.h4}>{moment(warranty.purchaseDate).format(DATE_FORMAT)}</Text>
                ) : (
                  <Text style={styles.h4}>____________________</Text>
                )}
              </View>
            </View>
            <View style={styles.col4}>
              <View style={styles.mb40} />
              <View style={styles.mb16}>
                <View style={styles.divider} />
                <Text>Подпись</Text>
              </View>
              <View>
                <Text style={styles.h4}>М.П.</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.gridContainer, { alignItems: 'center', justifyContent: 'center', paddingTop: 8 }]}>
            <Text style={[{ marginRight: 16 }]}>КОД ПОКУПАТЕЛЯ:</Text>
            <Text style={[styles.h3]}>{warranty.code}</Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={[styles.gridContainer, styles.mb40]}>
            <View style={[styles.col6]}>
              <View style={[styles.gridContainer]}>
                <View style={[styles.stepCircle]}>
                  <Text style={[styles.h4, { lineHeight: 1.1 }]}>1</Text>
                  <Text>ШАГ</Text>
                </View>
                <View style={{ width: '75%', marginRight: 8 }}>
                  <Text style={styles.body1}>
                    Для активации РГ вам необходимо зайти на сайт www.contipartners.kz и зарегистрироваться по кнопке
                    "Активировать талон"
                  </Text>
                </View>
              </View>
            </View>
            <View style={[styles.col6]}>
              <View style={[styles.gridContainer]}>
                <View style={[styles.stepCircle, { marginLeft: 8 }]}>
                  <Text style={[styles.h4, { lineHeight: 1.1 }]}>2</Text>
                  <Text>ШАГ</Text>
                </View>
                <View style={{ width: '75%' }}>
                  <Text style={styles.body1}>
                    В случае наступления гарантийного случая вам необходимо обратиться в магазин, где осуществляется
                    ремонт/замена, предъявив бумажный талон c печатью оргагнизации-продавца, а также фискальный чек.
                  </Text>
                  <Text style={styles.body1}>
                    Просим Вас обязательно сохранять все полученные при покупке документы!
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View>
            <Text style={styles.h4}>ОСНОВНЫЕ ПОЛОЖЕНИЯ УСЛОВИЙ ГАРАНТИИ</Text>
            <Text style={styles.body1}>
              В соответствии с Расширенными условиями гарантии, при обнаружении в процессе эксплуатации шины
              непреднамеренного повреждения, т.е. в результате внешнего механического воздействия, и потери шипов,
              компания (через своих Дистрибьюторов и Авторизованные торговые точки) обязуется произвести один бесплатный
              ремонт (в случае ремонтопригодности) или одну бесплатную замену (в случае неремонтопригодности) купленной
              шины. При обращении по гарантии клиент обязан предоставить дилеру купон на гарантию, товарный и кассовый
              чеки. Условия и сроки программы могут быть изменены без предварительного уведомления. С актуальными
              условиями программы Вы можете ознакомиться на официальном сайте Continental www.continental.ru.
            </Text>
          </View>
        </View>

        <View style={[styles.gridContainer, styles.footer]}>
          <View style={styles.col4}>
            {/* <Text style={styles.subtitle2}>NOTES</Text>
            <Text>We appreciate your business. Should you need us to add VAT or extra notes let us know!</Text> */}
          </View>
          <View style={[styles.col8, styles.alignRight]}>
            <Text style={styles.subtitle2}>Покупатель ознакомлен_______________________________</Text>
            {/* <Text>support@abcapp.com</Text> */}
          </View>
        </View>
      </Page>
    </Document>
  )
}
