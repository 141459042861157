import { Route, RouteProps } from 'react-router'
import { observer } from 'mobx-react-lite'

import { useStores } from '../../stores/hooks'
import { PageNotFound } from '../Layout'

export const RoleRoute: React.FC<{ role: string } & RouteProps> = observer(
  ({ role, component: Component, ...routeProps }) => {
    const { authStore } = useStores()
    const isAuthorized = authStore.user?.role.type === role

    return (
      <Route
        {...routeProps}
        render={(props) => (isAuthorized && Component ? <Component {...props} /> : <PageNotFound />)}
      />
    )
  }
)
