import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'
import { Formik, FormikProps } from 'formik'
import { observer } from 'mobx-react-lite'

import { ITesting, ITestingResultItem } from '../../../../graphql/types/testings'
import { testingFormSchema } from './validation'
import { TestingProgress } from './TestingProgress'
import { TestingQuestion } from './TestingQuestion'

export interface ITestingFormValues {
  results: ITestingResultItem[]
}

interface IProps {
  testing: ITesting
  onSubmit: (values: ITestingFormValues) => void
  onCancel?: () => void
  loading?: boolean
}

export const TestingForm: React.FC<IProps> = observer(({ testing, onSubmit, onCancel, loading }) => {
  const questions = testing.questions
  const [activeQuestionIndex, setActiveQuestionIndex] = useState<number>(0)

  const initialValues: ITestingFormValues = {
    results: [],
  }

  const handleFormSubmit = (values: ITestingFormValues) => {
    onSubmit(values)
  }

  const handlePrev = () => {
    if (activeQuestionIndex === 0) {
      return
    }

    setActiveQuestionIndex((prev) => prev - 1)
  }

  const handleNext = () => {
    if (activeQuestionIndex === questions.length - 1) {
      return
    }

    setActiveQuestionIndex((prev) => prev + 1)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={testingFormSchema(testing.questions.length)}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, submitForm, handleSubmit }: FormikProps<ITestingFormValues>) => {
        const progress = (values.results.length * 100) / questions.length

        return (
          <form onSubmit={handleSubmit}>
            <Box mb={2} display='flex'>
              <Box mx={1}>
                <Typography variant='body2' color='text.secondary'>{`${activeQuestionIndex + 1}/${
                  questions.length
                }`}</Typography>
              </Box>
              <Box flexGrow={1}>
                <TestingProgress value={progress} />
              </Box>
            </Box>
            <Box mb={3}>
              <TestingQuestion question={questions[activeQuestionIndex]} />
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <Box>
                <Button disabled={activeQuestionIndex === 0} variant='contained' sx={{ mr: 2 }} onClick={handlePrev}>
                  Назад
                </Button>
                <Button
                  disabled={activeQuestionIndex === questions.length - 1}
                  variant='contained'
                  onClick={handleNext}
                >
                  Далее
                </Button>
              </Box>
              {questions.length === values.results.length && (
                <Button
                  disabled={loading}
                  color='primary'
                  variant='contained'
                  startIcon={<SendIcon />}
                  onClick={submitForm}
                >
                  Отправить
                </Button>
              )}
            </Box>
          </form>
        )
      }}
    </Formik>
  )
})
