import { Avatar, Box, Button, Card, CardContent, CardMedia, Grid, Stack, Typography } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import { welcomeBg } from '../../../assets/images'
import { IDealer } from '../../../graphql/types/dealers'
import { useStores } from '../../../stores/hooks'
import { withApiUrl } from '../../../utils/helpers'

export const Welcome = observer(() => {
  const { authStore } = useStores()
  const { user, userType } = authStore

  if (!user || !userType) {
    return null
  }

  return (
    <Box>
      <Card sx={{ display: 'flex' }}>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={6}>
            <CardMedia sx={{ height: { xs: 150, sm: 300 } }} component='img' image={welcomeBg} />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Stack direction='column' alignItems='center'>
                <Box mb={1}>
                  <Avatar
                    sx={{
                      width: 105,
                      height: 105,
                      bgcolor: 'grey.300',
                      border: (t) => `5px solid ${t.palette.grey[200]}`,
                    }}
                    alt={userType.name}
                    src={withApiUrl(userType.avatar?.url)}
                  />
                </Box>

                <Box textAlign='center'>
                  <Typography variant='h6'>Добро пожаловать, {userType.name}!</Typography>
                  {authStore.isDealer && (
                    <Typography color='text.secondary'>
                      {(userType as IDealer).city?.title}, {(userType as IDealer).address}
                    </Typography>
                  )}
                  <Typography color='text.secondary'>{userType.contacts}</Typography>
                </Box>

                <Button variant='outlined' size='small' component={Link} to='/profile' sx={{ mt: { xs: 2 } }}>
                  Профиль
                </Button>
              </Stack>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
})
