import { useMutation } from '@apollo/client'

import { GET_WARRANTY, UPDATE_WARRANTY_PRODUCTS } from '../../queries/warranty'
import { IUpdateWarrantyProductsData, IUpdateWarrantyProductsVariables } from '../../types/warranty'

export const useUpdateWarrantyProducts = (id: number) => {
  return useMutation<IUpdateWarrantyProductsData, IUpdateWarrantyProductsVariables>(UPDATE_WARRANTY_PRODUCTS, {
    refetchQueries: [{ query: GET_WARRANTY, variables: { id } }],
  })
}
