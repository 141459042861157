import { gql } from '@apollo/client'

import { BRAND_FRAGMENT } from './brands'
import { PRODUCT_FRAGMENT } from './products'
import { CUSTOMER_FRAGMENT } from './customer'

export const WARRANTY_FRAGMENT = gql`
  fragment WarrantyFragment on Warranty {
    id
    code
    brand {
      ...BrandFragment
    }
    product {
      ...ProductFragment
    }
    secondProduct {
      ...ProductFragment
    }
    dealer {
      id
      name
      email
      address
      city {
        id
        title
      }
    }
    seller {
      id
      name
    }
    customer {
      ...CustomerFragment
    }
    purchaseDate
    created_at
    updated_at
  }
  ${BRAND_FRAGMENT}
  ${PRODUCT_FRAGMENT}
  ${CUSTOMER_FRAGMENT}
`

export const GET_WARRANTIES = gql`
  query GetWarrantiesQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    warranties(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...WarrantyFragment
    }
    warrantiesCount(where: $where)
  }
  ${WARRANTY_FRAGMENT}
`

export const GET_WARRANTY = gql`
  query GetWarrantyQuery($id: ID!) {
    warranty(id: $id) {
      ...WarrantyFragment
    }
  }
  ${WARRANTY_FRAGMENT}
`

export const WARRANTY_BY_CODE = gql`
  query FindWarrantyByCodeQuery($code: String!) {
    warrantyByCode(code: $code) {
      ...WarrantyFragment
    }
  }
  ${WARRANTY_FRAGMENT}
`

export const GENERATE_WARRANTY = gql`
  mutation GenerateWarrantyMutation($input: GenerateWarrantyInput!) {
    generateWarranty(input: $input) {
      ...WarrantyFragment
    }
  }
  ${WARRANTY_FRAGMENT}
`

export const UPDATE_WARRANTY_PRODUCTS = gql`
  mutation UpdateWarrantyProductsMutation($id: ID!, $input: UpdateWarrantyProductsInput!) {
    updateWarrantyProducts(id: $id, input: $input) {
      ...WarrantyFragment
    }
  }
  ${WARRANTY_FRAGMENT}
`

export const REGISTER_CUSTOMER_TO_WARRANTY = gql`
  mutation RegisterCustomerToWarrantyMutation($input: RegisterCustomerToWarrantyInput!) {
    registerCustomerToWarranty(input: $input)
  }
`
