import { useMutation } from '@apollo/client'

import { REGISTER_CUSTOMER_TO_WARRANTY } from '../../queries/warranty'
import { IRegisterCustomerToWarrantyInput } from '../../types/warranty'

export const useRegisterCustomer = () => {
  return useMutation<{ registerCustomerToWarranty: boolean }, { input: IRegisterCustomerToWarrantyInput }>(
    REGISTER_CUSTOMER_TO_WARRANTY
  )
}
