import React from 'react'
import { Box, Dialog, DialogContent, DialogTitle, IconButton, styled } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { IWarranty } from '../../../graphql/types/warranty'
import { RegisterWarrantyForm } from './RegisterWarrantyForm'

interface IProps {
  warranty: IWarranty
  open: boolean
  onClose?: VoidFunction
}

export const RegisterCustomerDialog: React.FC<IProps> = ({ warranty, open, onClose }) => {
  const { enqueueSnackbar } = useSnackbar()

  const handleRegistered = () => {
    enqueueSnackbar('Данные покупателя успешно зарегистрированы', { variant: 'success' })
    onClose?.()
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open}>
      <CloseBox>
        <IconButton color='inherit' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseBox>
      <DialogTitle sx={{ mb: 2 }}>Регистрация купона покупателя</DialogTitle>
      <DialogContent>
        <Box>
          <RegisterWarrantyForm refetchWarranty initialWarranty={warranty} onRegistered={handleRegistered} />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

const CloseBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 9,
}))
