import { useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { useApolloClient, useMutation } from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'

import { ResetPasswordForm, IResetPasswordFormValues } from './components'
import { PROFILE, RESET_PASSWORD } from '../../graphql/queries/auth'
import { ILoginData, IProfilePayload } from '../../graphql/types/auth'
import { useStores } from '../../stores/hooks'

function ResetPassword() {
  const qs = new URLSearchParams(useLocation().search)
  const code = qs.get('code')

  const { authStore } = useStores()
  const client = useApolloClient()
  const history = useHistory()
  const [error, setError] = useState<string>()
  const [resetPassword, { loading }] = useMutation<{ resetPassword: ILoginData }>(RESET_PASSWORD)

  const handleSubmit = async (values: IResetPasswordFormValues) => {
    try {
      const res = await resetPassword({
        variables: {
          ...values,
          code,
        },
      })

      if (res.data?.resetPassword) {
        authStore.setToken(res.data.resetPassword.jwt)

        const { data } = await client.query<IProfilePayload>({
          query: PROFILE,
          fetchPolicy: 'network-only',
        })

        authStore.updateUserWithType(data.profile.user, data.profile.type)
        authStore.setSalesCount(data.userSalesCount)

        history.push('/')
      }
    } catch (err) {
      setError('Неверные данные')
      console.log(err)
    }
  }

  if (!code) {
    return null
  }

  return (
    <Box>
      <Box pt={'260px'} mb={3}>
        <Typography variant='h4'>Восстановление пароля</Typography>
      </Box>
      {!!error && (
        <Box mb={2}>
          <Alert severity='error' variant='outlined'>
            {error}
          </Alert>
        </Box>
      )}
      <ResetPasswordForm loading={loading} onSubmit={handleSubmit} />
    </Box>
  )
}

export default ResetPassword
