import { gql } from '@apollo/client'

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on UploadFile {
    id
    url
  }
`

export const SINGLE_UPLOAD = gql`
  mutation SingUploadMutation($refId: ID, $ref: String, $field: String, $file: Upload!) {
    upload(refId: $refId, ref: $ref, field: $field, file: $file) {
      ...ImageFragment
    }
  }
  ${IMAGE_FRAGMENT}
`
