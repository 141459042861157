import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_PRIZES } from '../../queries/prizes'
import { IGetPrizesVariables, IGetPrizesResponse } from '../../types/prize'

export const usePrizes = (limit: number = 9) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ myPrizes: IGetPrizesResponse }, IGetPrizesVariables>(GET_PRIZES, {
    variables: {
      limit,
      start: (page - 1) * limit,
      sort: 'created_at:DESC',
    },
  })

  const prizes = data?.myPrizes.prizes || []
  const total = data?.myPrizes.total || 0

  return {
    prizes,
    total,
    page,
    setPage,
    ...q,
  }
}
