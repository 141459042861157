import { Box, Button, Stack } from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'
import { ImagesForm } from './ImagesForm'
import { GeneralForm } from './GeneralForm'
import { SwitchesForm } from './SwitchesForm'
import { IWarrantyCaseFormValues, useWarrantyCaseFormik } from './useWarrantyCaseFormik'
import { FormikProvider, Form } from 'formik'
import { LoadableButton } from '../../../components/Form'
import { useSnackbar } from 'notistack'
import { useCreateWarrantyCase } from '../../../graphql/hooks/warranty-case'
import { IWarranty } from '../../../graphql/types/warranty'
import { IWarrantyCaseInput } from '../../../graphql/types/warranty-case'

interface IProps {
  warranty: IWarranty
  onSucess?: () => void
}

export const WarrantyCaseForm: React.FC<IProps> = ({ warranty, onSucess }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [createWarrantyCase] = useCreateWarrantyCase(warranty.id)

  const formik = useWarrantyCaseFormik({
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const { data } = await createWarrantyCase({
          variables: { input: { data: inputFromValues(values, warranty) } },
        })

        if (data?.createWarrantyCase.warrantyCase) {
          enqueueSnackbar('Гарантийный случай добавлен успешно', { variant: 'success' })
          onSucess?.()
        }
      } catch (err) {
        console.log(err)
      } finally {
        setSubmitting(false)
      }
    },
  })

  const { handleSubmit, isSubmitting } = formik

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Box>
          <GeneralForm />
          <SwitchesForm />
          <Box mb={8}>
            <ImagesForm />
          </Box>

          <Box>
            <Stack direction='row' spacing={2} justifyContent='flex-end'>
              <Button size='large'>Закрыть</Button>
              <LoadableButton
                loading={isSubmitting}
                variant='contained'
                size='large'
                type='submit'
                startIcon={<SendIcon />}
              >
                Отправить заявку
              </LoadableButton>
            </Stack>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  )
}

function inputFromValues(values: IWarrantyCaseFormValues, warranty: IWarranty) {
  return {
    warranty: warranty.id,
    ...values,
    cost: String(values.cost),
    mileage: String(values.mileage),
    type: parseInt(`${values.type}`),
    damage: parseInt(`${values.damage}`),
    tireType: parseInt(`${values.tireType}`),
    wholeTireImage: values.wholeTireImage?.id,
    sizeImage: values.sizeImage?.id,
    dotCodeImage: values.dotCodeImage?.id,
    damageImage: values.damageImage?.id,
    couponCheckImage: values.couponCheckImage?.id,
    optImage1: values.optImage1?.id,
    optImage2: values.optImage2?.id,
  } as IWarrantyCaseInput
}
