import { gql } from '@apollo/client'

export const CITY_FRAGMENT = gql`
  fragment CityFragment on Cities {
    id
    title
  }
`

export const GET_CITIES = gql`
  query GetCitiesQuery {
    cities {
      ...CityFragment
    }
  }
  ${CITY_FRAGMENT}
`
