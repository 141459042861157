import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_SALES } from '../../queries/sales'
import { ISalesPayload, IGetSalesVariables } from '../../types/sales'

export const useSales = (limit: number = 12) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ userSales: ISalesPayload }, IGetSalesVariables>(GET_SALES, {
    variables: {
      limit,
      start: (page - 1) * limit,
    },
    fetchPolicy: 'network-only',
  })

  const sales = data?.userSales.sales || []
  const total = data?.userSales.total || 0

  return {
    sales,
    total,
    page,
    setPage,
    ...q,
  }
}
