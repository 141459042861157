import { gql } from '@apollo/client'

export const TESTING_RESULT_FRAGMENT = gql`
  fragment TestingResultFragment on TestingResult {
    id
    results
    points
    correct_count
    is_passed
    created_at
  }
`

export const TESTING_FRAGMENT = gql`
  fragment TestingFragment on TestingTesting {
    id
    title
    description
    points
    status
    questions {
      id
      title
      answers {
        id
        title
      }
    }
    created_at
    myResult {
      ...TestingResultFragment
    }
  }
  ${TESTING_RESULT_FRAGMENT}
`

export const GET_TESTINGS = gql`
  query GetTestingsQuery($sort: String, $limit: Int, $start: Int) {
    userTestings(sort: $sort, limit: $limit, start: $start) {
      testings {
        ...TestingFragment
      }
      total
    }
  }
  ${TESTING_FRAGMENT}
`

export const GET_TESTING = gql`
  query GetTestingQuery($id: ID!) {
    userTesting(id: $id) {
      ...TestingFragment
    }
  }
  ${TESTING_FRAGMENT}
`

export const SEND_TESTING_RESULTS = gql`
  mutation SendTestingResultsMutation($input: UserTestingResultsInput!) {
    sendTestingResults(input: $input) {
      ...TestingResultFragment
    }
  }
  ${TESTING_RESULT_FRAGMENT}
`
