import { useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { useHistory, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useLogin } from '../../graphql/hooks/auth/useLogin'
import { ILoginFormValues, LoginForm } from './components'
import { useStores } from '../../stores/hooks'

function Login() {
  const history = useHistory()
  const { authStore } = useStores()
  const { login, loading } = useLogin()
  const [error, setError] = useState<string>()

  if (authStore.isLoggedIn) {
    return <Redirect to='/' />
  }

  const handleSubmit = async (values: ILoginFormValues) => {
    try {
      await login(values)
      history.push('/')
    } catch (err) {
      setError('Неверные данные')
      console.log(err)
    }
  }

  return (
    <Box>
      <Box pt={'260px'} mb={3}>
        <Typography variant='h4'>Авторизация</Typography>
      </Box>
      {!!error && (
        <Box mb={2}>
          <Alert severity='error' variant='outlined'>
            {error}
          </Alert>
        </Box>
      )}
      <LoginForm loading={loading} onSubmit={handleSubmit} />
    </Box>
  )
}

export default observer(Login)
