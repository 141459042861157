import React from 'react'
import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  styled,
  Typography,
  Stack,
} from '@mui/material'
import { Close as CloseIcon, Person as CustomerIcon, DirectionsCar as CarIcon } from '@mui/icons-material'
import { ICustomer } from '../../graphql/types/customer'

interface IProps {
  customer: ICustomer
  open: boolean
  onClose?: VoidFunction
}

export const CustomerDetailDialog: React.FC<IProps> = ({ customer, open, onClose }) => {
  return (
    <Dialog maxWidth='sm' fullWidth open={open}>
      <CloseBox>
        <IconButton color='inherit' onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </CloseBox>
      <DialogTitle sx={{ mb: 2 }}>Данные покупателя</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Stack direction='row'>
              <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>
                <CustomerIcon />
              </Avatar>
              <Box>
                <Typography variant='body1'>{customer.fullName}</Typography>
                {/* <Typography variant='body1'>{customer.phone}</Typography>
                <Typography variant='body1'>{customer.email}</Typography> */}
                <Typography variant='body1'>{customer.city.title}</Typography>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack direction='row'>
              <Avatar sx={{ mr: 2, bgcolor: 'primary.main' }}>
                <CarIcon />
              </Avatar>
              <Box>
                <Typography variant='body1'>{`${customer.carBrand} ${customer.carModel}`}</Typography>
                <Typography variant='body1'>{customer.carYear}</Typography>
                <Typography variant='body1'>{customer.exploitation.title}</Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>{/* <Button>Редактировать</Button> */}</DialogActions>
    </Dialog>
  )
}

const CloseBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: 9,
}))
