import { gql } from '@apollo/client'

export const DEALER_FRAGMENT = gql`
  fragment DealerFragment on Dealer {
    id
    name
    email
    points
    director
    contacts
    address
    avatar {
      id
      url
    }
    city {
      id
      title
    }
  }
`

export const SEND_POINTS_TO_SELLER = gql`
  mutation SendPointsToSellerMutation($sellerId: ID!, $points: Int!) {
    sendPointsToSeller(sellerId: $sellerId, points: $points)
  }
`
