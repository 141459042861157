import { useState } from 'react'
import { Box, Grid, IconButton, Pagination, Stack } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'

import { PageHeader } from '../../components/Layout'
import { AddSellerModal, SellerItem } from './components'
import { useSellers } from '../../graphql/hooks/sellers'
import { EmptyListMessage, Loader } from '../../components/UI'

function Sellers() {
  const [addModalVisible, setAddModalVisible] = useState(false)
  const { sellers, total, page, setPage, loading } = useSellers(12)
  const paginationCount = Math.ceil(total / 12)

  const toggleAddModal = () => {
    setAddModalVisible((prev) => !prev)
  }

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <PageHeader
        title='Продавцы'
        rightContent={
          <IconButton title='Добавить продавца' onClick={toggleAddModal}>
            <AddIcon />
          </IconButton>
        }
      />
      {loading && <Loader />}
      {sellers.length > 0 && !loading ? (
        <Grid mb={4} container spacing={2}>
          {sellers.map((item) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`seller-${item.id}`} mb={2}>
              <SellerItem seller={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyListMessage message='Продавцов еще нет' />
      )}
      {paginationCount > 1 && (
        <Stack alignItems='center'>
          <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
        </Stack>
      )}
      <AddSellerModal open={addModalVisible} onClose={toggleAddModal} />
    </Box>
  )
}

export default Sellers
