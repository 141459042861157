import * as yup from 'yup'

export const warrantyCaseSchema = yup.object().shape({
  tireType: yup.number().required(),
  tireDesignation: yup.string().required(),
  dotCode: yup.string().required(),
  treadDepth: yup.array().of(yup.string().required()).min(3).required(),
  avgPressure: yup.array().of(yup.string().required()).min(2).required(),
  damage: yup.number().required(),
  type: yup.number().required(),
  cost: yup.string().required(),
  mileage: yup.string().required(),
  vendorCode: yup.string().required(),
  // Switches
  damageHumanHealth: yup.bool().required(),
  carDamage: yup.bool().required(),
  policeInvolvement: yup.bool().required(),
  damageProperty: yup.bool().required(),
  damageOtherVehicles: yup.bool().required(),
  involvementInsurance: yup.bool().required(),
  // Images
  wholeTireImage: yup.mixed().required(),
  sizeImage: yup.mixed().required(),
  dotCodeImage: yup.mixed().required(),
  damageImage: yup.mixed().required(),
  couponCheckImage: yup.mixed().required(),
})
