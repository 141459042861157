import { gql } from '@apollo/client'

export const CUSTOMER_FRAGMENT = gql`
  fragment CustomerFragment on Customer {
    id
    created_at
    updated_at
    fullName
    email
    phone
    birthday
    city {
      id
      title
    }
    carBrand
    carModel
    carYear
    exploitation {
      id
      title
    }
  }
`
