import { Box, Pagination, Stack } from '@mui/material'

import { PageHeader, TopWidgets } from '../../components/Layout'
import { SalesItem } from '../../components/Sales'
import { EmptyListMessage, Loader } from '../../components/UI'
import { useSales } from '../../graphql/hooks/sales'

function Sales() {
  const { sales, total, page, setPage, loading } = useSales(12)
  const paginationCount = Math.ceil(total / 12)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <Box mb={4}>
        <TopWidgets />
      </Box>
      <PageHeader title='Продажи' />
      <Box mb={4}>
        {loading && <Loader />}
        {sales.length > 0 && !loading ? (
          <Box>
            {sales.map((item) => (
              <Box key={`sales-${item.id}`} mb={2}>
                <SalesItem sale={item} />
              </Box>
            ))}
          </Box>
        ) : (
          <EmptyListMessage message='Продаж еще нет' />
        )}
      </Box>
      {paginationCount > 1 && (
        <Stack alignItems='center'>
          <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
        </Stack>
      )}
    </Box>
  )
}

export default Sales
