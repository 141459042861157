import React from 'react'
import { CircularProgress, Button, ButtonProps } from '@mui/material'

interface IProps extends ButtonProps {
  loading?: boolean
}

export const LoadableButton: React.FC<IProps> = ({ loading, children, ...props }) => {
  return (
    <Button {...props} disabled={loading}>
      {loading && <CircularProgress size={20} sx={{ marginRight: 8 }} />} {children}
    </Button>
  )
}
