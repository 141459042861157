import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import { Block as SuspendIcon, PlayCircleOutline as UnsuspendIcon } from '@mui/icons-material'

import { ISeller } from '../../../graphql/types/sellers'
import { withApiUrl } from '../../../utils/helpers'
import { useSwitchSellerSuspended } from '../../../graphql/hooks/sellers'
import { useConfirm } from '../../../services/confirmation'
import { SendPointsModal } from './SendPointsModal'

interface IProps {
  seller: ISeller
}

export const SellerItem: React.FC<IProps> = ({ seller }) => {
  const [sendPointsModalVisible, setSendPointsModalVisible] = useState(false)
  const [switchSuspended, { loading }] = useSwitchSellerSuspended()
  const confirm = useConfirm()

  const handleSwitchSuspended = async () => {
    if (!seller.suspended) {
      try {
        await confirm({ message: 'Вы действительно хотите приостановить работу сотрудника?' })
      } catch {
        return
      }
    }

    try {
      await switchSuspended({
        variables: { id: seller.id },
      })
    } catch (err) {
      console.log(err)
    }
  }

  const toggleSendPointsModal = () => {
    setSendPointsModalVisible((prev) => !prev)
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ opacity: seller.suspended ? 0.3 : 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <Box mb={2}>
            <Avatar
              sx={{ width: 120, height: 120, bgcolor: 'grey.300' }}
              alt={seller.name}
              src={withApiUrl(seller.avatar?.url)}
            />
          </Box>
          <Typography>{seller.name}</Typography>
          <Typography variant='caption' color='text.secondary'>
            {seller.email}
          </Typography>
          <Box>
            {seller.isRegistered ? (
              <Typography color='primary.main' variant='button'>
                Баллы: {seller.points}
              </Typography>
            ) : (
              <Stack direction='row' alignItems='center'>
                <Typography variant='button' color='text.secondary'>
                  Приглашение отправлено...
                </Typography>
              </Stack>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardActions sx={{ justifyContent: 'space-between' }}>
        {seller.isRegistered ? (
          <Button size='small' color='primary' onClick={toggleSendPointsModal}>
            Перевести баллы
          </Button>
        ) : (
          <div />
          // <Button size='small' color='warning'>
          //   Пригласить еще раз
          // </Button>
        )}
        {loading ? (
          <CircularProgress size={20} />
        ) : (
          <IconButton
            size='small'
            color='default'
            title='Приостановить работу сотрудника'
            onClick={handleSwitchSuspended}
          >
            {seller.suspended ? <UnsuspendIcon color='success' fontSize='small' /> : <SuspendIcon fontSize='small' />}
          </IconButton>
        )}
      </CardActions>
      {sendPointsModalVisible && <SendPointsModal seller={seller} open onClose={toggleSendPointsModal} />}
    </Card>
  )
}
