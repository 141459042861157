import * as yup from 'yup'

export const createSalesSchema = yup.object().shape({
  products: yup
    .array()
    .of(
      yup.object().shape({
        product: yup.object().shape({
          id: yup.number().required(),
          title: yup.string(),
        }),
        kit: yup.string().oneOf(['2', '4']).required(),
      })
    )
    .min(1)
    .required(),
})
