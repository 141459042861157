import { Box, Grid, Stack, TextField, Typography } from '@mui/material'
import { useFormikContext } from 'formik'
import {
  IWarrantyCaseFormValues,
  WARRANTY_CASE_DAMAGES,
  WARRANTY_CASE_TIRE_TYPES,
  WARRANTY_CASE_TYPES,
} from './useWarrantyCaseFormik'

export const GeneralForm = () => {
  const { errors, getFieldProps } = useFormikContext<IWarrantyCaseFormValues>()

  return (
    <Grid container spacing={3} mb={5}>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <TextField
            label='Тип шин'
            margin='none'
            select
            SelectProps={{
              native: true,
            }}
            {...getFieldProps('tireType')}
            error={!!errors.tireType}
          >
            {WARRANTY_CASE_TIRE_TYPES.map((o, index) => (
              <option key={o} value={index}>
                {o}
              </option>
            ))}
          </TextField>
          <TextField
            label='Обозначение шин'
            margin='none'
            {...getFieldProps('tireDesignation')}
            error={!!errors.tireDesignation}
          />
          <TextField label='DOT-код шин' margin='none' {...getFieldProps('dotCode')} error={!!errors.dotCode} />
          <Box>
            <Typography color='text.secondary' mb={0.5}>
              Глубина протектора, мм
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField label='' margin='none' {...getFieldProps('treadDepth.0')} error={!!errors.treadDepth} />
              <TextField label='' margin='none' {...getFieldProps('treadDepth.1')} error={!!errors.treadDepth} />
              <TextField label='' margin='none' {...getFieldProps('treadDepth.2')} error={!!errors.treadDepth} />
            </Stack>
          </Box>
          <Box>
            <Typography color='text.secondary' mb={1}>
              Среднее давление в шинах, бар
            </Typography>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                label='Передняя ось'
                margin='none'
                {...getFieldProps('avgPressure.0')}
                error={!!errors.avgPressure}
              />
              <TextField
                label='Задняя ось'
                margin='none'
                {...getFieldProps('avgPressure.1')}
                error={!!errors.avgPressure}
              />
            </Stack>
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack spacing={2}>
          <TextField
            label='Повреждения, претензии к шинам'
            margin='none'
            select
            SelectProps={{
              native: true,
            }}
            {...getFieldProps('damage')}
            error={!!errors.damage}
          >
            {WARRANTY_CASE_DAMAGES.map((o, index) => (
              <option key={o} value={index}>
                {o}
              </option>
            ))}
          </TextField>
          <TextField
            label='Ремонт/Замена'
            margin='none'
            select
            SelectProps={{
              native: true,
            }}
            {...getFieldProps('type')}
            error={!!errors.type}
          >
            {WARRANTY_CASE_TYPES.map((o, index) => (
              <option key={o} value={index}>
                {o}
              </option>
            ))}
          </TextField>
          <TextField
            label='Цена/Стоимость ремонта без НДС'
            margin='none'
            type='number'
            {...getFieldProps('cost')}
            error={!!errors.cost}
          />
          <TextField
            label='Километраж, км'
            type='number'
            margin='none'
            {...getFieldProps('mileage')}
            error={!!errors.mileage}
          />
          <TextField label='Артикул шин' margin='none' {...getFieldProps('vendorCode')} error={!!errors.vendorCode} />
        </Stack>
      </Grid>
    </Grid>
  )
}
