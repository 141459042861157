import { TextField, Box, Card, Button, IconButton, styled } from '@mui/material'
import { FieldArray, Formik, FormikProps } from 'formik'
import { Add as AddIcon, Send as SendIcon, Close as CloseIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import { LoadableButton } from '../../../components/Form'
import { requestPrizeSchema } from './validation'

interface IProps {
  onSubmit: (values: IRequestPrizeFormValues) => void
  loading?: boolean
}

export interface IRequestPrizeItem {
  name: string
  link: string
  comment: string
}

export interface IRequestPrizeFormValues {
  items: IRequestPrizeItem[]
}

export const RequestPrizeForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const emptyItem = {
    name: '',
    link: '',
    comment: '',
  }
  const initialValues: IRequestPrizeFormValues = {
    items: [emptyItem],
  }

  const handleFormSubmit = async (values: IRequestPrizeFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={requestPrizeSchema}
      validateOnChange={false}
      onSubmit={handleFormSubmit}
    >
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IRequestPrizeFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <FieldArray name='items'>
              {({ remove, push }) => (
                <div>
                  {values.items.length > 0 &&
                    values.items.map((item, index) => (
                      <Card sx={{ mb: 2, position: 'relative' }} key={`product-${index}`}>
                        <Box p={2}>
                          <TextField
                            label='Наименование'
                            name={`items.${index}.name`}
                            value={values.items[index].name}
                            onChange={handleChange}
                            error={!!errors.items && !!errors.items[index]}
                            size='small'
                            required
                          />
                          <TextField
                            label='Ссылка'
                            name={`items.${index}.link`}
                            value={values.items[index].link}
                            onChange={handleChange}
                            error={!!errors.items && !!errors.items[index]}
                            size='small'
                            required
                          />
                          <TextField
                            label='Комментарий'
                            name={`items.${index}.comment`}
                            value={values.items[index].comment}
                            onChange={handleChange}
                            // error={!!errors.items && !!errors.items[index]}
                            multiline
                            rows={2}
                            size='small'
                          />
                        </Box>
                        {index > 0 && (
                          <CloseButton onClick={() => remove(index)}>
                            <CloseIcon />
                          </CloseButton>
                        )}
                      </Card>
                    ))}
                  {values.items.length < 5 && (
                    <Box mb={4} textAlign='right'>
                      <Button variant='outlined' size='small' startIcon={<AddIcon />} onClick={() => push(emptyItem)}>
                        Добавить еще
                      </Button>
                    </Box>
                  )}
                </div>
              )}
            </FieldArray>

            <Box mt={2} display='flex'>
              <Button variant='outlined' sx={{ mr: 1 }} component={Link} to='/prizes'>
                Отмена
              </Button>
              <LoadableButton
                loading={loading}
                color='primary'
                endIcon={<SendIcon />}
                variant='contained'
                type='submit'
              >
                Отправить
              </LoadableButton>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
}

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(0),
  top: theme.spacing(0),
}))
