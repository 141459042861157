import { useEffect, useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useApolloClient, useMutation } from '@apollo/client'

import { Loader } from '../../components/UI'
import { EMAIL_CONFIRMATION, PROFILE } from '../../graphql/queries/auth'
import { useStores } from '../../stores/hooks'
import { IProfilePayload } from '../../graphql/types/auth'

function EmailConfirmation() {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string>()
  const [confirmEmail] = useMutation(EMAIL_CONFIRMATION)

  const history = useHistory()
  const client = useApolloClient()
  const { authStore } = useStores()
  const qs = new URLSearchParams(useLocation().search)
  const confirmationString = qs.get('confirmation')

  useEffect(
    () => {
      if (!confirmationString) {
        setError('Неверный токен')
        setLoading(false)
        return
      }

      ;(async () => {
        try {
          const { data } = await confirmEmail({
            variables: { confirmation: confirmationString },
          })

          if (data.emailConfirmation) {
            authStore.setToken(data.emailConfirmation.jwt)

            const p = await client.query<IProfilePayload>({
              query: PROFILE,
              fetchPolicy: 'network-only',
            })

            authStore.updateUserWithType(p.data.profile.user, p.data.profile.type)
            authStore.setSalesCount(p.data.userSalesCount)
            history.push('/auth/register')
          }
        } catch (err) {
          setError('Неверный токен')
          setLoading(false)
          console.log(err)
        }
      })()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <Box>
      <Box pt={'220px'} mb={3}>
        <Typography variant='h4' mb={4}>
          Подтверждение аккаунта
        </Typography>
        {loading && <Loader />}
        {!!error && (
          <Alert severity='error' variant='outlined'>
            {error}
          </Alert>
        )}
      </Box>
    </Box>
  )
}

export default EmailConfirmation
