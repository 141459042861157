import { grey, blueGrey } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      // light: '#81A0F2',
      main: '#ffa500',
      // dark: '#2240B6',
    },
    secondary: {
      main: '#ff6e40',
    },
    success: {
      main: '#2db928',
      contrastText: '#fff',
    },
    info: {
      main: '#00a5dc',
    },
    background: {
      default: '#ffffff',
    },
    text: {
      secondary: blueGrey[400],
    },
  },
  typography: {
    // fontFamily: 'Roboto, sans-serif',
    button: {
      textTransform: 'none',
    },
    subtitle1: {
      fontWeight: 500,
    },
    h1: {
      fontWeight: 600,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          height: 48,
        },
        sizeSmall: {
          height: 36,
        },
        contained: {
          paddingLeft: 24,
          paddingRight: 24,
        },
        outlined: {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #f1f1ff',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: grey[50],
          '& fieldset': {
            borderColor: grey[300],
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // backgroundColor: '#f0f0f0',
        },
      },
    },
  },
})
