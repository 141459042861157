import * as yup from 'yup'

export const requestPrizeSchema = yup.object().shape({
  items: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        link: yup.string().required(),
        comment: yup.string(),
      })
    )
    .min(1)
    .required(),
})
