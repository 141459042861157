import { PrizeStatus } from '../graphql/types/prize'

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'

export const MONTHS_NAMES = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
]

export const PRIZE_STATUS_LABELS = {
  [PrizeStatus.IN_PROGRESS]: 'На рассмотрении',
  [PrizeStatus.APPROVED]: 'Одобрен',
  [PrizeStatus.CANCELED]: 'Нет в наличии',
}
