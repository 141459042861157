import { FormControlLabel, FormGroup, Grid, Switch } from '@mui/material'
import { useFormikContext } from 'formik'
import { IWarrantyCaseFormValues } from './useWarrantyCaseFormik'

export const SwitchesForm = () => {
  const { getFieldProps } = useFormikContext<IWarrantyCaseFormValues>()

  return (
    <Grid container spacing={3} mb={5}>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormControlLabel {...getFieldProps('damageHumanHealth')} control={<Switch />} label='Ущерб здоровью людей' />
          <FormControlLabel {...getFieldProps('carDamage')} control={<Switch />} label='Ущерб автомобиля' />
          <FormControlLabel {...getFieldProps('policeInvolvement')} control={<Switch />} label='Участие полиции' />
        </FormGroup>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormGroup>
          <FormControlLabel {...getFieldProps('damageProperty')} control={<Switch />} label='Ущерб матер. ценностей' />
          <FormControlLabel
            {...getFieldProps('damageOtherVehicles')}
            control={<Switch />}
            label='Ущерб других автомобилей'
          />
          <FormControlLabel
            {...getFieldProps('involvementInsurance')}
            control={<Switch />}
            label='Участие страховой компании'
          />
        </FormGroup>
      </Grid>
    </Grid>
  )
}
