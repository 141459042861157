import { gql } from '@apollo/client'

import { PRODUCT_FRAGMENT } from './products'
import { SELLER_FRAGMENT } from './sellers'

export const SALE_FRAGMENT = gql`
  fragment SaleFragment on Sale {
    id
    status
    points
    sent_date
    created_at
    updated_at
    sales_products {
      id
      product {
        ...ProductFragment
      }
      kit
    }
    images {
      id
      url
    }
    seller {
      ...SellerFragment
    }
  }
  ${PRODUCT_FRAGMENT}
  ${SELLER_FRAGMENT}
`

export const GET_SALES = gql`
  query GetUserSalesQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    userSales(sort: $sort, limit: $limit, start: $start, where: $where) {
      sales {
        ...SaleFragment
      }
      total
    }
  }
  ${SALE_FRAGMENT}
`

export const GET_SALE = gql`
  query GetUserSaleQuery($id: ID!) {
    userSale(id: $id) {
      ...SaleFragment
    }
  }
  ${SALE_FRAGMENT}
`

export const UPSERT_SALE = gql`
  mutation UpsertSaleMutation($input: UpsertSaleInput!) {
    upsertSaleBySeller(input: $input) {
      ...SaleFragment
    }
  }
  ${SALE_FRAGMENT}
`

export const DELETE_SALE = gql`
  mutation DeleteSaleMutation($id: ID!) {
    deleteSaleBySeller(id: $id)
  }
`
