import { Box, Pagination, Grid, Stack, Button } from '@mui/material'
import { Link } from 'react-router-dom'

import { PageHeader } from '../../components/Layout'
import { LeaderboardItem, SellerLeaderCard } from '../../components/Leaderboard'
import { EmptyListMessage, Loader } from '../../components/UI'
import { useLeaders, useSellersRating } from '../../graphql/hooks/rating'

function LeadersSellers() {
  const { leaders } = useLeaders()
  const { sellers, total, page, setPage, loading } = useSellersRating(9)
  const paginationCount = Math.ceil(total / 9)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <PageHeader title='Рейтинг продавцов' />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <Box mb={3}>
              <SellerLeaderCard leaders={leaders} />
            </Box>
            <Box>
              <Button variant='outlined' color='success' fullWidth component={Link} to='/leaders/sales-points'>
                Рейтинг торговых точек
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Box mb={4}>
              {loading && <Loader />}
              {sellers.length > 0 && !loading ? (
                <Box>
                  {sellers.map((item) => (
                    <Box key={`leaderboard-item-${item.id}`} mb={1}>
                      <LeaderboardItem
                        name={item.name}
                        description={item.dealer.name || ''}
                        avatar={item.avatar?.url}
                        points={item.points}
                      />
                    </Box>
                  ))}
                </Box>
              ) : (
                <EmptyListMessage message='Торговых точек еще нет' />
              )}
            </Box>
            {paginationCount > 1 && (
              <Stack alignItems='center'>
                <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
              </Stack>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default LeadersSellers
