import { Dialog, DialogTitle, DialogContent, TextField, Box, Button, Alert, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'

import { LoadableButton } from '../../../components/Form'
import { useSendPointsToSeller } from '../../../graphql/hooks/dealers'
import { ISeller } from '../../../graphql/types/sellers'
import { useStores } from '../../../stores/hooks'
import { sendPointsSchema } from './validation'

interface IProps {
  seller: ISeller
  open: boolean
  onClose: () => void
}

export interface ISendPointsFormValues {
  points: string
}

export const SendPointsModal: React.FC<IProps> = observer(({ seller, open, onClose }) => {
  const { authStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()
  const [sendPoints, { loading, error }] = useSendPointsToSeller()
  const dealerPoints = authStore.userType?.points || 0

  const initialValues: ISendPointsFormValues = {
    points: '',
  }

  const handleFormSubmit = async (values: ISendPointsFormValues) => {
    try {
      await sendPoints({
        variables: { sellerId: seller.id, points: parseInt(values.points, 10) },
      })

      enqueueSnackbar('Баллы успешно переведены', { variant: 'success' })

      onClose()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Dialog maxWidth='sm' fullWidth onClose={onClose} open={open}>
      <DialogTitle>Перевести баллы</DialogTitle>
      <DialogContent>
        <Box mb={1}>
          <Typography>Вы переводите баллы продавцу</Typography>
          <Typography sx={{ fontWeight: 600, mb: 1 }}>{seller.name}</Typography>
          <Alert severity='warning'>Можно перевести не более {dealerPoints} баллов</Alert>
        </Box>
        {!!error && (
          <Box mb={1}>
            <Alert severity='error'>{'Ошибка при отправке'}</Alert>
          </Box>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={sendPointsSchema(dealerPoints)}
          onSubmit={handleFormSubmit}
        >
          {({ values, errors, handleChange, handleSubmit }: FormikProps<ISendPointsFormValues>) => (
            <form onSubmit={handleSubmit}>
              <TextField
                label='Баллы'
                name='points'
                value={values.points}
                onChange={handleChange}
                error={!!errors.points}
                required
              />
              <Box mt={2} display='flex' justifyContent='space-between'>
                <Button onClick={onClose}>Закрыть</Button>
                <LoadableButton loading={loading} color='primary' variant='contained' type='submit'>
                  Отправить баллы
                </LoadableButton>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
})
