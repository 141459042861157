import React from 'react'
import { Autocomplete, Button, Card, CardContent, CardHeader, Grid, Stack, TextField, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { useExploitations } from '../../../graphql/hooks/exploitations'
import { carSchema } from './validation'

interface IProps {
  onNext: (values: ICarFormValues) => void
  onBack?: VoidFunction
}

export interface ICarFormValues {
  carBrand: string
  carModel: string
  carYear: string
  exploitation: number
}

export const CarForm: React.FC<IProps> = ({ onNext, onBack }) => {
  const { exploitations } = useExploitations()

  const initialValues: ICarFormValues = {
    carBrand: '',
    carModel: '',
    carYear: '',
    exploitation: 0,
  }

  const handleFormSubmit = (values: ICarFormValues) => {
    onNext(values)
  }

  return (
    <Card>
      <CardHeader title={<Typography variant='h6'>Данные автомобиля</Typography>} />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={carSchema} onSubmit={handleFormSubmit}>
          {({ errors, getFieldProps, setFieldValue, handleSubmit }: FormikProps<ICarFormValues>) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Марка автомобиля'
                    margin='none'
                    {...getFieldProps('carBrand')}
                    error={!!errors.carBrand}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label='Модель' margin='none' {...getFieldProps('carModel')} error={!!errors.carModel} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label='Год выпуска автомобиля'
                    margin='none'
                    {...getFieldProps('carYear')}
                    error={!!errors.carYear}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    options={exploitations}
                    getOptionLabel={(e) => e.title}
                    onChange={(e, v) => setFieldValue('exploitation', v?.id)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label='Условия эксплуатации' margin='none' error={!!errors.exploitation} />
                    )}
                  />
                </Grid>
              </Grid>

              <Stack pt={3} direction='row' spacing={2} justifyContent='flex-end'>
                <Button size='small' onClick={onBack}>
                  Назад
                </Button>
                <Button variant='contained' size='small' type='submit'>
                  Далее
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
