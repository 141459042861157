import { Box, Grid, IconButton, Stack, Typography } from '@mui/material'
import { ChevronRight as MoreIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import { NewsCard } from '../../../components/News'
import { Loader } from '../../../components/UI'
import { useArticles } from '../../../graphql/hooks/articles'

export const LastNews = () => {
  const { articles, loading } = useArticles(3)

  if (articles.length === 0) {
    return null
  }

  return (
    <Box pt={3}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Typography variant='h4' mb={3}>
          Новости
        </Typography>
        <IconButton size='small' component={Link} to='/news'>
          <MoreIcon />
        </IconButton>
      </Stack>
      {loading && <Loader />}
      {articles.length > 0 && !loading && (
        <Grid container spacing={2}>
          {articles.map((item) => (
            <Grid key={`news-item-${item.id}`} item xs={12} sm={4}>
              <NewsCard item={item} />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  )
}
