import { useState } from 'react'
import { Box, Button, IconButton, Pagination, Stack } from '@mui/material'
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { PageHeader } from '../../components/Layout'
import { EmptyListMessage, Loader } from '../../components/UI'
import { SearchWarrantyDialog, WarrantyListItem } from '../../sections/warranty'
import { useWarranties } from '../../graphql/hooks/warranty'
import { useStores } from '../../stores/hooks'

function Warranties() {
  const [searchDialogVisible, setSearchDialogVisible] = useState(false)
  const { authStore } = useStores()
  const { warranties, total, page, setPage, loading } = useWarranties(12)
  const paginationCount = Math.ceil(total / 12)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const toggleSearchDialog = () => {
    setSearchDialogVisible((prev) => !prev)
  }

  return (
    <Box>
      <PageHeader
        title='Гарантии'
        rightContent={
          <>
            <IconButton onClick={toggleSearchDialog}>
              <SearchIcon />
            </IconButton>
            {authStore.isSeller && (
              <Button
                component={Link}
                to='/warranties/create'
                variant='contained'
                size='small'
                startIcon={<AddIcon />}
                sx={{ ml: 1 }}
              >
                Создать
              </Button>
            )}
          </>
        }
      />
      <Box mb={4}>
        {loading && <Loader />}
        {warranties.length > 0 && !loading ? (
          <>
            {warranties.map((warranty, index) => (
              <Box key={`warranty-${warranty.id}`} mb={2}>
                <WarrantyListItem warranty={warranty} />
              </Box>
            ))}
          </>
        ) : (
          <EmptyListMessage message='Гарантии еще нет' />
        )}
        {paginationCount > 1 && (
          <Stack alignItems='center'>
            <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
          </Stack>
        )}
      </Box>
      {searchDialogVisible && <SearchWarrantyDialog open onClose={toggleSearchDialog} />}
    </Box>
  )
}

export default Warranties
