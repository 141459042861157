import * as yup from 'yup'

export const sellerSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
})

export const sendPointsSchema = (max: number) =>
  yup.object().shape({
    points: yup.number().integer().min(1).max(max).required(),
  })
