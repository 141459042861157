import React, { useState } from 'react'
import { Box, ImageList, ImageListItem } from '@mui/material'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

import { IImage } from '../../../graphql/types/image'
import { withApiUrl } from '../../../utils/helpers'

interface IProps {
  images: IImage[]
}

export const SaleImages: React.FC<IProps> = ({ images }) => {
  const [imgIndex, setImgIndex] = useState(0)
  const [lightboxOpen, setLightboxOpen] = useState(false)

  const imagesUrls = images.map((im) => withApiUrl(im.url)!)

  const onClickImage = (index: number) => {
    setImgIndex(index)
    setLightboxOpen(true)
  }

  return (
    <Box>
      <ImageList variant='masonry' cols={3} gap={8}>
        {images.map((item, i) => (
          <ImageListItem key={`image-${item.id}`}>
            <img
              src={withApiUrl(item.url)}
              srcSet={withApiUrl(item.url)}
              alt=''
              loading='lazy'
              onClick={() => onClickImage(i)}
            />
          </ImageListItem>
        ))}
      </ImageList>
      {lightboxOpen && (
        <Lightbox
          mainSrc={imagesUrls[imgIndex]}
          nextSrc={imagesUrls[(imgIndex + 1) % imagesUrls.length]}
          prevSrc={imagesUrls[(imgIndex + imagesUrls.length - 1) % imagesUrls.length]}
          onCloseRequest={() => setLightboxOpen(false)}
          onMovePrevRequest={() => setImgIndex((imgIndex + imagesUrls.length - 1) % imagesUrls.length)}
          onMoveNextRequest={() => setImgIndex((imgIndex + 1) % imagesUrls.length)}
          reactModalStyle={{ overlay: { zIndex: 1110 } }}
        />
      )}
    </Box>
  )
}
