import { useApolloClient } from '@apollo/client'

import { IWarranty } from '../../types/warranty'
import { WARRANTY_BY_CODE } from '../../queries/warranty'

export const useFindWarrantyByCode = () => {
  const client = useApolloClient()

  return async (code: string) => {
    const { data } = await client.query<{ warrantyByCode: IWarranty | null }, { code: string }>({
      query: WARRANTY_BY_CODE,
      variables: { code },
      fetchPolicy: 'network-only',
    })

    return data.warrantyByCode
  }
}
