import React from 'react'
import { CircularProgress, styled } from '@mui/material'
import { grey } from '@mui/material/colors'

interface IProps {
  size?: number
}

export const Loader: React.FC<IProps> = ({ size = 30 }) => {
  return (
    <Root>
      <CircularProgress color='inherit' size={size} thickness={4} />
    </Root>
  )
}

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  justifyContent: 'center',
  color: grey[600],
}))
