import React from 'react'
import { Box, CircularProgress, Fab, Typography } from '@mui/material'
import { ThumbUp as LikeIcon } from '@mui/icons-material'
import { observer } from 'mobx-react-lite'
import { useStores } from '../../../stores/hooks'

interface IProps {
  likes: number
  didILike?: boolean
  onLike: () => void
  loading?: boolean
}

export const NewsLike: React.FC<IProps> = observer(({ likes, didILike, onLike, loading }) => {
  const { authStore } = useStores()

  return (
    <Box display='flex' alignItems='center'>
      {!didILike && authStore.isSeller && (
        <Fab color='primary' sx={{ mr: 2 }} disabled={loading} onClick={onLike}>
          {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : <LikeIcon />}
        </Fab>
      )}
      <Box display='flex' alignItems='center'>
        <LikeIcon sx={{ color: 'grey.500', mr: 1, fontSize: 18 }} />
        <Typography color='text.secondary'>{likes}</Typography>
      </Box>
    </Box>
  )
})
