import * as yup from 'yup'

export const customerSchema = yup.object().shape({
  fullName: yup.string().required(),
  // email: yup.string().email().required(),
  // phone: yup.string().required(),
  // birthday: yup
  //   .string()
  //   .test('birthday', 'Invalid date format', (value) => moment(value, 'DD.MM.YYYY', true).isValid())
  //   .required(),
  city: yup.number().integer().moreThan(0).required(),
})

export const carSchema = yup.object().shape({
  carBrand: yup.string().required(),
  carModel: yup.string().required(),
  carYear: yup.string().required(),
  exploitation: yup.number().integer().moreThan(0).required(),
})
