export interface IPrize {
  id: number
  points: number | null
  status: PrizeStatus
  created_at: string
  items: IPrizeItemInput[]
}

export enum PrizeStatus {
  IN_PROGRESS = 'in_progress',
  APPROVED = 'approved',
  CANCELED = 'canceled',
}

export interface IPrizeItemInput {
  name: string
  link: string
  comment?: string
}

export interface IGetPrizesVariables {
  sort?: string
  limit?: number
  start?: number
}

export interface IGetPrizesResponse {
  prizes: IPrize[]
  total: number
}

export interface IRequestPrizeInput {
  items: IPrizeItemInput[]
}
