import { useQuery } from '@apollo/client'

import { GET_WARRANTY } from '../../queries/warranty'
import { IWarranty } from '../../types/warranty'

export const useWarranty = (id: number) => {
  const { data, ...q } = useQuery<{ warranty: IWarranty }, { id: number }>(GET_WARRANTY, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const warranty = data?.warranty

  return {
    warranty,
    ...q,
  }
}
