import { gql } from '@apollo/client'

import { DEALER_FRAGMENT } from './dealers'
import { SELLER_FRAGMENT } from './sellers'

export const USER_FRAGMENT = gql`
  fragment UserFragment on UsersPermissionsMe {
    id
    email
    role {
      id
      name
      type
    }
  }
`

export const LOGIN = gql`
  mutation LoginMutation($input: UsersPermissionsLoginInput!) {
    login(input: $input) {
      jwt
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`

export const PROFILE = gql`
  query UserProfileQuery {
    profile {
      user {
        ...UserFragment
      }
      type {
        ... on Dealer {
          ...DealerFragment
        }
        ... on Sellers {
          ...SellerFragment
        }
      }
    }
    userSalesCount
  }
  ${USER_FRAGMENT}
  ${DEALER_FRAGMENT}
  ${SELLER_FRAGMENT}
`

export const EMAIL_CONFIRMATION = gql`
  mutation EmailConfirmationMutation($confirmation: String!) {
    emailConfirmation(confirmation: $confirmation) {
      jwt
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`

export const UPDATE_PROFILE = gql`
  mutation UpdateProfileMutation($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      user {
        ...UserFragment
      }
      type {
        ... on Dealer {
          ...DealerFragment
        }
        ... on Sellers {
          ...SellerFragment
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${DEALER_FRAGMENT}
  ${SELLER_FRAGMENT}
`

export const UPDATE_AVATAR = gql`
  mutation UpdateAvatar($refId: ID!, $ref: String!, $field: String!, $file: Upload!) {
    upload(refId: $refId, ref: $ref, field: $field, file: $file) {
      id
      url
    }
  }
`

export const FORGOT_PASSWORD = gql`
  mutation ForgotPasswordMutation($email: String!) {
    forgotPassword(email: $email) {
      ok
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation ResetPasswordMutation($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      jwt
      user {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`
