import React, { useState } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import { EmptyListMessage, Loader } from '../../components/UI'
import { useStores } from '../../stores/hooks'
import { IWarranty } from '../../graphql/types/warranty'
import { WarrantyCaseDialog } from './WarrantyCaseDialog'
import { WarrantyCaseListItem } from './WarrantyCaseListItem'
import { useWarrantyCases } from '../../graphql/hooks/warranty-case'
import { IWarrantyCase } from '../../graphql/types/warranty-case'

interface IProps {
  warranty: IWarranty
}

export const WarrantyCasesList: React.FC<IProps> = ({ warranty }) => {
  const { authStore } = useStores()
  const [openedCase, setOpenedCase] = useState<IWarrantyCase>()
  const [dialogVisible, setDialogVisible] = useState(false)
  const { warrantyCases, loading } = useWarrantyCases(warranty)

  const handleCreate = () => {
    setOpenedCase(undefined)
    setDialogVisible(true)
  }

  const handleOpen = (wc: IWarrantyCase) => {
    setOpenedCase(wc)
    setDialogVisible(true)
  }

  const handleClose = () => {
    setOpenedCase(undefined)
    setDialogVisible(false)
  }

  const canCreate = authStore.isSeller && warrantyCases.length < 5 && !!warranty.customer && !!warranty.product

  return (
    <Box>
      <Stack mb={2} direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant='subtitle1'>Гарантийные случаи</Typography>
        {canCreate && (
          <Button variant='outlined' size='small' startIcon={<AddIcon />} onClick={handleCreate}>
            Создать
          </Button>
        )}
      </Stack>
      {loading ? (
        <Loader />
      ) : (
        <>
          {warrantyCases.length > 0 ? (
            <>
              {warrantyCases.map((w) => (
                <Box key={`warranty-case-${w.id}`} mb={2}>
                  <WarrantyCaseListItem warrantyCase={w} onClick={handleOpen} />
                </Box>
              ))}
            </>
          ) : (
            <EmptyListMessage message='Гарантийных случаев еще нет' />
          )}
        </>
      )}

      {dialogVisible && <WarrantyCaseDialog warrantyCase={openedCase} warranty={warranty} open onClose={handleClose} />}
    </Box>
  )
}
