import React from 'react'
import { Chip, ChipProps } from '@mui/material'
import { WarrantyCaseStatus } from '../../graphql/types/warranty-case'

interface IProps extends ChipProps {
  status: WarrantyCaseStatus
}

export const WarrantyCaseStatusChip: React.FC<IProps> = ({ status, ...props }) => {
  switch (status) {
    case WarrantyCaseStatus.PENDING:
      return <Chip label={'На рассмотрении'} color={'warning'} {...props} />
    case WarrantyCaseStatus.SUCCESS:
      return <Chip label={'Одобрен'} color={'success'} {...props} />
    case WarrantyCaseStatus.REJECTED:
      return <Chip label={'Отказан'} color={'error'} {...props} />
    default:
      return null
  }
}
