import { useQuery } from '@apollo/client'

import { LEADERS_OF_LAST_MONTH } from '../../queries/leaders'
import { ILeadersOfLastMonthPayload } from '../../types/leaders'

export const useLeaders = () => {
  const { data, ...q } = useQuery<{ leaders: ILeadersOfLastMonthPayload[] }>(LEADERS_OF_LAST_MONTH)

  const [leaders] = data?.leaders || []

  return {
    leaders,
    ...q,
  }
}
