import React from 'react'
import { Avatar, Box, Card, styled, Typography } from '@mui/material'

import { medal, starsBg } from '../../assets/images'
import { ILeadersOfLastMonthPayload } from '../../graphql/types/leaders'
import moment from 'moment'
import { withApiUrl } from '../../utils/helpers'
import { MONTHS_NAMES } from '../../core/constants'

interface IProps {
  leaders?: ILeadersOfLastMonthPayload
}

export const SalesPointLeaderCard: React.FC<IProps> = ({ leaders }) => {
  return (
    <Card
      sx={{
        padding: 2,
        bgcolor: 'success.main',
        backgroundImage: `url(${starsBg})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <Typography variant='subtitle2'>Лучшая точка месяца</Typography>
      <Box mb={2}>
        {leaders && (
          <Typography variant='h6' sx={{ fontWeight: 700, lineHeight: 1 }}>
            {MONTHS_NAMES[moment(leaders.month).get('month')]}
          </Typography>
        )}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <MedalIcon src={medal} alt='' />
        <Avatar
          sx={{ width: 120, height: 120, mb: 1, border: '5px solid #fff' }}
          alt='Remy Sharp'
          src={withApiUrl(leaders?.dealer.avatar?.url)}
        />
      </Box>
      {leaders && <Typography variant='h6'>{leaders.dealer.name}</Typography>}
    </Card>
  )
}

export const SellerLeaderCard: React.FC<IProps> = ({ leaders }) => {
  return (
    <Card
      sx={{
        padding: 2,
        bgcolor: 'info.main',
        backgroundImage: `url(${starsBg})`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <Typography variant='subtitle2'>Лучший продавец месяца</Typography>
      <Box mb={2}>
        {leaders && (
          <Typography variant='h6' sx={{ fontWeight: 700, lineHeight: 1 }}>
            {MONTHS_NAMES[moment(leaders.month).get('month')]}
          </Typography>
        )}
      </Box>
      <Box sx={{ position: 'relative' }}>
        <MedalIcon src={medal} alt='' />
        <Avatar
          sx={{ width: 120, height: 120, mb: 1, border: '5px solid #fff' }}
          alt='Remy Sharp'
          src={withApiUrl(leaders?.seller.avatar?.url)}
        />
      </Box>
      {leaders && <Typography variant='h6'>{leaders.seller.name}</Typography>}
    </Card>
  )
}

const MedalIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: -5,
  right: -5,
  color: '#fff',
  zIndex: 11,
  width: 50,
  height: 50,
}))
