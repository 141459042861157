import { useState } from 'react'
import { useMutation } from '@apollo/client'

import { useStores } from '../../../stores/hooks'
import { PROFILE, UPDATE_AVATAR, UPDATE_PROFILE } from '../../queries/auth'
import { IUpdateAvatarInput, IUpdateProfileInput, IUserProfileData, UserRoleType } from '../../types/auth'
import { IImage } from '../../types/image'

export const useUpdateProfile = () => {
  const { authStore } = useStores()
  const [loading, setLoading] = useState<boolean>(false)
  const [updateProfileMutation] = useMutation<{ updateProfile: IUserProfileData }>(UPDATE_PROFILE)
  const [updateAvatarMutation] = useMutation<{ upload: IImage }>(UPDATE_AVATAR)

  const updateProfile = async (input: IUpdateProfileInput) => {
    try {
      setLoading(true)
      const { data } = await updateProfileMutation({
        variables: { input },
        refetchQueries: [{ query: PROFILE }],
      })

      if (data?.updateProfile) {
        authStore.updateUserWithType(data.updateProfile.user, data.updateProfile.type)
      }
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }

  const updateAvatar = async (file: File) => {
    if (!authStore.user || !authStore.userType) {
      return
    }

    try {
      setLoading(true)
      const input: IUpdateAvatarInput = {
        refId: authStore.userType.id,
        ref: 'dealer',
        field: 'avatar',
        file,
      }

      if (authStore.user.role.type === UserRoleType.SELLER) {
        input.ref = 'sellers'
      }

      const { data } = await updateAvatarMutation({
        variables: { ...input },
        refetchQueries: [{ query: PROFILE }],
      })

      if (data?.upload) {
        authStore.setUserType({
          ...authStore.userType,
          avatar: data.upload,
        })
      }
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { updateProfile, updateAvatar, loading }
}
