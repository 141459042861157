import { IImage } from './image'
import { ISellerShort } from './sellers'

export enum WarrantyCaseStatus {
  PENDING = 0,
  SUCCESS = 1,
  REJECTED = 2,
}

export interface IWarrantyCase {
  id: number
  created_at: string
  updated_at: string
  tireType: number
  tireDesignation: string
  dotCode: string
  treadDepth: string[]
  avgPressure: string[]
  damage: number
  type: number
  cost: string
  mileage: string
  vendorCode: string
  damageHumanHealth: boolean
  carDamage: boolean
  policeInvolvement: boolean
  damageProperty: boolean
  damageOtherVehicles: boolean
  involvementInsurance: boolean
  wholeTireImage: IImage
  sizeImage: IImage
  dotCodeImage: IImage
  damageImage: IImage
  couponCheckImage: IImage
  optImage1: IImage | null
  optImage2: IImage | null
  status: WarrantyCaseStatus
  seller: ISellerShort
  decision: string | null
  admin_user: IAdminUser | null
}

export interface IAdminUser {
  id: number
  firstname: string
  lastname: string
}

export interface IWarrantyCaseInput {
  tireType: number
  tireDesignation: string
  dotCode: string
  treadDepth: object
  avgPressure: object
  damage: number
  type: number
  cost: string
  mileage: string
  vendorCode: string
  damageHumanHealth: boolean
  carDamage: boolean
  policeInvolvement: boolean
  damageProperty: boolean
  damageOtherVehicles: boolean
  involvementInsurance: boolean
  wholeTireImage: number
  sizeImage: number
  dotCodeImage: number
  damageImage: number
  couponCheckImage: number
  optImage1?: number
  optImage2?: number
  warranty: number
}

export interface ICreateWarrantyCaseInput {
  data: IWarrantyCaseInput
}

export interface ICreateWarrantyCasePayload {
  warrantyCase: IWarrantyCase
}

export interface ICreateWarrantyCaseData {
  createWarrantyCase: ICreateWarrantyCasePayload
}

export interface ICreateWarrantyCaseVariables {
  input: ICreateWarrantyCaseInput
}

export interface IWarrantyCasesData {
  warrantyCases: IWarrantyCase[]
}

export interface IWarrantyCasesVariables {
  sort?: string
  limit?: number
  start?: number
  where?: object
}
