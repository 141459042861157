import { useEffect } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'

import { NOTIFICATIONS } from '../../graphql/queries/notifications'
import { INotification } from '../../graphql/types/notifications'
import { useStores } from '../../stores/hooks'

let initialLoad = true

export const useNotifications = () => {
  const client = useApolloClient()
  const { notificationsStore } = useStores()

  const { data, ...q } = useQuery<{ userNotifications: INotification[] }>(NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    pollInterval: __DEV__ ? undefined : 30000,
  })

  const notifications = data?.userNotifications || []

  useEffect(
    () => {
      const subscription = client
        .watchQuery<{ userNotifications: INotification[] }>({
          query: NOTIFICATIONS,
        })
        .subscribe(({ data }) => {
          const newList = data.userNotifications

          if (!initialLoad && newList.length > 0 && newList[0].id !== notificationsStore.lastNotificationId) {
            notificationsStore.setHasNew(true)
            notificationsStore.setLastNotificationId(newList[0].id)
          }

          if (initialLoad && newList.length > 0) {
            notificationsStore.setLastNotificationId(newList[0].id)
            initialLoad = false
          }
        })

      return () => {
        subscription.unsubscribe()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return {
    notifications,
    ...q,
  }
}
