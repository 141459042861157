import { Avatar, Box, Card, Typography } from '@mui/material'
import React from 'react'
import { withApiUrl } from '../../utils/helpers'

interface IProps {
  name: string
  description: string
  avatar?: string
  points: number
}

export const LeaderboardItem: React.FC<IProps> = ({ name, description, avatar, points }) => {
  return (
    <Card>
      <Box sx={{ padding: 1.5, display: 'flex', alignItems: 'center' }}>
        <Avatar alt={name} src={withApiUrl(avatar)} />
        <Box flexGrow={1} mx={2} sx={{ lineHeight: 1 }}>
          <Typography>{name}</Typography>
          <Typography variant='caption' color='text.secondary'>
            {description}
          </Typography>
        </Box>
        <Typography color='GrayText'>{`${points}`}</Typography>
      </Box>
    </Card>
  )
}
