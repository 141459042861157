import { Box, Container } from '@mui/material'
import { observer } from 'mobx-react-lite'
import { useHistory } from 'react-router'

import { PageHeader } from '../../components/Layout'
import { EditableAvatar, Loader } from '../../components/UI'
import { useProfile, useUpdateProfile } from '../../graphql/hooks/auth'
import { IUpdateProfileInput } from '../../graphql/types/auth'
import { useStores } from '../../stores/hooks'
import { IProfileFormValues, ProfileForm } from './components'

function Profile() {
  const { authStore } = useStores()
  const history = useHistory()
  const { profile, loading } = useProfile()
  const { updateProfile, updateAvatar, loading: updateLoading } = useUpdateProfile()

  if (loading) {
    return <Loader />
  }

  if (!profile) {
    return null
  }

  const handleSubmit = async (values: IProfileFormValues) => {
    try {
      await updateProfile(values as IUpdateProfileInput)
      history.push('/')
    } catch (err) {
      console.log(err)
    }
  }

  const handleSelectAvatar = async (file: File) => {
    try {
      await updateAvatar(file)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader title='Профиль' />
      <Container maxWidth='xs'>
        <Box display='flex' justifyContent='center' mb={3}>
          <EditableAvatar
            src={
              authStore.userType?.avatar?.url
                ? `${process.env.REACT_APP_API_HOST}${authStore.userType?.avatar?.url}`
                : undefined
            }
            loading={updateLoading}
            onSelectImage={handleSelectAvatar}
          />
        </Box>
        <ProfileForm profile={profile} loading={updateLoading} onSubmit={handleSubmit} />
      </Container>
    </Box>
  )
}

export default observer(Profile)
