import { useQuery } from '@apollo/client'
import { Box } from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { PageHeader, PageNotFound } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { useDeleteSale, useUpsertSale } from '../../graphql/hooks/sales'
import { GET_SALE } from '../../graphql/queries/sales'
import { ISale, IUpsertSaleInput, SaleStatus } from '../../graphql/types/sales'
import { useConfirm } from '../../services/confirmation'
import { ISalesFormValues, SalesForm, SalesView } from './components'

function SalesDetail() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [upsertSale, { loading: upsertLoading }] = useUpsertSale()
  const confirm = useConfirm()
  const [deleteSale, { loading: deleteLoading }] = useDeleteSale()
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useQuery<{ userSale: ISale | null }, { id: number }>(GET_SALE, {
    variables: { id: parseInt(id, 10) },
  })

  if (loading) {
    return <Loader />
  }

  const sale = data?.userSale

  if (!sale) {
    return <PageNotFound />
  }

  const isSent = sale.status === SaleStatus.SENT

  const handleSubmit = async (values: ISalesFormValues) => {
    try {
      const input: IUpsertSaleInput = {
        id: sale.id,
        status: values.status,
        salesProducts: values.products.map((p) => ({
          id: p.id,
          productId: p.product!.id,
          kit: parseInt(p.kit, 10),
        })),
        images: [],
      }

      await upsertSale({ variables: { input } })

      const message = values.status === SaleStatus.DRAFT ? 'Данные сохранены' : 'Данные отправлены'
      enqueueSnackbar(message, { variant: 'success' })
      history.push('/sales')
    } catch (err) {
      console.log(err)
    }
  }

  const handleDelete = async () => {
    try {
      await confirm({ message: 'Вы действительно хотите удалить?' })
    } catch {
      return
    }

    try {
      await deleteSale({
        variables: { id: sale.id },
      })

      history.push('/sales')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader title='Продажа' back />
      <Box>
        {isSent ? (
          <SalesView sale={sale} />
        ) : (
          <SalesForm
            sale={sale}
            loading={upsertLoading || deleteLoading}
            onSubmit={handleSubmit}
            onDelete={handleDelete}
          />
        )}
      </Box>
    </Box>
  )
}

export default SalesDetail
