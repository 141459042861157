import { useApolloClient } from '@apollo/client'
import React from 'react'
import { Redirect } from 'react-router-dom'

import { useStores } from '../../stores/hooks'

const Logout: React.FC = () => {
  const { authStore } = useStores()
  const client = useApolloClient()

  React.useEffect(() => {
    client.clearStore()
    authStore.logout()
  }, [authStore, client])

  return <Redirect to='/auth/login' />
}

export default Logout
