import * as yup from 'yup'

export const testingFormSchema = (length: number) =>
  yup.object().shape({
    results: yup
      .array()
      .of(
        yup.object().shape({
          questionId: yup.number().required(),
          answerId: yup.number().required(),
        })
      )
      .length(length)
      .required(),
  })
