import React from 'react'
import { Box, Card, CardActionArea, Typography } from '@mui/material'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../core/constants'
import { IWarrantyCase } from '../../graphql/types/warranty-case'
import { WarrantyCaseStatusChip } from './WarrantyCaseStatusChip'

interface IProps {
  warrantyCase: IWarrantyCase
  onClick?: (wc: IWarrantyCase) => void
}

export const WarrantyCaseListItem: React.FC<IProps> = ({ warrantyCase, onClick }) => {
  return (
    <Card>
      <CardActionArea onClick={() => onClick?.(warrantyCase)}>
        <Box sx={{ px: 2, py: 1, display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1} sx={{ lineHeight: 1 }}>
            <Typography variant='subtitle1'>{`Заявка №${warrantyCase.id}`}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {moment(warrantyCase.created_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box textAlign='right'>
            <WarrantyCaseStatusChip status={warrantyCase.status} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
