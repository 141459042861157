import { useQuery } from '@apollo/client'

import { GET_ARTICLE } from '../../queries/articles'
import { IGetArticlePayload } from '../../types/articles'

export const useArticle = (id: number) => {
  const { data, ...q } = useQuery<IGetArticlePayload, { id: number }>(GET_ARTICLE, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const article = data?.article
  const likesCount = data?.articleLikesCount || 0
  const didILike = data?.didILikeArticle

  return {
    article,
    likesCount,
    didILike,
    ...q,
  }
}
