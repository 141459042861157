import { useEffect } from 'react'
import { useApolloClient } from '@apollo/client'

import { IProfilePayload } from '../../types/auth'
import { PROFILE } from '../../queries/auth'
import { useStores } from '../../../stores/hooks'

export const useWatchProfile = () => {
  const client = useApolloClient()
  const { authStore } = useStores()

  useEffect(
    () => {
      const subscription = client
        .watchQuery<IProfilePayload, {}>({
          query: PROFILE,
        })
        .subscribe(({ data }) => {
          authStore.updateUserWithType(data.profile.user, data.profile.type)
          authStore.setSalesCount(data.userSalesCount)
        })

      return () => {
        subscription.unsubscribe()
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
