import React from 'react'
import { Box, Grid, Pagination, Stack } from '@mui/material'

import { PageHeader } from '../../components/Layout'
import { NewsCard } from '../../components/News'
import { useArticles } from '../../graphql/hooks/articles'
import { EmptyListMessage, Loader } from '../../components/UI'

function News() {
  const { articles, total, page, setPage, loading } = useArticles(9)
  const paginationCount = Math.ceil(total / 9)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <PageHeader title='Новости' />
      <Box mb={4}>
        {loading && <Loader />}
        {articles.length > 0 && !loading ? (
          <Grid container spacing={2}>
            {articles.map((item) => (
              <Grid key={`news-item-${item.id}`} item xs={12} sm={6} md={4}>
                <Box mb={2}>
                  <NewsCard item={item} />
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <EmptyListMessage message='Новостей еще нет' />
        )}
      </Box>
      {paginationCount > 1 && (
        <Stack alignItems='center'>
          <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
        </Stack>
      )}
    </Box>
  )
}

export default News
