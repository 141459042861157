import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_ARTICLES } from '../../queries/articles'
import { IArticle, IGetArticlesVariables } from '../../types/articles'

export const useArticles = (limit: number = 9) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ articles: IArticle[]; articlesCount: number }, IGetArticlesVariables>(
    GET_ARTICLES,
    {
      variables: {
        limit,
        start: (page - 1) * limit,
        sort: 'created_at:DESC',
      },
    }
  )

  const articles = data?.articles || []
  const total = data?.articlesCount || 0

  return {
    articles,
    total,
    page,
    setPage,
    ...q,
  }
}
