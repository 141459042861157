import React from 'react'
import { Box, Card, CardContent, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useFormikContext } from 'formik'

import { ITestingQuestion } from '../../../../graphql/types/testings'
import { ITestingFormValues } from '.'

interface IProps {
  question: ITestingQuestion
}

export const TestingQuestion: React.FC<IProps> = ({ question }) => {
  const { values, setFieldValue } = useFormikContext<ITestingFormValues>()
  const answered = values.results.find((r) => r.questionId === question.id)

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue('results', [
      ...values.results.filter((r) => r.questionId !== question.id),
      { questionId: question.id, answerId: target.value },
    ])
  }

  return (
    <Card>
      <CardContent>
        <Box mb={2}>
          <Typography variant='h6'>{question.title}</Typography>
        </Box>
        <RadioGroup aria-label='answer' onChange={handleChange}>
          {question.answers.map((a) => (
            <Box key={`answer-${a.id}`}>
              <FormControlLabel
                value={a.id}
                control={<Radio />}
                checked={answered?.answerId === a.id}
                label={a.title}
              />
            </Box>
          ))}
        </RadioGroup>
      </CardContent>
    </Card>
  )
}
