import { useQuery } from '@apollo/client'

import { GET_EXPLOITATIONS } from '../../queries/exploitations'
import { IExploitation } from '../../types/exploitations'

export const useExploitations = () => {
  const { data, ...q } = useQuery<{ exploitations: IExploitation[] }, {}>(GET_EXPLOITATIONS)

  const exploitations = data?.exploitations || []

  return {
    exploitations,
    ...q,
  }
}
