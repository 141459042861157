import { Router, Switch, Route } from 'react-router-dom'

import { PrivateRoute } from './components/Routes'
import { history } from './core/history'
import AuthLayout from './layouts/AuthLayout'
import MainLayout from './layouts/MainLayout'
import Welcome from './views/Welcome/Welcome'
import RegisterWarranty from './views/RegisterWarranty/RegisterWarranty'

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/welcome' component={Welcome} />
        <Route path='/register-warranty' component={RegisterWarranty} />
        <Route path='/auth' component={AuthLayout} />
        <PrivateRoute path='/' component={MainLayout} />
      </Switch>
    </Router>
  )
}

export default App
