import React from 'react'
import { Box, Card, CardContent, Grid, Typography, styled } from '@mui/material'

import { ITesting, ITestingResult } from '../../../graphql/types/testings'

interface IProps {
  testing: ITesting
  result: ITestingResult
}

export const TestingResult: React.FC<IProps> = ({ testing, result }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <StatCard sx={{ justifyContent: 'center', bgcolor: result.is_passed ? 'success.main' : 'error.main' }}>
            <CardContent>
              {result.is_passed ? (
                <Typography variant='h6' color='white'>
                  Успешно
                </Typography>
              ) : (
                <Typography variant='h6' color='white'>
                  Не успешно
                </Typography>
              )}
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard>
            <CardContent>
              <Typography variant='caption' color='text.secondary'>{`Кол-во правильных`}</Typography>
              <Box display='flex' alignItems='center'>
                <Typography variant='h6' mr={0.5}>
                  {result.correct_count}
                </Typography>
                <Typography variant='caption' color='text.secondary'>{`из ${testing.questions.length}`}</Typography>
              </Box>
            </CardContent>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard>
            <CardContent>
              <Typography variant='caption' color='text.secondary'>{`Начислено`}</Typography>
              <Typography variant='h6'>{result.points}</Typography>
            </CardContent>
          </StatCard>
        </Grid>
      </Grid>
    </Box>
  )
}

const StatCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))
