import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

import * as stores from '../stores'
import { stripTypenames } from '../utils/helpers'

const API_URL = process.env.REACT_APP_API_URL

const httpLink: ApolloLink = createUploadLink({
  uri: API_URL,
})

const authMiddlewareLink = new ApolloLink((operation, forward) => {
  const token = stores.authStore.getToken()

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
    },
  })

  return forward(operation)
})

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const variables = Object.keys(operation.variables)

    if (!variables.includes('file')) {
      operation.variables = stripTypenames(operation.variables, '__typename')
    }
  }

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
      if (extensions && extensions.code === 'INVALID_TOKEN') {
        stores.authStore.logout()
      }

      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authMiddlewareLink, removeTypenameMiddleware, httpLink]),
  cache: new InMemoryCache(),
})
