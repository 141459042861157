import React from 'react'
import { Avatar, Box, CircularProgress, Fab, styled } from '@mui/material'
import { Person as UserIcon, Edit as EditIcon, Add as AddIcon } from '@mui/icons-material'

import { compressImage } from '../../utils/image'

interface IProps {
  src?: string
  loading?: boolean
  onSelectImage: (file: File) => void
}

export const EditableAvatar: React.FC<IProps> = ({ src, loading, onSelectImage }) => {
  const fileInputRef = React.useRef<HTMLInputElement | null>(null)

  const handleSelectImage = () => {
    fileInputRef?.current?.click()
  }

  const handleSelectedImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files, validity } = event.target
    const file = files && files[0]

    if (!validity.valid || !file) {
      return null
    }

    const compressedFile = await compressImage(file, 500)

    onSelectImage(compressedFile)
  }

  return (
    <Box position='relative'>
      <StyledAvatar src={src}>
        <UserIcon sx={{ fontSize: 40 }} />
      </StyledAvatar>
      <EditFab size='small' disabled={loading} aria-label='edit' onClick={handleSelectImage}>
        {loading ? <CircularProgress size={16} /> : !!src ? <EditIcon /> : <AddIcon />}
      </EditFab>
      <input
        ref={fileInputRef}
        type='file'
        accept='image/*'
        onChange={handleSelectedImage}
        style={{ display: 'none' }}
      />
    </Box>
  )
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  position: 'relative',
  width: 120,
  height: 120,
  maxWidth: '100%',
  overflow: 'auto',
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.grey[300],
}))

const EditFab = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.primary.main,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndex.speedDial,
  boxShadow: theme.shadows[1],
}))
