import { gql } from '@apollo/client'

export const BRAND_FRAGMENT = gql`
  fragment BrandFragment on Brands {
    id
    title
  }
`

export const GET_BRANDS = gql`
  query GetBrandsQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    brands(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...BrandFragment
    }
  }
  ${BRAND_FRAGMENT}
`
