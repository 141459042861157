import React from 'react'
import { Box, Card, CardActionArea, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { formatProductTitle } from '../../utils/helpers'
import { DATE_TIME_FORMAT } from '../../core/constants'
import { IWarranty } from '../../graphql/types/warranty'

interface IProps {
  warranty: IWarranty
}

export const WarrantyListItem: React.FC<IProps> = ({ warranty }) => {
  return (
    <Card>
      <CardActionArea component={Link} to={`/warranties/${warranty.id}`}>
        <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1} sx={{ lineHeight: 1 }}>
            <Typography variant='subtitle1'>{warranty.code}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {`${warranty.brand.title}${warranty.product ? `, ${formatProductTitle(warranty.product)}` : ''}`}
            </Typography>
          </Box>
          <Box textAlign='right'>
            <Typography variant='subtitle2'>{warranty.seller.name}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {moment(warranty.created_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
