import React, { useState } from 'react'
import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Card,
  IconButton,
  styled,
  Grid,
} from '@mui/material'
import {
  Add as AddIcon,
  Save as SaveIcon,
  Send as SendIcon,
  Close as CloseIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material'
import { Formik, FormikProps, FieldArray } from 'formik'
import { observer } from 'mobx-react-lite'

import { useStores } from '../../../stores/hooks'
import { createSalesSchema } from './validation'
import { ProductsAutocomplete } from '../../../sections/common'
import { IProduct } from '../../../graphql/types/products'
import { ISeller } from '../../../graphql/types/sellers'
import { ISale, SaleStatus } from '../../../graphql/types/sales'
import { ImagesDropzone } from './ImagesDropzone'
import { SellerSuspendedMessage } from './SellerSuspendedMessage'

interface ISalesProduct {
  id?: number
  product: IProduct | null
  kit: string
}

export interface ISalesFormValues {
  products: ISalesProduct[]
  status: SaleStatus
}

interface IProps {
  sale?: ISale
  onSubmit: (values: ISalesFormValues, images: File[]) => void
  onDelete?: () => void
  loading?: boolean
}

const productFullTitle = (product: IProduct) => {
  return `${product.title} / ${product.width}/${product.height}/${product.diameter}`
}

export const SalesForm: React.FC<IProps> = observer(({ sale, onSubmit, onDelete, loading }) => {
  const { authStore } = useStores()
  const [images, setImages] = useState<File[]>([])
  const isSellerSuspended = authStore.isSeller && (authStore.userType as ISeller).suspended

  if (isSellerSuspended) {
    return <SellerSuspendedMessage />
  }

  const initialValues: ISalesFormValues = {
    products: sale?.sales_products.map((sp) => ({
      id: sp.id,
      product: { ...sp.product, fullTitle: productFullTitle(sp.product) },
      kit: `${sp.kit}`,
    })) || [
      {
        product: null,
        kit: '2',
      },
    ],
    status: sale?.status || SaleStatus.DRAFT,
  }

  const handleFormSubmit = (values: ISalesFormValues) => {
    onSubmit(values, images)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={createSalesSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, setFieldValue, submitForm, handleSubmit }: FormikProps<ISalesFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7} md={8}>
                <FieldArray name='products'>
                  {({ remove, push }) => (
                    <div>
                      {values.products.length > 0 &&
                        values.products.map((product, index) => (
                          <Card sx={{ mb: 2, position: 'relative' }} key={`product-${index}`}>
                            <Box p={2}>
                              <Box mb={2}>
                                <ProductsAutocomplete
                                  value={values.products[index].product}
                                  onChange={(p) => setFieldValue(`products.${index}.product`, p)}
                                  error={errors?.products && errors.products[index] ? true : false}
                                />
                              </Box>
                              <FormControl component='fieldset'>
                                <FormLabel component='legend'>Шины</FormLabel>
                                <RadioGroup
                                  row
                                  aria-label='kit'
                                  value={values.products[index].kit}
                                  onChange={handleChange}
                                  name={`products.${index}.kit`}
                                >
                                  <FormControlLabel value='2' control={<Radio />} label='2 шт' />
                                  <FormControlLabel value='4' control={<Radio />} label='4 шт' />
                                </RadioGroup>
                              </FormControl>
                            </Box>
                            {index > 0 && (
                              <CloseButton onClick={() => remove(index)}>
                                <CloseIcon />
                              </CloseButton>
                            )}
                          </Card>
                        ))}
                      <Box mb={4} textAlign='right'>
                        <Button
                          variant='outlined'
                          size='small'
                          startIcon={<AddIcon />}
                          onClick={() => push({ product: '', kit: '2' })}
                        >
                          Добавить товар
                        </Button>
                      </Box>
                    </div>
                  )}
                </FieldArray>
              </Grid>
              <Grid item xs={12} sm={5} md={4}>
                <ImagesDropzone images={images} uploaded={sale?.images} onChange={setImages} />
              </Grid>
            </Grid>

            <Box>
              {sale && sale.status === SaleStatus.DRAFT && (
                <IconButton color='error' disabled={loading} onClick={onDelete} sx={{ mr: 2 }}>
                  <DeleteIcon />
                </IconButton>
              )}
              <Button
                disabled={loading}
                variant='outlined'
                startIcon={<SaveIcon />}
                sx={{ mr: 2 }}
                onClick={() => {
                  setFieldValue('status', SaleStatus.DRAFT)
                  submitForm()
                }}
              >
                Сохранить
              </Button>
              <Button
                disabled={loading}
                color='primary'
                variant='contained'
                startIcon={<SendIcon />}
                onClick={() => {
                  setFieldValue('status', SaleStatus.SENT)
                  submitForm()
                }}
              >
                Отправить
              </Button>
            </Box>
          </form>
        )
      }}
    </Formik>
  )
})

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(1),
  bottom: theme.spacing(1),
}))
