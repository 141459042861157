import React, { useState } from 'react'
import { Box, Alert, Card, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material'
import { useFindWarrantyByCode } from '../../../graphql/hooks/warranty'
import { LoadableButton } from '../../../components/Form'
import { IWarranty } from '../../../graphql/types/warranty'

interface IProps {
  onNext?: (warranty: IWarranty) => void
}

export const CouponForm: React.FC<IProps> = ({ onNext }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string>()
  const [code, setCode] = useState<string>('')
  const findWarranty = useFindWarrantyByCode()

  const handleNext = async () => {
    if (code.trim().length === 0) {
      return
    }

    setError('')
    setLoading(true)

    try {
      const warranty = await findWarranty(code)

      if (!warranty) {
        setError('По данному коду купон не найден.')
        return
      }

      if (warranty.customer) {
        setError('Купон уже зарегистрирован.')
        return
      }

      onNext?.(warranty)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader title={<Typography variant='h6'>Купон</Typography>} />
      <CardContent>
        <Stack spacing={2}>
          {!!error && <Alert severity='error'>{error}</Alert>}
          <TextField
            label='Код купона покупателя'
            margin='none'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        </Stack>
        <Box pt={3} display='flex' justifyContent='flex-end'>
          <LoadableButton loading={loading} variant='contained' size='small' onClick={handleNext}>
            Далее
          </LoadableButton>
        </Box>
      </CardContent>
    </Card>
  )
}
