import { gql } from '@apollo/client'

export const ARTICLE_FRAGMENT = gql`
  fragment ArticleFragment on Articles {
    id
    title
    description
    content
    image {
      id
      url
    }
    published_at
  }
`

export const GET_ARTICLES = gql`
  query GetArticlesWithCountQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    articles(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...ArticleFragment
    }
    articlesCount(where: $where)
  }
  ${ARTICLE_FRAGMENT}
`

export const GET_ARTICLE = gql`
  query GetArticleQuery($id: ID!) {
    article(id: $id) {
      ...ArticleFragment
    }
    articleLikesCount(id: $id)
    didILikeArticle(id: $id)
  }
  ${ARTICLE_FRAGMENT}
`

export const LIKE_ARTICLE = gql`
  mutation LikeArticleMutation($id: ID!) {
    likeArticle(id: $id)
  }
`
