import { useQuery } from '@apollo/client'

import { GET_BRANDS } from '../../queries/brands'
import { IBrand, IGetBrandsVariables } from '../../types/brands'

export const useBrands = (where?: { [key: string]: any }) => {
  const { data, ...q } = useQuery<{ brands: IBrand[] }, IGetBrandsVariables>(GET_BRANDS, {
    variables: {
      where,
    },
  })

  const brands = data?.brands || []

  return {
    brands,
    ...q,
  }
}
