import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { useDropzone } from 'react-dropzone'
import { compressImage } from '../../../utils/image'
import { IImage } from '../../../graphql/types/image'
import { withApiUrl } from '../../../utils/helpers'

interface IProps {
  images: File[]
  uploaded?: IImage[]
  onChange: (images: File[]) => void
}

export const ImagesDropzone: React.FC<IProps> = ({ images, uploaded = [], onChange }) => {
  const onDrop = async (acceptedFiles: File[]) => {
    const accepted = []

    for (let image of acceptedFiles) {
      const compressed = await compressImage(image, 800)
      accepted.push(compressed)
    }

    onChange([...images, ...accepted])
  }

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: 'image/*',
    maxSize: 3000000,
    multiple: true,
    maxFiles: 7,
    onDrop,
  })

  const removeImage = (i: number) => () => {
    const newImages = [...images]
    newImages.splice(i, 1)
    onChange(newImages)
  }

  return (
    <Box>
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
        <input {...getInputProps()} />
        <Typography textAlign='center'>Переместите файлы сюда или кликните для загрузки</Typography>
      </Container>
      {images.length > 0 && (
        <Box pt={2}>
          <Typography variant='subtitle2'>Изрбражения</Typography>
          <Box>
            {images.map((file, i) => (
              <FileItem key={`file-${i}`} file={file} onRemove={removeImage(i)} />
            ))}
          </Box>
        </Box>
      )}
      {uploaded.length > 0 && (
        <Box pt={2}>
          <Typography variant='subtitle2' mb={1}>
            Загруженные
          </Typography>
          <Box>
            {uploaded.map((image) => (
              <ImageItem key={`image-${image.id}`} image={image} />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  )
}

const FileItem: React.FC<{ file: File; onRemove: () => void }> = ({ file, onRemove }) => {
  return (
    <Box display='flex' alignItems='center'>
      <Box flexGrow={1} flexShrink={1}>
        <Typography variant='caption' color='text.secondary' noWrap textOverflow='ellipsis'>
          {file.name}
        </Typography>
        <Typography variant='caption' color='text.secondary'>
          {file.size}
        </Typography>
      </Box>
      <IconButton size='small' color='error' onClick={onRemove}>
        <DeleteIcon />
      </IconButton>
    </Box>
  )
}

const ImageItem: React.FC<{ image: IImage }> = ({ image }) => {
  return <Img src={withApiUrl(image.url)} alt='' />
}

const getColor = (props: any) => {
  if (props.isDragAccept) {
    return '#00e676'
  }
  if (props.isDragReject) {
    return '#ff1744'
  }
  if (props.isDragActive) {
    return '#2196f3'
  }
  return '#eeeeee'
}

const Img = styled.img`
  width: 90px;
  height: 90px;
  object-fit: cover;
  margin: 0 8px 8px 0;
`

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 195px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`
