import { useQuery } from '@apollo/client'

import { GET_CITIES } from '../../queries/cities'
import { ICity } from '../../types/location'

export const useCities = () => {
  const { data, ...q } = useQuery<{ cities: ICity[] }, {}>(GET_CITIES)

  const cities = data?.cities || []

  return {
    cities,
    ...q,
  }
}
