import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import { PageHeader } from '../../components/Layout'
import { useRequestPrize } from '../../graphql/hooks/prizes'
import { IRequestPrizeInput } from '../../graphql/types/prize'
import { IRequestPrizeFormValues, RequestPrizeForm } from './components'

function RequestPrize() {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const [requestPrize, { loading }] = useRequestPrize()

  const handleSubmit = async (values: IRequestPrizeFormValues) => {
    try {
      const input: IRequestPrizeInput = {
        items: values.items,
      }

      await requestPrize({ variables: { input } })

      enqueueSnackbar('Запрос отправлен', { variant: 'success' })
      history.push('/prizes')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader title='Запрос на призы' back />
      <Box>
        <RequestPrizeForm loading={loading} onSubmit={handleSubmit} />
      </Box>
    </Box>
  )
}

export default RequestPrize
