import { Box, styled, Typography } from '@mui/material'

export const Copyright = () => {
  return (
    <Root pt={10} pb={5}>
      <Typography color='text.secondary'>{`© ${new Date().getFullYear()} Contipartners.kz ❤️`}</Typography>
    </Root>
  )
}

const Root = styled(Box)({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  textAlign: 'center',
})
