import { useState } from 'react'
import { Alert, Box, Typography } from '@mui/material'
import { useMutation } from '@apollo/client'
import { CheckCircle as OkIcon } from '@mui/icons-material'
import { Link, Redirect } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { ForgotPasswordForm, IForgotPasswordFormValues } from './components'
import { FORGOT_PASSWORD } from '../../graphql/queries/auth'
import { useStores } from '../../stores/hooks'

function ForgotPassword() {
  const { authStore } = useStores()
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [forgotPassword, { loading }] = useMutation<{ forgotPassword: { ok: boolean } }>(FORGOT_PASSWORD)

  if (authStore.isLoggedIn) {
    return <Redirect to='/' />
  }

  const handleSubmit = async (values: IForgotPasswordFormValues) => {
    try {
      const { data } = await forgotPassword({
        variables: { email: values.email },
      })

      if (data?.forgotPassword.ok) {
        setError(undefined)
        setSuccess(true)
      }
    } catch (err) {
      setError('Неверные данные')
      console.log(err)
    }
  }

  return (
    <Box>
      <Box pt={'260px'} mb={3}>
        <Typography variant='h4'>Восстановление пароля</Typography>
      </Box>
      {!!error && (
        <Box mb={2}>
          <Alert severity='error' variant='outlined'>
            {error}
          </Alert>
        </Box>
      )}
      {success ? (
        <Box>
          <OkIcon color='success' sx={{ mb: 1, fontSize: 42 }} />
          <Typography mb={2}>На указанную почту отправлено письмо с дальнейшими инструкциями</Typography>
          <Link to='/auth/login'>Войти</Link>
        </Box>
      ) : (
        <ForgotPasswordForm loading={loading} onSubmit={handleSubmit} />
      )}
    </Box>
  )
}

export default observer(ForgotPassword)
