import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import moment from 'moment'

import { SalesProductItem } from '../../../components/Sales'
import { ISale } from '../../../graphql/types/sales'
import { DATE_TIME_FORMAT } from '../../../core/constants'
import { SaleImages } from './SaleImages'

interface IProps {
  sale: ISale
}

export const SalesView: React.FC<IProps> = ({ sale }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7} md={8}>
          <Box mb={2}>
            <Typography variant='h6'>Дата</Typography>
            <Typography variant='body1' color='text.secondary'>
              {moment(sale.sent_date || sale.created_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant='h6'>Продавец</Typography>
            <Typography variant='body1' color='text.secondary'>
              {sale.seller.name}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant='h6'>Начислено баллов</Typography>
            <Typography variant='h6' color='primary'>
              {sale.points}
            </Typography>
          </Box>
          <Box mb={2}>
            <Typography variant='h6'>Товары</Typography>
          </Box>
          <Box mb={2}>
            {sale.sales_products.map((item) => (
              <Box key={`sales-${item.id}`} mb={2}>
                <SalesProductItem saleProduct={item} />
              </Box>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          {sale.images.length > 0 && <SaleImages images={sale.images} />}
        </Grid>
      </Grid>
    </Box>
  )
}
