import { Redirect, Route, RouteProps } from 'react-router'
import { observer } from 'mobx-react-lite'

import { useStores } from '../../stores/hooks'

export const PrivateRoute: React.FC<RouteProps> = observer(({ component: Component, ...routeProps }) => {
  const { authStore } = useStores()
  const isLoggedIn = authStore.isLoggedIn

  return (
    <Route
      {...routeProps}
      render={(props) =>
        isLoggedIn && Component ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/welcome',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
})
