import { default as sign3 } from './manager_sign_3.png'
import { default as sign4 } from './manager_sign_4.png'

const signs = [
  {
    id: 3,
    src: sign3,
  },
  {
    id: 4,
    src: sign4,
  },
]

export function signByManagerId(id: number) {
  const sign = signs.find((s) => s.id === id)

  return sign
}
