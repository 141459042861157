import { makeObservable, observable, action, computed } from 'mobx'

import { UserType, IUser, UserRoleType } from '../graphql/types/auth'

export class AuthStore {
  user: IUser | null = null
  token: string | null = null
  userType: UserType | null = null
  salesCount: number | null = null

  constructor() {
    makeObservable(this, {
      user: observable,
      token: observable,
      userType: observable,
      isLoggedIn: computed,
      isDealer: computed,
      isSeller: computed,
      login: action,
      logout: action,
      updateUserWithType: action,
      updateUserWithToken: action,
      setUser: action,
      setToken: action,
      setUserType: action,
      loadFromStorage: action,
      salesCount: observable,
      setSalesCount: action,
    })

    this.loadFromStorage()
  }

  get isLoggedIn() {
    return !!this.token && !!this.user && !!this.userType
  }

  get isDealer() {
    return this.user?.role.type === UserRoleType.DEALER
  }

  get isSeller() {
    return this.user?.role.type === UserRoleType.SELLER
  }

  getToken() {
    return this.token
  }

  login(user: IUser, token: string, type: UserType) {
    this.setUser(user)
    this.setToken(token)
    this.setUserType(type)

    this.saveToStorage()
  }

  logout() {
    this.setUser(null)
    this.setToken(null)
    this.setUserType(null)

    this.clearStorage()
  }

  updateUserWithType(user: IUser, type: UserType) {
    this.setUser(user)
    this.setUserType(type)

    this.saveToStorage()
  }

  updateUserWithToken(user: IUser, token: string) {
    this.setUser(user)
    this.setToken(token)

    this.saveToStorage()
  }

  setUser(user: IUser | null) {
    this.user = user
  }

  setToken(token: string | null) {
    this.token = token
  }

  setUserType(type: UserType | null) {
    this.userType = type
  }

  setSalesCount(count: number) {
    this.salesCount = count
  }

  private saveToStorage() {
    window.localStorage.setItem('@auth', JSON.stringify({ user: this.user, type: this.userType, token: this.token }))
  }

  private clearStorage() {
    window.localStorage.removeItem('@auth')
  }

  loadFromStorage() {
    const auth = window.localStorage.getItem('@auth')

    if (auth) {
      const { token, user, type } = JSON.parse(auth)

      this.setUser(user)
      this.setToken(token)
      this.setUserType(type)
    }
  }
}

export default new AuthStore()
