import { Box, Stack } from '@mui/material'
import { ImageUploadItem } from './ImageUploadItem'
import { useFormikContext } from 'formik'
import { IWarrantyCaseFormValues } from './useWarrantyCaseFormik'
import { IImage } from '../../../graphql/types/image'

type ImageFields = keyof Pick<
  IWarrantyCaseFormValues,
  'wholeTireImage' | 'sizeImage' | 'dotCodeImage' | 'damageImage' | 'couponCheckImage' | 'optImage1' | 'optImage2'
>

export const ImagesForm = () => {
  const { values, errors, setFieldValue } = useFormikContext<IWarrantyCaseFormValues>()

  const handleChange = (key: ImageFields) => (file: IImage) => {
    setFieldValue(key, file)
  }

  return (
    <Box>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mb={3}>
        <ImageUploadItem
          label='Шина целиком + дефект + борт'
          image={values.wholeTireImage}
          error={!!errors.wholeTireImage}
          onChange={handleChange('wholeTireImage')}
        />
        <ImageUploadItem
          label='Типоразмер, ин, ис'
          image={values.sizeImage}
          error={!!errors.sizeImage}
          onChange={handleChange('sizeImage')}
        />
        <ImageUploadItem
          label='DOT-код'
          image={values.dotCodeImage}
          error={!!errors.dotCodeImage}
          onChange={handleChange('dotCodeImage')}
        />
        <ImageUploadItem
          label='Повреждение'
          image={values.damageImage}
          error={!!errors.damageImage}
          onChange={handleChange('damageImage')}
        />
        <ImageUploadItem
          label='Купон + Чек'
          image={values.couponCheckImage}
          error={!!errors.couponCheckImage}
          onChange={handleChange('couponCheckImage')}
        />
      </Stack>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} sx={{ maxWidth: 450 }}>
        <ImageUploadItem
          label='Доп. 1 (необязательно)'
          image={values.optImage1}
          error={!!errors.optImage1}
          onChange={handleChange('optImage1')}
        />
        <ImageUploadItem
          label='Доп. 2 (необязательно)'
          image={values.optImage2}
          error={!!errors.optImage2}
          onChange={handleChange('optImage2')}
        />
      </Stack>
    </Box>
  )
}
