import { useMutation } from '@apollo/client'

import { GET_TESTINGS, SEND_TESTING_RESULTS } from '../../queries/testings'
import { ITestingResult, ISendTestingResultInput } from '../../types/testings'

export const useSendTestingResult = () => {
  return useMutation<{ sendTestingResults: ITestingResult }, { input: ISendTestingResultInput }>(SEND_TESTING_RESULTS, {
    refetchQueries: [{ query: GET_TESTINGS, variables: { limit: 9, start: 0 } }],
  })
}
