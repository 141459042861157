import { IImage } from './image'
import { IProduct } from './products'
import { ISeller } from './sellers'

export enum SaleStatus {
  DRAFT = 'draft',
  SENT = 'sent',
}

export interface ISale {
  id: number
  status: SaleStatus
  points: number
  sent_date: string | null
  sales_products: ISaleProduct[]
  seller: ISeller
  images: IImage[]
  created_at: string
  updated_at: string
}

export interface ISaleProduct {
  id: number
  product: IProduct
  kit: number
}

export interface IUpsertSaleProductInput {
  id?: number
  productId: number
  kit: number
}

export interface IUpsertSaleInput {
  id?: number
  status: SaleStatus
  salesProducts: IUpsertSaleProductInput[]
  images: File[]
}

export interface ISalesPayload {
  sales: ISale[]
  total: number
}

export interface IGetSalesVariables {
  sort?: string
  limit?: number
  start?: number
  where?: object
}
