import { useMutation } from '@apollo/client'

import { GET_WARRANTIES, GENERATE_WARRANTY } from '../../queries/warranty'
import { IWarranty, IGenerateWarrantyInput } from '../../types/warranty'

export const useGenerateWarranty = () => {
  return useMutation<{ generateWarranty: IWarranty }, { input: IGenerateWarrantyInput }>(GENERATE_WARRANTY, {
    refetchQueries: [{ query: GET_WARRANTIES, variables: { limit: 12, start: 0 } }],
  })
}
