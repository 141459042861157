import { gql } from '@apollo/client'

import { DEALER_FRAGMENT } from './dealers'

export const SELLER_FRAGMENT = gql`
  fragment SellerFragment on Sellers {
    id
    name
    email
    points
    contacts
    avatar {
      id
      url
    }
    dealer {
      ...DealerFragment
    }
    created_at
    isRegistered
    suspended
  }
  ${DEALER_FRAGMENT}
`

export const GET_SELLERS = gql`
  query GetSellersQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    sellersOfDealer(sort: $sort, limit: $limit, start: $start, where: $where) {
      sellers {
        ...SellerFragment
      }
      total
    }
  }
  ${SELLER_FRAGMENT}
`

export const CREATE_SELLER = gql`
  mutation CreateSellerMutation($input: CreateSellerByDealerInput!) {
    createSellerByDealer(input: $input) {
      ...SellerFragment
    }
  }
  ${SELLER_FRAGMENT}
`

export const DELETE_SELLER = gql`
  mutation DeleteSellerMutation($id: ID!) {
    deleteSellerByDealer(id: $id)
  }
`

export const SWITCH_SELLER_SUSPENDED = gql`
  mutation SwitchSuspendedByDealerMutation($id: ID!) {
    switchSellerSuspendedByDealer(id: $id)
  }
`
