import React from 'react'
import { Box, Card, CardActionArea, Chip, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { ISale, SaleStatus } from '../../graphql/types/sales'
import { plural } from '../../utils/helpers'
import { DATE_TIME_FORMAT } from '../../core/constants'
import { useStores } from '../../stores/hooks'

interface IProps {
  sale: ISale
}

export const SalesItem: React.FC<IProps> = ({ sale }) => {
  const { authStore } = useStores()

  const isSent = sale.status === SaleStatus.SENT

  const firstSaleProduct = sale.sales_products[0] || {}
  const product = firstSaleProduct.product
  const productTitle = `${product.title} / ${product.width}/${product.height}/${product.diameter}`
  const title = `${productTitle} / ${firstSaleProduct.kit} шины`

  const moreProducts = sale.sales_products.length - 1

  return (
    <Card>
      <CardActionArea component={Link} to={`/sales/${sale.id}`}>
        <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1} sx={{ lineHeight: 1 }}>
            {authStore.isDealer && <Typography color='text.secondary'>Продавец: {sale.seller.name}</Typography>}
            <Stack direction='row'>
              <Typography>{title}</Typography>
              {moreProducts > 0 && (
                <Chip
                  label={`+${moreProducts} ${plural(['товар', 'товара', 'товаров'], moreProducts)}`}
                  size='small'
                  sx={{ ml: 1 }}
                />
              )}
            </Stack>
            <Typography variant='caption' color='text.secondary'>
              {moment(sale.sent_date || sale.updated_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box textAlign='right'>
            {isSent && (
              <Box ml={2}>
                <Typography fontWeight={700}>{sale.points || ''}</Typography>
              </Box>
            )}
            <Box>
              {isSent ? (
                <Typography variant='body2' color='success.main'>
                  отправлен
                </Typography>
              ) : (
                <Typography variant='body2' color='text.secondary'>
                  черновик
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
