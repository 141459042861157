import { Box, styled } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Redirect, useHistory } from 'react-router-dom'
import { PageHeader } from '../../components/Layout'
import { useGenerateWarranty } from '../../graphql/hooks/warranty'
import { IGenerateWarrantyInput } from '../../graphql/types/warranty'
import { useStores } from '../../stores/hooks'
import { CreateWarrantyForm } from './components'

function CreateWarranty() {
  const { authStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [generateWarranty, { loading }] = useGenerateWarranty()

  if (!authStore.isSeller) {
    return <Redirect to='/warranties' />
  }

  const handleSubmit = async (input: IGenerateWarrantyInput) => {
    try {
      const { data } = await generateWarranty({ variables: { input } })

      if (data?.generateWarranty) {
        enqueueSnackbar('Гарантия создана успешно', { variant: 'success' })
        history.replace(`/warranties/${data.generateWarranty.id}`)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader back title='Новая гарантия' />
      <Body>
        <CreateWarrantyForm onSubmit={handleSubmit} loading={loading} />
      </Body>
    </Box>
  )
}

const Body = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    maxWidth: 640,
    margin: 'auto',
  },
}))

export default CreateWarranty
