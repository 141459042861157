import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { Block as SuspendIcon } from '@mui/icons-material'

export const SellerSuspendedMessage: React.FC = () => {
  return (
    <Paper>
      <Box p={3} textAlign='center'>
        <Box>
          <SuspendIcon sx={{ fontSize: 46 }} color='disabled' />
        </Box>
        <Typography color='textSecondary'>Ваш аккаунт временно приостановлен.</Typography>
      </Box>
    </Paper>
  )
}
