import React, { useState } from 'react'
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { LoadableButton } from '../../components/Form'
import { useFindWarrantyByCode } from '../../graphql/hooks/warranty'

interface IProps {
  open: boolean
  onClose?: VoidFunction
}

export const SearchWarrantyDialog: React.FC<IProps> = ({ open, onClose }) => {
  const history = useHistory()
  const [code, setCode] = useState<string>('')
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(false)
  const findWarranty = useFindWarrantyByCode()

  const handleSearch = async () => {
    if (code.trim().length === 0) {
      return
    }

    setError(undefined)
    setLoading(true)

    try {
      const warranty = await findWarranty(code)

      if (warranty) {
        history.push(`/warranties/${warranty.id}`)
      } else {
        setError('Гарантия по данному коду не найдена')
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <Dialog maxWidth='sm' fullWidth open={open} onClose={onClose}>
      <DialogTitle>Поиск гарантии по коду</DialogTitle>
      <DialogContent>
        <Box pt={2}>
          <Stack spacing={2}>
            {!!error && <Alert severity='error'>{error}</Alert>}
            <TextField label='Код' margin='none' value={code} onChange={(e) => setCode(e.target.value)} />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Закрыть</Button>
        <LoadableButton loading={loading} variant='contained' size='small' onClick={handleSearch}>
          Далее
        </LoadableButton>
      </DialogActions>
    </Dialog>
  )
}
