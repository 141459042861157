import React, { useState } from 'react'
import { Box, Container, Dialog, DialogContent, IconButton, styled, Typography } from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { PDFViewer } from '@react-pdf/renderer'
import { IWarranty } from '../../graphql/types/warranty'
import { WarrantyCaseForm } from './WarrantyCaseForm'
import { IWarrantyCase } from '../../graphql/types/warranty-case'
import { WarrantyCaseDetail } from './WarrantyCaseDetail'
import { DecisionPDF } from './pdf'

interface IProps {
  warranty: IWarranty
  warrantyCase?: IWarrantyCase
  open: boolean
  onClose?: VoidFunction
}

export const WarrantyCaseDialog: React.FC<IProps> = ({ warranty, warrantyCase, open, onClose }) => {
  const [openPDF, setOpenPDF] = useState(false)

  const handleClose = () => {
    if (openPDF) {
      setOpenPDF(false)
    } else {
      onClose?.()
    }
  }

  return (
    <Dialog fullScreen open={open}>
      <CloseBox>
        <IconButton color='inherit' onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </CloseBox>
      <Box
        sx={{
          p: 2,
          zIndex: 9,
          bgcolor: 'primary.main',
          boxShadow: (theme) => theme.shadows[2],
        }}
      >
        <Typography variant='h6'>Заявка на гарантийный случай</Typography>
      </Box>
      {openPDF && warrantyCase ? (
        <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
          <PDFViewer width='100%' height='100%' style={{ border: 'none' }}>
            <DecisionPDF warranty={warranty} warrantyCase={warrantyCase} />
          </PDFViewer>
        </Box>
      ) : (
        <DialogContent>
          <Container maxWidth='lg'>
            <Box sx={{ pt: 3 }} />

            {!!warrantyCase ? (
              <WarrantyCaseDetail warrantyCase={warrantyCase} onOpenPDF={() => setOpenPDF(true)} />
            ) : (
              <WarrantyCaseForm warranty={warranty} onSucess={onClose} />
            )}

            <Box sx={{ pt: 3 }} />
          </Container>
        </DialogContent>
      )}
    </Dialog>
  )
}

const CloseBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(1),
  zIndex: 10,
}))
