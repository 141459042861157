import { useQuery } from '@apollo/client'

import { PROFILE } from '../../queries/auth'
import { IProfilePayload } from '../../types/auth'

export const useProfile = () => {
  const { data, ...q } = useQuery<IProfilePayload, {}>(PROFILE, {
    fetchPolicy: 'network-only',
  })

  const profile = data?.profile || null
  const salesCount = data?.userSalesCount || 0

  return {
    profile,
    salesCount,
    ...q,
  }
}
