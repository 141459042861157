import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { GET_TESTINGS } from '../../queries/testings'
import { IGetTestingsVariables, IGetTestingsResponse } from '../../types/testings'

export const useTestings = (limit: number = 9) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ userTestings: IGetTestingsResponse }, IGetTestingsVariables>(GET_TESTINGS, {
    variables: {
      limit,
      start: (page - 1) * limit,
      sort: 'created_at:DESC',
    },
  })

  const testings = data?.userTestings.testings || []
  const total = data?.userTestings.total || 0

  return {
    testings,
    total,
    page,
    setPage,
    ...q,
  }
}
