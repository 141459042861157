import { Box, Button, Pagination, Paper, Stack, Typography } from '@mui/material'
import { StarBorder as GiftIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { PageHeader } from '../../components/Layout'
import { EmptyListMessage, Loader } from '../../components/UI'
import { usePrizes } from '../../graphql/hooks/prizes'
import { PrizeListItem } from './components'
import { useStores } from '../../stores/hooks'

function Prizes() {
  const { prizes, total, page, setPage, loading } = usePrizes(9)
  const paginationCount = Math.ceil(total / 9)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <PageHeader title='Призы' />
      <Box mb={3}>
        <Header />
      </Box>
      <Box mb={4}>
        {loading && <Loader />}
        {prizes.length > 0 && !loading ? (
          <Box>
            {prizes.map((item) => (
              <Box key={`prize-item-${item.id}`} mb={2}>
                <PrizeListItem prize={item} />
              </Box>
            ))}
          </Box>
        ) : (
          <EmptyListMessage message='Призов еще нет' />
        )}
      </Box>
      {paginationCount > 1 && (
        <Stack alignItems='center'>
          <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
        </Stack>
      )}
    </Box>
  )
}

const Header = observer(() => {
  const { authStore } = useStores()
  const points = authStore.userType?.points || 0

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <Paper sx={{ p: 1.5, mr: 1 }}>
        <Typography fontWeight='600'>{`Доступно ${points} баллов`}</Typography>
      </Paper>
      <Button
        variant='contained'
        disabled={points <= 100}
        startIcon={<GiftIcon />}
        component={Link}
        to={'/prizes/create'}
      >
        Запросить призы
      </Button>
    </Box>
  )
})

export default Prizes
