import React from 'react'
import { Box, Pagination, Stack } from '@mui/material'

import { PageHeader } from '../../components/Layout'
import { useTestings } from '../../graphql/hooks/testing'
import { EmptyListMessage, Loader } from '../../components/UI'
import { TestingListItem } from './components'

function Testings() {
  const { testings, total, page, setPage, loading } = useTestings(9)
  const paginationCount = Math.ceil(total / 9)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  return (
    <Box>
      <PageHeader title='Тестирование' />
      <Box mb={4}>
        {loading && <Loader />}
        {testings.length > 0 && !loading ? (
          <Box>
            {testings.map((item) => (
              <Box key={`testing-item-${item.id}`} mb={2}>
                <TestingListItem testing={item} />
              </Box>
            ))}
          </Box>
        ) : (
          <EmptyListMessage message='Тестов еще нет' />
        )}
      </Box>
      {paginationCount > 1 && (
        <Stack alignItems='center'>
          <Pagination count={paginationCount} variant='outlined' page={page} onChange={handleChangePage} />
        </Stack>
      )}
    </Box>
  )
}

export default Testings
