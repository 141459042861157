import { useMutation } from '@apollo/client'

import { CREATE_SELLER, GET_SELLERS } from '../../queries/sellers'
import { ISeller } from '../../types/sellers'
import { ICreateSellerInput } from '../../types/sellers'

export const useCreateSeller = () => {
  return useMutation<{ createSellerByDealer: ISeller }, { input: ICreateSellerInput }>(CREATE_SELLER, {
    refetchQueries: [{ query: GET_SELLERS, variables: { limit: 12, start: 0 } }],
  })
}
