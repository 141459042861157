import React from 'react'
import { useQuery } from '@apollo/client'
import { Box, Card, CardContent, Chip, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { PageHeader, PageNotFound } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { IPrize, IPrizeItemInput, PrizeStatus } from '../../graphql/types/prize'
import { GET_PRIZE } from '../../graphql/queries/prizes'
import { PRIZE_STATUS_LABELS } from '../../core/constants'

function PrizeDetail() {
  const { id } = useParams<{ id: string }>()
  const { data, loading } = useQuery<{ myPrize: IPrize | null }, { id: number }>(GET_PRIZE, {
    variables: { id: parseInt(id, 10) },
  })

  if (loading) {
    return <Loader />
  }

  const prize = data?.myPrize

  if (!prize) {
    return <PageNotFound />
  }

  return (
    <Box>
      <PageHeader title='Призы' back />
      <Box mb={3} display='flex' alignItems='center'>
        <Status prize={prize} />
        {prize.status === PrizeStatus.APPROVED && prize.points && (
          <Typography ml={2} fontWeight='600'>{`Списано ${prize.points} баллов`}</Typography>
        )}
      </Box>
      <Box>
        <Typography mb={1} variant='h6'>
          Наименования
        </Typography>
        {prize.items.map((item) => (
          <Box key={`prize-item-${item.name}`} mb={2}>
            <PrizeItem item={item} />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

const PrizeItem: React.FC<{ item: IPrizeItemInput }> = ({ item }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant='caption' color='text.secondary'>
          Наименование
        </Typography>
        <Typography mb={1}>{item.name}</Typography>
        <Typography variant='caption' color='text.secondary'>
          Ссылка
        </Typography>
        <Typography mb={1}>{item.link}</Typography>
        {!!item.comment && (
          <>
            <Typography variant='caption' color='text.secondary'>
              Комментарий
            </Typography>
            <Typography>{item.comment}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  )
}

const Status: React.FC<{ prize: IPrize }> = ({ prize }) => {
  const color =
    prize.status === PrizeStatus.IN_PROGRESS ? 'default' : prize.status === PrizeStatus.APPROVED ? 'success' : 'error'

  return <Chip label={PRIZE_STATUS_LABELS[prize.status]} color={color} />
}

export default PrizeDetail
