import React from 'react'
import { Box, Card, CardActionArea, Chip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { DATE_TIME_FORMAT, PRIZE_STATUS_LABELS } from '../../../core/constants'
import { IPrize, PrizeStatus } from '../../../graphql/types/prize'
import { plural } from '../../../utils/helpers'

interface IProps {
  prize: IPrize
}

export const PrizeListItem: React.FC<IProps> = ({ prize }) => {
  const title = `Запрос на ${prize.items.length} ${plural(
    ['наименование', 'наименования', 'наименовании'],
    prize.items.length
  )}`

  return (
    <Card>
      <CardActionArea component={Link} to={`/prizes/${prize.id}`}>
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1} sx={{ lineHeight: 1 }}>
            <Typography>{title}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {moment(prize.created_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box>
            <Status prize={prize} />
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}

const Status: React.FC<{ prize: IPrize }> = ({ prize }) => {
  const color =
    prize.status === PrizeStatus.IN_PROGRESS ? 'default' : prize.status === PrizeStatus.APPROVED ? 'success' : 'error'

  return <Chip label={PRIZE_STATUS_LABELS[prize.status]} color={color} />
}
