import React from 'react'
import { Autocomplete, Button, Card, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { useCities } from '../../../graphql/hooks/cities'
import { customerSchema } from './validation'

interface IProps {
  initialData?: ICustomerFormValues
  onNext: (values: ICustomerFormValues) => void
  onBack?: VoidFunction
}

export interface ICustomerFormValues {
  fullName: string
  // email: string
  // phone: string
  // birthday: string
  city: number
}

export const CustomerForm: React.FC<IProps> = ({ initialData, onNext, onBack }) => {
  const { cities } = useCities()

  const initialValues: ICustomerFormValues = {
    fullName: initialData?.fullName ?? '',
    // email: initialData?.email ?? '',
    // phone: initialData?.phone ?? '',
    // birthday: initialData?.birthday ?? '',
    city: initialData?.city ?? 0,
  }

  const handleFormSubmit = (values: ICustomerFormValues) => {
    onNext(values)
  }

  return (
    <Card sx={{ mb: 3 }}>
      <CardHeader title={<Typography variant='h6'>Данные покупателя</Typography>} />
      <CardContent>
        <Formik initialValues={initialValues} validationSchema={customerSchema} onSubmit={handleFormSubmit}>
          {({ values, errors, getFieldProps, setFieldValue, handleSubmit }: FormikProps<ICustomerFormValues>) => (
            <form onSubmit={handleSubmit}>
              <TextField
                label='Частное или Юр. лицо'
                margin='none'
                {...getFieldProps('fullName')}
                sx={{ mb: 2 }}
                error={!!errors.fullName}
              />
              <Autocomplete
                disablePortal
                value={values.city ? cities.find((c) => c.id === values.city) : null}
                options={cities}
                getOptionLabel={(c) => c.title}
                onChange={(e, v) => setFieldValue('city', v?.id)}
                fullWidth
                renderInput={(params) => <TextField {...params} label='Город' margin='none' error={!!errors.city} />}
              />
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disablePortal
                    value={values.city ? cities.find((c) => c.id === values.city) : null}
                    options={cities}
                    getOptionLabel={(c) => c.title}
                    onChange={(e, v) => setFieldValue('city', v?.id)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label='Город' margin='none' error={!!errors.city} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label='E-mail' margin='none' {...getFieldProps('email')} error={!!errors.email} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MaskedField
                    mask='+7 (999) 999-99-99'
                    label='Контактный телефон'
                    margin='none'
                    type='tel'
                    {...getFieldProps('phone')}
                    error={!!errors.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MaskedField
                    mask='99.99.9999'
                    label='День рождения'
                    margin='none'
                    {...getFieldProps('birthday')}
                    error={!!errors.birthday}
                  />
                </Grid>
              </Grid> */}
              <Stack pt={3} spacing={2} direction='row' justifyContent='flex-end'>
                {!!onBack && (
                  <Button size='small' onClick={onBack}>
                    Назад
                  </Button>
                )}
                <Button variant='contained' type='submit' size='small'>
                  Далее
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  )
}
