import { useState } from 'react'
import { Box, Button, Card, CardContent, Grid, Stack, styled, Typography } from '@mui/material'
import { CheckCircle as OkIcon } from '@mui/icons-material'
import { Link, RouteComponentProps } from 'react-router-dom'

import { authBg, mainLogo } from '../../assets/images'
import { RegisterWarrantyForm } from '../../sections/warranty/register'

const RegisterWarranty: React.FC<RouteComponentProps> = ({ location }) => {
  const [registered, setRegistered] = useState(false)

  const handleRegistered = () => {
    setRegistered(true)
  }

  return (
    <Box>
      <LogoImg src={mainLogo} alt='' />
      <Grid container sx={{ height: 'calc(100vh - 32px)', backgroundColor: '#f0f0f0' }}>
        <Grid item xs={12} sm={12} md={9} sx={{ height: '100%', overflowY: 'auto', overflowX: 'hidden' }}>
          <Body>
            <Box pt={'180px'} mb={5}>
              <Title variant='h4'>
                Регистрация купона
                <br />
                расширенной гарантии
              </Title>
            </Box>

            {registered ? <RegisteredMessage /> : <RegisterWarrantyForm onRegistered={handleRegistered} />}
          </Body>
        </Grid>
        <Grid
          item
          xs={false}
          sm={false}
          md={3}
          sx={{
            backgroundImage: `url(${authBg})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) => t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </Box>
  )
}

const RegisteredMessage = () => (
  <Card>
    <CardContent>
      <Stack alignItems='center' spacing={2}>
        <OkIcon sx={{ fontSize: 64, color: 'success.main' }} />
        <Typography>Ваш купон успешно зарегистрирован.</Typography>
        <Button component={Link} to='/'>
          Закрыть
        </Button>
      </Stack>
    </CardContent>
  </Card>
)

const Title = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.h6.fontSize,
  },
}))

const Body = styled('div')(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingBottom: '140px',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 640,
    margin: 'auto',
  },
}))

const LogoImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(6),
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.down('sm')]: {
    top: -50,
  },
}))

export default RegisterWarranty
