import { useState } from 'react'
import { useApolloClient, useMutation } from '@apollo/client'

import { useStores } from '../../../stores/hooks'
import { LOGIN, PROFILE } from '../../queries/auth'
import { ILoginData, ILoginInput, IProfilePayload } from '../../types/auth'

export const useLogin = () => {
  const { authStore } = useStores()
  const client = useApolloClient()
  const [loginMutation] = useMutation<{ login: ILoginData }>(LOGIN)
  const [loading, setLoading] = useState(false)

  const login = async (input: ILoginInput) => {
    try {
      setLoading(true)
      const res = await loginMutation({
        variables: { input: { identifier: input.email, password: input.password } },
      })

      if (res.data?.login) {
        authStore.setToken(res.data.login.jwt)

        const { data } = await client.query<IProfilePayload>({
          query: PROFILE,
          fetchPolicy: 'network-only',
        })

        authStore.updateUserWithType(data.profile.user, data.profile.type)
        authStore.setSalesCount(data.userSalesCount)
      }
    } catch (err) {
      throw err
    } finally {
      setLoading(false)
    }
  }

  return { login, loading }
}
