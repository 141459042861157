import { Box, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router'

import { PageHeader } from '../../components/Layout'
import { useUpsertSale } from '../../graphql/hooks/sales'
import { IUpsertSaleInput, SaleStatus } from '../../graphql/types/sales'
import { ISalesFormValues, SalesForm } from './components'

const SALES_ENABLED = true

function SalesCreate() {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const [upsertSale, { loading }] = useUpsertSale()

  const handleSubmit = async (values: ISalesFormValues, images: File[]) => {
    try {
      const input: IUpsertSaleInput = {
        status: values.status,
        salesProducts: values.products.map((p) => ({
          productId: p.product!.id,
          kit: parseInt(p.kit, 10),
        })),
        images,
      }

      await upsertSale({ variables: { input } })

      const message = values.status === SaleStatus.DRAFT ? 'Данные сохранены' : 'Данные отправлены'
      enqueueSnackbar(message, { variant: 'success' })
      history.push('/sales')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader title='Регистрация продажи' back />
      <Box>
        {SALES_ENABLED ? (
          <SalesForm loading={loading} onSubmit={handleSubmit} />
        ) : (
          <Box textAlign={'center'} py={4}>
            <Typography variant='h6' color='GrayText'>
              Продажи временно приостановлены
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SalesCreate
