import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { IDealer } from '../../types/dealers'
import { IFindVariables } from '../../types/common'
import { DEALERS_RATING } from '../../queries/leaders'

export const useDealersRating = (limit: number) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<{ dealers: IDealer[]; dealersCount: number }, IFindVariables>(DEALERS_RATING, {
    variables: {
      sort: 'points:DESC',
      limit,
      start: (page - 1) * limit,
    },
  })

  const dealers = data?.dealers || []
  const total = data?.dealersCount || 0

  return {
    dealers,
    total,
    setPage,
    ...q,
  }
}
