import React from 'react'
import { TextField, Box, Typography } from '@mui/material'
import { Formik, FormikProps } from 'formik'
import { Link } from 'react-router-dom'

import { LoadableButton } from '../../../components/Form'
import { forgotPasswordSchema } from './validation'

export interface IForgotPasswordFormValues {
  email: string
}

interface IProps {
  onSubmit: (values: IForgotPasswordFormValues) => void
  loading?: boolean
}

export const ForgotPasswordForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const initialValues: IForgotPasswordFormValues = {
    email: '',
  }

  const handleFormSubmit = (values: IForgotPasswordFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IForgotPasswordFormValues>) => (
        <form onSubmit={handleSubmit}>
          <TextField
            label='Email'
            name='email'
            value={values.email}
            onChange={handleChange}
            error={!!errors.email}
            required
          />
          <Box textAlign='right'>
            <Typography>
              <Link to='/auth/login'>Вспомнили пароль?</Link>
            </Typography>
          </Box>
          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Отправить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
}
