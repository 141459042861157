import * as yup from 'yup'

export const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
})

export const registerSchema = yup.object().shape({
  name: yup.string().required(),
  password: yup.string().required(),
})

export const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email().required(),
})

export const resetPasswordSchema = yup.object().shape({
  password: yup.string().required(),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null])
    .required(),
})
