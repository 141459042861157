import { useEffect, useState } from 'react'
import { Container, styled } from '@mui/material'
import { Route, RouteComponentProps, Switch } from 'react-router'

import { RoleRoute } from '../components/Routes'
import { UserRoleType } from '../graphql/types/auth'
import { Copyright, Header, PageNotFound } from '../components/Layout'
import Home from '../views/Home/Home'
import Sales from '../views/Sales/Sales'
import SalesCreate from '../views/Sales/SalesCreate'
import SalesDetail from '../views/Sales/SalesDetail'
import News from '../views/News/News'
import NewsSingle from '../views/News/NewsSingle'
import Sellers from '../views/Sellers/Sellers'
import LeadersSalesPoint from '../views/Leaders/LeadersSalesPoint'
import LeadersSellers from '../views/Leaders/LeadersSellers'
import Profile from '../views/Profile/Profile'
import Testings from '../views/Testing/Testings'
import TestingDetail from '../views/Testing/TestingDetail'
import Prizes from '../views/Prizes/Prizes'
import RequestPrize from '../views/Prizes/RequestPrize'
import PrizeDetail from '../views/Prizes/PrizeDetail'
import Warranties from '../views/Warranties/Warranties'
import WarrantyDetail from '../views/Warranties/WarrantyDetail'
import CreateWarranty from '../views/Warranties/CreateWarranty'
import { useWatchProfile } from '../graphql/hooks/auth'
import { NotificationDrawer } from '../services/notifications'

export const MainLayout: React.FC<RouteComponentProps> = ({ location }) => {
  useWatchProfile()

  const [notificationOpen, setNotificationOpen] = useState(false)

  const toggleNotification = () => {
    setNotificationOpen((prev) => !prev)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    setNotificationOpen(false)
  }, [location.pathname])

  return (
    <Main>
      <Header onOpenNotification={toggleNotification} />
      <Container maxWidth='lg' sx={{ position: 'relative' }}>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/sales' exact component={Sales} />
          <RoleRoute role={UserRoleType.SELLER} path='/sales/create' exact component={SalesCreate} />
          <Route path='/sales/:id' exact component={SalesDetail} />
          <Route path='/news' exact component={News} />
          <Route path='/news/:id' exact component={NewsSingle} />
          <RoleRoute role={UserRoleType.DEALER} path='/sellers' exact component={Sellers} />
          <Route path='/leaders/sales-points' exact component={LeadersSalesPoint} />
          <Route path='/leaders/sellers' exact component={LeadersSellers} />
          <Route path='/profile' exact component={Profile} />
          <RoleRoute role={UserRoleType.SELLER} path='/testings' exact component={Testings} />
          <RoleRoute role={UserRoleType.SELLER} path='/testings/:id' exact component={TestingDetail} />
          <Route path='/prizes' exact component={Prizes} />
          <Route path='/prizes/create' exact component={RequestPrize} />
          <Route path='/prizes/:id' exact component={PrizeDetail} />
          <Route path='/warranties' exact component={Warranties} />
          <Route path='/warranties/create' exact component={CreateWarranty} />
          <Route path='/warranties/:id' exact component={WarrantyDetail} />
          <Route component={PageNotFound} />
        </Switch>
      </Container>

      <NotificationDrawer open={notificationOpen} onClose={toggleNotification} />
      <Copyright />
    </Main>
  )
}

const Main = styled('main')({
  width: '100%',
  minHeight: 'calc(100vh - 32px)',
  overflow: 'hidden',
  backgroundColor: '#f0f0f0',
  paddingBottom: '140px',
})

export default MainLayout
