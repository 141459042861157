import { Box, Grid } from '@mui/material'
import { useParams } from 'react-router-dom'
import { PageHeader, PageNotFound } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { useWarranty } from '../../graphql/hooks/warranty'
import { WarrantyInfo, WarrantyCustomerInfo } from '../../sections/warranty'
import { WarrantyCasesList } from '../../sections/warrantyCases'

function WarrantyDetail() {
  const { id } = useParams<{ id: string }>()
  const { warranty, loading } = useWarranty(parseInt(id, 10))

  if (loading) {
    return <Loader />
  }

  if (!warranty) {
    return <PageNotFound />
  }

  return (
    <Box>
      <PageHeader back title='Гарантия' />
      <Box mb={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <WarrantyInfo warranty={warranty} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <WarrantyCustomerInfo warranty={warranty} />
          </Grid>
        </Grid>
      </Box>

      <WarrantyCasesList warranty={warranty} />
    </Box>
  )
}

export default WarrantyDetail
