import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { useParams } from 'react-router-dom'

import { PageHeader, PageNotFound } from '../../components/Layout'
import { Loader } from '../../components/UI'
import { useSendTestingResult } from '../../graphql/hooks/testing'
import { GET_TESTING } from '../../graphql/queries/testings'
import { ISendTestingResultInput, ITesting, ITestingResult, TestingStatus } from '../../graphql/types/testings'
import { ITestingFormValues, TestingForm, TestingResult } from './components'

function TestingDetail() {
  const { id } = useParams<{ id: string }>()
  const [testingResult, setTestingResult] = useState<ITestingResult>()
  const { data, loading } = useQuery<{ userTesting: ITesting | null }, { id: number }>(GET_TESTING, {
    variables: { id: parseInt(id, 10) },
  })
  const [sendResults, { loading: sendLoading }] = useSendTestingResult()

  if (loading) {
    return <Loader />
  }

  const testing = data?.userTesting

  if (!testing) {
    return <PageNotFound />
  }

  const myResult = testing.myResult || testingResult

  const handleSubmit = async (values: ITestingFormValues) => {
    try {
      const input: ISendTestingResultInput = {
        testingId: testing.id,
        results: values.results,
      }

      const response = await sendResults({
        variables: { input },
      })

      const result = response.data?.sendTestingResults

      if (result) {
        setTestingResult(result)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <PageHeader title={testing.title} back />
      {!!testing.description && (
        <Box mb={4}>
          <Typography>{testing.description}</Typography>
        </Box>
      )}

      {testing.status === TestingStatus.STOPPED && (
        <Box mb={2}>
          <Card sx={{ textAlign: 'center' }}>
            <CardContent>
              <Typography mt={1} color='text.secondary'>
                Тестирование остановлено
              </Typography>
            </CardContent>
          </Card>
        </Box>
      )}

      {myResult && <TestingResult testing={testing} result={myResult} />}

      {!myResult && testing.status === TestingStatus.PUBLISHED && (
        <TestingForm testing={testing} loading={sendLoading} onSubmit={handleSubmit} />
      )}
    </Box>
  )
}

export default TestingDetail
