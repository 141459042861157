import { useMutation } from '@apollo/client'
import { PROFILE } from '../../queries/auth'

import { GET_SALES, UPSERT_SALE } from '../../queries/sales'
import { ISale, IUpsertSaleInput } from '../../types/sales'

export const useUpsertSale = () => {
  return useMutation<{ upsertSaleBySeller: ISale }, { input: IUpsertSaleInput }>(UPSERT_SALE, {
    refetchQueries: [{ query: GET_SALES, variables: { limit: 12, start: 0 } }, { query: PROFILE }],
  })
}
