import { useQuery } from '@apollo/client'
import { WARRANTY_CASES } from '../../queries/warranty-cases'
import { IWarranty } from '../../types/warranty'
import { IWarrantyCasesData, IWarrantyCasesVariables } from '../../types/warranty-case'

export const useWarrantyCases = (warranty: IWarranty) => {
  const { data, ...q } = useQuery<IWarrantyCasesData, IWarrantyCasesVariables>(WARRANTY_CASES, {
    variables: {
      where: {
        warranty: warranty.id,
      },
    },
  })

  const warrantyCases = data?.warrantyCases || []

  return {
    warrantyCases,
    ...q,
  }
}
