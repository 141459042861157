import React from 'react'
import { Typography, Box } from '@mui/material'
import moment from 'moment'
import { IWarranty } from '../../graphql/types/warranty'
import { DATE_FORMAT } from '../../core/constants'
import { formatProductTitle } from '../../utils/helpers'

interface IProps {
  warranty: IWarranty
}

export const WarrantyProductsInfo: React.FC<IProps> = ({ warranty }) => {
  const { product, secondProduct, purchaseDate } = warranty

  if (!product) {
    return null
  }

  return (
    <Box>
      <Typography variant='h6'>{formatProductTitle(product)}</Typography>
      {!!secondProduct && <Typography variant='h6'>{formatProductTitle(secondProduct)}</Typography>}
      {!!purchaseDate && (
        <Box pt={1}>
          <Typography variant='body2' color='text.secondary'>
            Дата покупки
          </Typography>
          <Typography>{moment(purchaseDate).format(DATE_FORMAT)}</Typography>
        </Box>
      )}
    </Box>
  )
}
