import React, { useState } from 'react'
import { Card, Typography, Stack, Box, Chip, CardContent, Button } from '@mui/material'
import { CheckCircle as OkIcon, Visibility as ViewIcon, Error as NotActivatedIcon } from '@mui/icons-material'
import { RegisterCustomerDialog } from './register'
import { CustomerDetailDialog } from './CustomerDetailDialog'
import { IWarranty } from '../../graphql/types/warranty'
import { ICustomer } from '../../graphql/types/customer'
import { useStores } from '../../stores/hooks'

interface IProps {
  warranty: IWarranty
}

export const WarrantyCustomerInfo: React.FC<IProps> = ({ warranty }) => {
  const { authStore } = useStores()
  const [customerDialog, setCustomerDialog] = useState(false)

  const toggleCustomerDialog = () => {
    setCustomerDialog((prev) => !prev)
  }

  return (
    <>
      <Card sx={{ minHeight: '100%' }}>
        <CardContent>
          {warranty.customer ? (
            <AcivatedInfo customer={warranty.customer} onView={toggleCustomerDialog} />
          ) : (
            <NotAcivatedInfo onRegister={toggleCustomerDialog} />
          )}
        </CardContent>
      </Card>
      {warranty.customer ? (
        <CustomerDetailDialog customer={warranty.customer} open={customerDialog} onClose={toggleCustomerDialog} />
      ) : (
        <>
          {authStore.isSeller && (
            <RegisterCustomerDialog warranty={warranty} open={customerDialog} onClose={toggleCustomerDialog} />
          )}
        </>
      )}
    </>
  )
}

const AcivatedInfo = ({ customer, onView }: { customer: ICustomer; onView: VoidFunction }) => (
  <Stack spacing={3} alignItems='center'>
    <Chip label='Активирован' color='success' icon={<OkIcon />} sx={{ mt: 2 }} />
    <Box mb={3} textAlign='center'>
      <Typography variant='h6'>{customer.fullName}</Typography>
      {/* <Typography color='text.secondary'>{customer.email}</Typography>
      <Typography color='text.secondary'>{customer.phone}</Typography> */}
    </Box>
    <Box>
      <Button variant='contained' size='small' startIcon={<ViewIcon />} onClick={onView}>
        Просмотр
      </Button>
    </Box>
  </Stack>
)

const NotAcivatedInfo = ({ onRegister }: { onRegister: VoidFunction }) => {
  const { authStore } = useStores()

  return (
    <Stack spacing={3} alignItems='center'>
      <Chip label='Не активирован' color='warning' icon={<NotActivatedIcon />} sx={{ mt: 2 }} />
      <Box mb={3} textAlign='center'>
        <Typography variant='h6'>Покупатель еще не зарегистрировал купон</Typography>
      </Box>
      {authStore.isSeller && (
        <Box>
          <Button variant='contained' size='small' startIcon={<OkIcon />} onClick={onRegister}>
            Зарегистрировать
          </Button>
        </Box>
      )}
    </Stack>
  )
}
