import React, { useEffect, useState } from 'react'
import { Badge, Button, IconButton, styled, Drawer, Box } from '@mui/material'
import { Notifications as NotificationIcon, Menu as MenuIcon } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { useSnackbar } from 'notistack'

import { mainLogo } from '../../assets/images'
import { AccountMenu } from '../Menu'
import { useStores } from '../../stores/hooks'

interface IProps {
  onOpenNotification: () => void
}

export const Header: React.FC<IProps> = ({ onOpenNotification }) => {
  const history = useHistory()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const toggleDrawer = () => {
    setDrawerOpen((prev) => !prev)
  }

  const navigate = (route: string) => {
    history.push(route)

    if (drawerOpen) {
      setDrawerOpen(false)
    }
  }

  return (
    <Root>
      <LogoImg src={mainLogo} alt='' onClick={() => history.push('/')} />
      <Nav>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
          <NavList navigate={navigate} />
        </Box>

        <NotificationButton onClick={onOpenNotification} />
        <AccountMenu />
        <Box sx={{ display: { sm: 'block', md: 'none' } }}>
          <IconButton onClick={toggleDrawer}>
            <MenuIcon color='inherit' />
          </IconButton>
        </Box>
      </Nav>
      <Drawer anchor='top' open={drawerOpen} onClose={toggleDrawer} sx={{ display: { sm: 'block', md: 'none' } }}>
        <NavList navigate={navigate} />
      </Drawer>
    </Root>
  )
}

const NavList: React.FC<{ navigate: (route: string) => void }> = observer(({ navigate }) => {
  const { authStore } = useStores()

  return (
    <>
      <Button color='inherit' onClick={() => navigate('/')}>
        Главная
      </Button>
      <Button color='inherit' onClick={() => navigate('/sales')}>
        Продажи
      </Button>
      <Button color='inherit' onClick={() => navigate('/warranties')}>
        Гарантии
      </Button>
      <Button color='inherit' onClick={() => navigate('/news')}>
        Новости
      </Button>
      {authStore.isSeller && (
        <Button color='inherit' onClick={() => navigate('/testings')}>
          Тестирование
        </Button>
      )}
      {authStore.isDealer && (
        <Button color='inherit' onClick={() => navigate('/sellers')}>
          Продавцы
        </Button>
      )}
    </>
  )
})

const NotificationButton: React.FC<{ onClick: () => void }> = observer(({ onClick }) => {
  const { notificationsStore } = useStores()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(
    () => {
      if (notificationsStore.hasNew) {
        enqueueSnackbar('У Вас новое уведомление', { variant: 'info' })
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationsStore.hasNew]
  )

  const handleClick = () => {
    notificationsStore.setHasNew(false)
    onClick()
  }

  return (
    <IconButton sx={{ mx: 1 }} onClick={handleClick}>
      {notificationsStore.hasNew ? (
        <Badge variant='dot' color='secondary'>
          <NotificationIcon color='inherit' />
        </Badge>
      ) : (
        <NotificationIcon color='inherit' />
      )}
    </IconButton>
  )
})

const Root = styled('header')(({ theme }) => ({
  height: 140,
  marginBottom: theme.spacing(6),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    height: 70,
  },
}))

const Nav = styled('nav')(({ theme }) => ({
  paddingRight: 16,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[700],
}))

const LogoImg = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(6),
  zIndex: theme.zIndex.appBar + 1,
  [theme.breakpoints.down('sm')]: {
    top: -10,
    left: theme.spacing(4),
    height: 90,
  },
}))
