import { Font, StyleSheet } from '@react-pdf/renderer'

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
})

const styles = StyleSheet.create({
  mb8: { marginBottom: 8 },
  mb16: { marginBottom: 16 },
  mb40: { marginBottom: 40 },
  body1: { fontSize: 8 },
  subtitle1: { fontSize: 10, fontWeight: 700 },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
  },
})

export default styles
