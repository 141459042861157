import { useMutation } from '@apollo/client'

import { PROFILE } from '../../queries/auth'
import { SEND_POINTS_TO_SELLER } from '../../queries/dealers'

export const useSendPointsToSeller = () => {
  return useMutation<{ sendPointsToSeller: boolean }, { sellerId: number; points: number }>(SEND_POINTS_TO_SELLER, {
    refetchQueries: [{ query: PROFILE }],
  })
}
