import { Box } from '@mui/material'

import { TopWidgets } from '../../components/Layout'
import { LastNews, Leaderboards, Welcome } from './components'

function Home() {
  return (
    <Box>
      <Box mb={2}>
        <Welcome />
      </Box>
      <Box mb={4}>
        <TopWidgets />
      </Box>
      <Box mb={4}>
        <LastNews />
      </Box>
      <Box mb={4}>
        <Leaderboards />
      </Box>
    </Box>
  )
}

export default Home
