import { action, makeObservable, observable } from 'mobx'

export class NotificationsStore {
  lastNotificationId: number | null = null
  hasNew: boolean = false

  constructor() {
    makeObservable(this, {
      lastNotificationId: observable,
      hasNew: observable,
      setLastNotificationId: action,
      setHasNew: action,
    })
  }

  setLastNotificationId(id: number) {
    this.lastNotificationId = id
  }

  setHasNew(has: boolean) {
    this.hasNew = has
  }
}

export default new NotificationsStore()
