import { gql } from '@apollo/client'

export const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Products {
    id
    title
    fullTitle
    width
    height
    diameter
    points
    brand {
      id
      title
    }
  }
`

export const PRODUCTS_SEARCH = gql`
  query ProductsSearchQuery($limit: Int, $start: Int, $search: String!, $brand: Int) {
    productsSearch(limit: $limit, start: $start, search: $search, brand: $brand) {
      products {
        ...ProductFragment
      }
      total
    }
  }
  ${PRODUCT_FRAGMENT}
`
