import { useMutation } from '@apollo/client'

import { GET_PRIZES, REQUEST_PRIZE } from '../../queries/prizes'
import { IPrize, IRequestPrizeInput } from '../../types/prize'

export const useRequestPrize = () => {
  return useMutation<{ requestPrizeByUser: IPrize }, { input: IRequestPrizeInput }>(REQUEST_PRIZE, {
    refetchQueries: [{ query: GET_PRIZES, variables: { limit: 9, start: 0, sort: 'created_at:DESC' } }],
  })
}
