import React from 'react'
import { Box, Card, Typography } from '@mui/material'

import { ISaleProduct } from '../../graphql/types/sales'

interface IProps {
  saleProduct: ISaleProduct
}

export const SalesProductItem: React.FC<IProps> = ({ saleProduct }) => {
  const product = saleProduct.product
  const productTitle = `${product.title} / ${product.width}/${product.height}/${product.diameter}`
  const title = `${productTitle} / ${saleProduct.kit} шины`

  return (
    <Card>
      <Box p={2}>
        <Typography>{title}</Typography>
      </Box>
    </Card>
  )
}
