import React from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useBrands } from '../../graphql/hooks/brands'

interface IProps {
  value?: number
  onChange: (value: number) => void
}

export const BrandsSelect: React.FC<IProps> = ({ value, onChange }) => {
  const { brands } = useBrands({ hasWarranty: true })

  return (
    <FormControl fullWidth>
      <InputLabel id='brand-select-label'>Бренд</InputLabel>
      <Select
        labelId='brand-select-label'
        id='brand-select'
        value={value ?? ''}
        label='Бренд'
        onChange={(e) => onChange(Number(e.target.value))}
      >
        {brands.map((b) => (
          <MenuItem key={`brand-${b.id}`} value={b.id}>
            {b.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
