import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { BrokenImageOutlined as NotFoundIcon } from '@mui/icons-material'
import { Link } from 'react-router-dom'

export const PageNotFound = () => {
  return (
    <Stack direction='row' justifyContent='center'>
      <Card>
        <Box py={4} px={5} textAlign='center'>
          <NotFoundIcon sx={{ fontSize: 62, color: 'grey.400' }} />
          <Typography color='grey.500' variant='h6' mb={3}>
            Страница не найдена
          </Typography>
          <Button component={Link} to='/' variant='outlined' size='small'>
            На главную
          </Button>
        </Box>
      </Card>
    </Stack>
  )
}
