import { useMutation } from '@apollo/client'
import { CREATE_WARRANTY_CASE, WARRANTY_CASES } from '../../queries/warranty-cases'
import { ICreateWarrantyCaseData, ICreateWarrantyCaseVariables } from '../../types/warranty-case'

export const useCreateWarrantyCase = (warrantyId: number) =>
  useMutation<ICreateWarrantyCaseData, ICreateWarrantyCaseVariables>(CREATE_WARRANTY_CASE, {
    refetchQueries: [
      {
        query: WARRANTY_CASES,
        variables: {
          where: {
            warranty: warrantyId,
          },
        },
      },
    ],
  })
