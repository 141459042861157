import { useState } from 'react'
import { useQuery } from '@apollo/client'

import { IGetSellersData, IGetSellersVariables } from '../../types/sellers'
import { GET_SELLERS } from '../../queries/sellers'

export const useSellers = (limit: number = 12) => {
  const [page, setPage] = useState<number>(1)

  const { data, ...q } = useQuery<IGetSellersData, IGetSellersVariables>(GET_SELLERS, {
    variables: {
      limit,
      start: (page - 1) * limit,
    },
    fetchPolicy: 'network-only',
  })

  const sellers = data?.sellersOfDealer.sellers || []
  const total = data?.sellersOfDealer.total || 0

  return {
    sellers,
    total,
    page,
    setPage,
    ...q,
  }
}
