import { gql } from '@apollo/client'
import { IMAGE_FRAGMENT } from './upload'

export const WARRANTY_CASE_FRAGMENT = gql`
  fragment WarrantyCaseFragment on WarrantyCase {
    id
    created_at
    updated_at
    tireType
    tireDesignation
    dotCode
    treadDepth
    avgPressure
    damage
    type
    cost
    mileage
    vendorCode
    damageHumanHealth
    carDamage
    policeInvolvement
    damageProperty
    damageOtherVehicles
    involvementInsurance
    wholeTireImage {
      ...ImageFragment
    }
    sizeImage {
      ...ImageFragment
    }
    dotCodeImage {
      ...ImageFragment
    }
    damageImage {
      ...ImageFragment
    }
    couponCheckImage {
      ...ImageFragment
    }
    optImage1 {
      ...ImageFragment
    }
    optImage2 {
      ...ImageFragment
    }
    status
    seller {
      id
      name
      email
      dealer {
        id
        name
        email
        director
        contacts
        address
        city {
          id
          title
        }
      }
    }
    decision
    admin_user {
      id
      firstname
      lastname
    }
  }
  ${IMAGE_FRAGMENT}
`

export const CREATE_WARRANTY_CASE = gql`
  mutation CreateWarrantyCaseMutation($input: createWarrantyCaseInput!) {
    createWarrantyCase(input: $input) {
      warrantyCase {
        ...WarrantyCaseFragment
      }
    }
  }
  ${WARRANTY_CASE_FRAGMENT}
`

export const WARRANTY_CASES = gql`
  query WarrantyCasesQuery($sort: String, $limit: Int, $start: Int, $where: JSON) {
    warrantyCases(sort: $sort, limit: $limit, start: $start, where: $where) {
      ...WarrantyCaseFragment
    }
  }
  ${WARRANTY_CASE_FRAGMENT}
`
