import { Box, Card, CardActionArea, Grid, IconButton, Stack, styled, Typography } from '@mui/material'
import {
  Add as AddIcon,
  StarBorder as GiftIcon,
  ChevronRight as MoreIcon,
  Person as SellerIcon,
} from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { useStores } from '../../stores/hooks'

export const TopWidgets = observer(() => {
  const { authStore } = useStores()

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={6} md={3}>
          <TopCard>
            <Stack flexGrow={1} px={2} py={1} direction='row' alignItems='center'>
              <Box flexGrow={1}>
                <Typography variant='h5'>{authStore.salesCount}</Typography>
                <Typography variant='caption' color='GrayText'>
                  ПРОДАЖИ
                </Typography>
              </Box>
              <Box>
                <IconButton size='small' component={Link} to='/sales'>
                  <MoreIcon />
                </IconButton>
              </Box>
            </Stack>
          </TopCard>
        </Grid>
        <Grid item xs={4} sm={6} md={3}>
          {authStore.isDealer ? (
            <TopCard sx={{ bgcolor: 'primary.main', color: '#fff' }}>
              <CardActionArea component={Link} to='/sellers'>
                <Box px={2} py={1} textAlign='center'>
                  <SellerIcon fontSize='large' sx={{ mt: 0.7 }} />
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Typography variant='caption' component='p'>
                      ПРОДАВЦЫ
                    </Typography>
                  </Box>
                </Box>
              </CardActionArea>
            </TopCard>
          ) : (
            <TopCard sx={{ bgcolor: 'primary.main', color: '#fff' }}>
              <CardActionArea component={Link} to='/sales/create'>
                <Box px={2} py={1} textAlign='center'>
                  <AddIcon fontSize='large' sx={{ mt: 0.7 }} />
                  <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Typography variant='caption' component='p'>
                      ЗАРЕГИСТРИРОВАТЬ ПРОДАЖУ
                    </Typography>
                  </Box>
                </Box>
              </CardActionArea>
            </TopCard>
          )}
        </Grid>
        <Grid item xs={8} sm={6} md={3}>
          <TopCard>
            <Box px={2} py={1}>
              <Typography variant='h5'>{authStore.userType?.points || 0}</Typography>
              <Typography variant='caption' color='GrayText'>
                БАЛЛЫ
              </Typography>
            </Box>
          </TopCard>
        </Grid>
        <Grid item xs={4} sm={6} md={3}>
          <TopCard sx={{ bgcolor: 'secondary.main', color: '#fff' }}>
            <CardActionArea component={Link} to='/prizes'>
              <Box px={2} py={1} textAlign='center'>
                <GiftIcon fontSize='large' />
                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Typography variant='caption' component='p'>
                    ПРИЗЫ
                  </Typography>
                </Box>
              </Box>
            </CardActionArea>
          </TopCard>
        </Grid>
      </Grid>
    </Box>
  )
})

const TopCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}))
