import { Box, Typography, styled } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { Link } from 'react-router-dom'

import { DATE_TIME_FORMAT } from '../../core/constants'
import { INotification, NotificationType } from '../../graphql/types/notifications'

interface IProps {
  item: INotification
}

export const NotificationItem: React.FC<IProps> = ({ item }) => {
  const notificationByType = () => {
    switch (item.type) {
      case NotificationType.ARTICLE_LIKE_POINTS:
        return <ArticleLikePoints item={item} />
      case NotificationType.SALE_POINTS:
        return <SalePoints item={item} />
      case NotificationType.DEALER_POINTS:
        return <DealerPoints item={item} />
      case NotificationType.TESTING_POINTS:
        return <TestingPoints item={item} />
      default:
        return null
    }
  }

  return (
    <Root>
      <Typography variant='caption' color='text.secondary'>
        {moment(item.created_at).format(DATE_TIME_FORMAT)}
      </Typography>
      <Box>{notificationByType()}</Box>
    </Root>
  )
}

const ArticleLikePoints = ({ item }: { item: INotification }) => {
  return (
    <Typography sx={{ lineHeight: 1.1 }}>
      {`Начислено ${item.points} баллов за `}
      <Link to={`/news/${item.relation_id}`}>новость</Link>
    </Typography>
  )
}

const SalePoints = ({ item }: { item: INotification }) => {
  return (
    <Typography sx={{ lineHeight: 1.1 }}>
      {`Начислено ${item.points} баллов за `}
      {!!item.relation_id ? <Link to={`/sales/${item.relation_id}`}>продажу</Link> : 'продажу'}
    </Typography>
  )
}

const DealerPoints = ({ item }: { item: INotification }) => {
  return <Typography sx={{ lineHeight: 1.1 }}>Перевод {item.points} баллов от точки продаж</Typography>
}

const TestingPoints = ({ item }: { item: INotification }) => {
  return (
    <Typography sx={{ lineHeight: 1.1 }}>
      {`Начислено ${item.points} баллов за `}
      {!!item.relation_id ? <Link to={`/testings/${item.relation_id}`}>тестирование</Link> : 'тестирование'}
    </Typography>
  )
}

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[100],
  borderTopLeftRadius: theme.spacing(1.5),
  borderTopRightRadius: theme.spacing(1.5),
  borderBottomLeftRadius: theme.spacing(1.5),
}))
