import { Dialog, DialogTitle, DialogContent, TextField, Box, Button, Alert } from '@mui/material'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../components/Form'
import { useCreateSeller } from '../../../graphql/hooks/sellers'
import { sellerSchema } from './validation'

interface IProps {
  open: boolean
  onClose: () => void
}

export interface IAddSellerFormValues {
  email: string
  name: string
}

export const AddSellerModal: React.FC<IProps> = ({ open, onClose }) => {
  const [createSeller, { loading, error }] = useCreateSeller()

  const initialValues: IAddSellerFormValues = {
    email: '',
    name: '',
  }

  const handleFormSubmit = async (values: IAddSellerFormValues) => {
    try {
      await createSeller({
        variables: { input: values },
      })
      onClose()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Dialog maxWidth='sm' fullWidth onClose={onClose} open={open}>
      <DialogTitle>Новый продавец</DialogTitle>
      <DialogContent>
        {!!error && (
          <Box mb={1}>
            <Alert severity='error'>{'Ошибка при создании продавца'}</Alert>
          </Box>
        )}
        <Formik initialValues={initialValues} validationSchema={sellerSchema} onSubmit={handleFormSubmit}>
          {({ values, errors, handleChange, handleSubmit }: FormikProps<IAddSellerFormValues>) => (
            <form onSubmit={handleSubmit}>
              <TextField
                label='Имя'
                name='name'
                value={values.name}
                onChange={handleChange}
                error={!!errors.name}
                required
              />
              <TextField
                label='Email'
                name='email'
                value={values.email}
                onChange={handleChange}
                error={!!errors.email}
                required
              />
              <Box mt={2} display='flex' justifyContent='space-between'>
                <Button onClick={onClose}>Закрыть</Button>
                <LoadableButton loading={loading} color='primary' variant='contained' type='submit'>
                  Отправить приглашение
                </LoadableButton>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
