import { IDealer } from './dealers'
import { ISeller } from './sellers'

export enum UserRoleType {
  DEALER = 'dealer',
  SELLER = 'seller',
}

export interface IUser {
  id: number
  email: string
  role: IUserRole
}

export interface IUserRole {
  id: number
  name: string
  type: string
}

export type UserType = IDealer | ISeller

export interface IUserProfileData {
  user: IUser
  type: UserType
}

export interface IProfilePayload {
  profile: IUserProfileData
  userSalesCount: number
}

export interface IUpdateProfileInput {
  name: string
  password?: string
  contacts?: string
  director?: string
  address?: string
}

export interface ILoginInput {
  email: string
  password: string
}

export interface ILoginData {
  jwt: string
  user: IUser
}

export interface IUpdateAvatarInput {
  refId: number
  ref: string
  field: string
  file: File
}
