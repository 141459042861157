import React, { useState } from 'react'
import { Box, Button, Grid, TextField } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import moment from 'moment'
import { IProduct } from '../../graphql/types/products'
import { IWarranty } from '../../graphql/types/warranty'
import { ProductsAutocomplete } from '../common'
import { LoadableButton } from '../../components/Form'
import { useUpdateWarrantyProducts } from '../../graphql/hooks/warranty'

interface IProps {
  warranty: IWarranty
}

export const WarrantyProductsForm: React.FC<IProps> = ({ warranty }) => {
  const [updateProducts, { loading }] = useUpdateWarrantyProducts(warranty.id)
  const [product, setProduct] = useState<IProduct | null>(null)
  const [secondProduct, setSecondProduct] = useState<IProduct | null>(null)
  const [addSecond, setAddSecond] = useState(false)
  const [purchaseDate, setPurchaseDate] = useState<string | null>(null)

  const handleSubmit = async () => {
    if (!product) {
      return
    }

    try {
      await updateProducts({
        variables: {
          id: warranty.id,
          input: {
            product: product.id,
            secondProduct: secondProduct?.id ?? undefined,
            purchaseDate: purchaseDate ? moment(purchaseDate).format('YYYY-MM-DD') : undefined,
          },
        },
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProductsAutocomplete
            value={product}
            onChange={setProduct}
            brand={warranty.brand ? parseInt(String(warranty.brand.id)) : undefined}
          />
          {addSecond && (
            <ProductsAutocomplete
              value={secondProduct}
              onChange={setSecondProduct}
              brand={warranty.brand ? parseInt(String(warranty.brand.id)) : undefined}
            />
          )}
          <Button size='small' onClick={() => setAddSecond((prev) => !prev)}>
            {`${addSecond ? 'Убрать' : 'Добавить'} второй товар`}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MobileDatePicker
            label='Дата покупки'
            inputFormat='DD.MM.YYYY'
            value={purchaseDate}
            onChange={(d) => setPurchaseDate(d)}
            renderInput={(params) => <TextField {...params} margin='dense' />}
          />
          <Box pt={1} textAlign='right'>
            <LoadableButton loading={loading} variant='contained' size='small' onClick={handleSubmit}>
              Сохранить
            </LoadableButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
