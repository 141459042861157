import React from 'react'
import { Box, Card, CardActionArea, Chip, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { DATE_TIME_FORMAT } from '../../../core/constants'
import { ITesting, TestingStatus } from '../../../graphql/types/testings'

interface IProps {
  testing: ITesting
}

export const TestingListItem: React.FC<IProps> = ({ testing }) => {
  const result = testing.myResult

  return (
    <Card>
      <CardActionArea component={Link} to={`/testings/${testing.id}`}>
        <Box sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          <Box flexGrow={1} sx={{ lineHeight: 1 }}>
            {testing.status === TestingStatus.STOPPED && (
              <Box mb={1}>
                <Chip label='Тестирование остановлено' size='small' />
              </Box>
            )}
            <Typography>{testing.title}</Typography>
            <Typography variant='caption' color='text.secondary'>
              {moment(testing.created_at).format(DATE_TIME_FORMAT)}
            </Typography>
          </Box>
          <Box textAlign='right'>
            {result && (
              <Box ml={2}>
                <Typography fontWeight={700}>{result.points || ''}</Typography>
                {result.is_passed ? (
                  <Typography variant='caption' color='success.main'>
                    Успешно
                  </Typography>
                ) : (
                  <Typography variant='caption' color='error.main'>
                    Не успешно
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </CardActionArea>
    </Card>
  )
}
