import React from 'react'
import { TextField, Box } from '@mui/material'
import { Formik, FormikProps } from 'formik'

import { LoadableButton } from '../../../components/Form'
import { IUserProfileData } from '../../../graphql/types/auth'
import { useStores } from '../../../stores/hooks'
import { IDealer } from '../../../graphql/types/dealers'
import { profileSchema } from './validation'
import { observer } from 'mobx-react-lite'

export interface IProfileFormValues {
  name: string
  password: string
  contacts?: string
  director?: string
  address?: string
}

interface IProps {
  profile: IUserProfileData
  onSubmit: (values: IProfileFormValues) => void
  loading?: boolean
}

export const ProfileForm: React.FC<IProps> = observer(({ profile, onSubmit, loading }) => {
  const { authStore } = useStores()

  const type = profile.type
  const initialValues: IProfileFormValues = {
    name: type.name || '',
    password: '',
    contacts: type.contacts || '',
    director: (type.hasOwnProperty('director') && (type as IDealer).director) || '',
    address: (type.hasOwnProperty('address') && (type as IDealer).address) || '',
  }

  const handleFormSubmit = (values: IProfileFormValues) => {
    onSubmit(values)
  }

  return (
    <Formik initialValues={initialValues} validationSchema={profileSchema} onSubmit={handleFormSubmit}>
      {({ values, errors, handleChange, handleSubmit }: FormikProps<IProfileFormValues>) => (
        <form onSubmit={handleSubmit}>
          <TextField
            label='Имя'
            name='name'
            value={values.name}
            onChange={handleChange}
            error={!!errors.name}
            required
          />
          <TextField
            label='Новый пароль'
            name='password'
            value={values.password}
            onChange={handleChange}
            error={!!errors.password}
            type='password'
          />

          <Box pt={3} pb={3}>
            {authStore.isDealer && (
              <TextField
                label='Директор'
                name='director'
                value={values.director}
                onChange={handleChange}
                error={!!errors.director}
              />
            )}
            <TextField
              label='Контакты'
              name='contacts'
              value={values.contacts}
              onChange={handleChange}
              error={!!errors.contacts}
              multiline
              rows={2}
            />
            {authStore.isDealer && (
              <TextField
                label='Адрес'
                name='address'
                value={values.address}
                onChange={handleChange}
                error={!!errors.address}
                multiline
                rows={2}
              />
            )}
          </Box>

          <Box mt={2}>
            <LoadableButton loading={loading} color='primary' variant='contained' fullWidth type='submit'>
              Сохранить
            </LoadableButton>
          </Box>
        </form>
      )}
    </Formik>
  )
})
