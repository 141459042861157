import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import { ChevronLeft as BackIcon } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'

interface IProps {
  title: string
  back?: boolean
  rightContent?: React.ReactNode
}

export const PageHeader: React.FC<IProps> = ({ title, back, rightContent }) => {
  const history = useHistory()

  return (
    <Box mb={3} sx={{ display: 'flex', flexDirection: 'row' }}>
      {back && (
        <Box mr={1}>
          <IconButton onClick={history.goBack}>
            <BackIcon />
          </IconButton>
        </Box>
      )}
      <Box flexGrow={1}>
        <Typography variant='h4'>{title}</Typography>
      </Box>
      {!!rightContent && <Box>{rightContent}</Box>}
    </Box>
  )
}
