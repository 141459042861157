import React, { useState } from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import { VerifiedUser as WarrantyIcon } from '@mui/icons-material'
import { BrandsSelect } from '../../../sections/common'
import { CreateWarrantyInfo } from '../../../sections/warranty'
import { IGenerateWarrantyInput } from '../../../graphql/types/warranty'
import { LoadableButton } from '../../../components/Form'

interface IProps {
  loading?: boolean
  onSubmit: (input: IGenerateWarrantyInput) => void
}

export const CreateWarrantyForm: React.FC<IProps> = ({ onSubmit, loading }) => {
  const [brandId, setBrandId] = useState<number>()

  const handleSelectBrand = (b: number) => {
    setBrandId(b)
  }

  const handleSubmit = () => {
    if (!brandId) {
      return
    }

    onSubmit({ brandId })
  }

  return (
    <Box>
      <Box mb={3}>
        <Card>
          <CardContent>
            <Typography variant='h6' mb={1}>
              Выберите бренд
            </Typography>
            <BrandsSelect value={brandId} onChange={handleSelectBrand} />
          </CardContent>
        </Card>
      </Box>
      <Box mb={3}>
        <CreateWarrantyInfo />
      </Box>
      <Box textAlign='center'>
        <LoadableButton
          loading={loading}
          variant='contained'
          size='large'
          startIcon={<WarrantyIcon />}
          onClick={handleSubmit}
        >
          Создать гарантию
        </LoadableButton>
      </Box>
    </Box>
  )
}
