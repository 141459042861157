import { gql } from '@apollo/client'

export const EXPLOITATION_FRAGMENT = gql`
  fragment ExploitationFragment on Exploitation {
    id
    title
  }
`

export const GET_EXPLOITATIONS = gql`
  query GetExploitationsQuery {
    exploitations {
      ...ExploitationFragment
    }
  }
  ${EXPLOITATION_FRAGMENT}
`
